import React from 'react';
import { useRenameSceneMutation } from '../../api/graphql/generated';
import { RenameFunction, RenameModal } from './RenameModal';
import { useToast } from '../../hooks/useToast';

type RenameSceneModalProps = {
  sceneId: number;
  sceneName: string;
  onRename: () => void;
  onCancel: () => void;
};

export const RenameSceneModal: React.FC<RenameSceneModalProps> = ({ sceneId, sceneName, onCancel, onRename }) => {
  const renameScene = useRenameSceneMutation();
  const { addSuccessToast, addErrorToast } = useToast();

  const onRenameAction: RenameFunction = Object.assign(
    (newName: string) => {
      renameScene
        .mutateAsync({ sceneId, newName })
        .then(() => {
          addSuccessToast('Scene renamed', `Your scene was successfully renamed to ${newName}.`);
          onRename();
        })
        .catch((reason) => {
          addErrorToast('Something went wrong', "We were not able to change scene's name.");
        });
    },
    {
      isLoading: renameScene.isLoading,
    }
  );

  return (
    <RenameModal
      config={{ title: 'Rename Scene', nameInputLabel: 'Scene Name' }}
      oldName={sceneName}
      onRename={onRenameAction}
      onCancel={onCancel}
    />
  );
};
