import React, { useState } from 'react';
import { Modal } from '../atoms/layouts/Modal';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Text } from '../atoms/texts/Text';
import { LabelOnTopLeftBox } from '../atoms/inputs/layouts/LabelOnTopLeftBox';
import { Label } from '../atoms/inputs/label/Label';
import { TextInput } from '../atoms/inputs/text/TextInput';
import { Button } from '../atoms/buttons/Button';
import { useInviteTeamMateMutation } from '../../repositories/team/InviteTeamMateMutation';
import { Role } from '../../api/graphql/generated';
import { RoleRadioGroup } from './RoleRadioGroup';
import { isValidEmail } from '../../utils/isValidEmail';
import { useToast } from '../../hooks/useToast';

type InviteUserModalProps = {
  existingEmails?: string[];
  onCreate: () => void;
  onCancel: () => void;
};

export const InviteUserModal: React.FC<InviteUserModalProps> = ({ existingEmails, onCreate, onCancel }) => {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [role, setRole] = useState(Role.Author);
  const { addSuccessToast, addErrorToast } = useToast();

  const inviteUser = useInviteTeamMateMutation();

  const userAlreadyInvited = existingEmails
    ? existingEmails.some((item) => item.toLowerCase() === newUserEmail.toLowerCase())
    : false;

  const inviteNewUserAction = () => {
    if (!newUserEmail) {
      return;
    }

    inviteUser.mutate(
      { email: newUserEmail, role },
      {
        onSuccess(data) {
          if (data.AddTeamMember.errors) {
            const [error] = data.AddTeamMember.errors;
            if (error.__typename === 'UserAlreadyExistsError') {
              addErrorToast(
                'User already exists',
                `A user with email "${newUserEmail}" already exists in another organization.`
              );
            }
          }
          onCreate();
          addSuccessToast('New invite has been sent', `A new user with email "${newUserEmail}" has been invited`);
        },
      }
    );
  };

  return (
    <Modal
      padding="l"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="s">
        <Text size="m" weight="semibold">
          Invite new collaborator
        </Text>
        <AlignedContent direction="column" gap="m">
          <LabelOnTopLeftBox>
            <Label htmlFor="newUserEmail">Email</Label>
            <TextInput
              id="newUserEmail"
              size="full"
              type="email"
              placeholder="Enter collaborator email"
              defaultValue={newUserEmail}
              onInput={(e) => setNewUserEmail(e.currentTarget.value)}
              errorMessage={
                userAlreadyInvited
                  ? 'A user with the entered email address already exists in your organization.'
                  : undefined
              }
            />
          </LabelOnTopLeftBox>
          <LabelOnTopLeftBox>
            <Label htmlFor="newUserRole">Role</Label>
            <RoleRadioGroup id="newUserRole" onChange={(selected) => setRole(selected)} />
          </LabelOnTopLeftBox>
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button
              label="Invite"
              action={inviteNewUserAction}
              loading={inviteUser.isLoading}
              disabled={!isValidEmail(newUserEmail) || userAlreadyInvited}
            />
          </AlignedContent>
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
