import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetcher } from './fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  Long: number;
  URL: string;
  UUID: string;
};

/** Current User creates a new Project. */
export type AddProjectInput = {
  name: Scalars['String'];
  sceneId: InputMaybe<Scalars['Int']>;
};

export type AddProjectPayload = {
  __typename?: 'AddProjectPayload';
  project: Maybe<Project>;
};

export type AddTeamMemberError = UserAlreadyExistsError;

/**
 * Creates new User and assigns  and  to them.
 * Returns id of newly created User.
 */
export type AddTeamMemberInput = {
  newMemberEmail: Scalars['String'];
  newMemberRole: Role;
};

export type AddTeamMemberPayload = {
  __typename?: 'AddTeamMemberPayload';
  errors: Maybe<Array<AddTeamMemberError>>;
  user: Maybe<User>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AssetLibraryStorageIdSortInput = {
  preferredAssetDirectory: InputMaybe<SortEnumType>;
};

/** User creates a Project based on a template. */
export type CreateProjectFromTemplateInput = {
  /** Identifies Project's GUID inside template Organization. */
  templateIdentifier: Scalars['UUID'];
};

export type CreateProjectFromTemplatePayload = {
  __typename?: 'CreateProjectFromTemplatePayload';
  project: Maybe<Project>;
};

/** Deletes existing panorama by . */
export type DeletePanoramaInput = {
  sceneId: Scalars['Int'];
};

export type DeletePanoramaPayload = {
  __typename?: 'DeletePanoramaPayload';
  result: Maybe<Result>;
};

/** Current User duplicates existing project . */
export type DuplicateProjectInput = {
  projectId: Scalars['Int'];
};

export type DuplicateProjectPayload = {
  __typename?: 'DuplicateProjectPayload';
  int: Maybe<Scalars['Int']>;
};

/**
 * Finds User by  and changes their Role.
 * Returns id of updated User.
 */
export type EditTeamMemberInput = {
  hasAcceptedInvite: InputMaybe<Scalars['Boolean']>;
  hasGivenMarketingConsent: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<NameInput>;
  newRole: InputMaybe<Role>;
  userId: Scalars['Int'];
};

export type EditTeamMemberPayload = {
  __typename?: 'EditTeamMemberPayload';
  int: Maybe<Scalars['Int']>;
};

export type Error = {
  message: Scalars['String'];
};

/**
 * Defines types of Project export.
 * SCORM 1.2 = 0
 * SCORM 2004 = 1
 * TinCan = 2
 */
export enum ExportType {
  /** SCORM version 1.2 */
  Scorm12 = 'SCORM12',
  /** SCORM version 2004 */
  Scorm2004 = 'SCORM2004',
  /** TinCan */
  TinCan = 'TIN_CAN'
}

export type FilesContainerIdSortInput = {
  value: InputMaybe<SortEnumType>;
};

/** Represents invitation state of User in Organization. */
export enum InvitationState {
  /** Invitation was accepted. */
  Accepted = 'ACCEPTED',
  /** Invitation was sent but not accepted. */
  Pending = 'PENDING'
}

/** Data Transfer Object for invoice. */
export type InvoiceDto = {
  __typename?: 'InvoiceDto';
  /** Amount paid by customer. Can be 0 if not paid yet. */
  amountPaid: Scalars['Long'];
  /** Date and time and invoice was initially created. */
  created: Scalars['DateTime'];
  /** Currency of the amount paid. */
  currency: Scalars['String'];
  /** URL to the invoice PDF. */
  invoicePdf: Scalars['String'];
  /** Status of the invoice - e.g. draft, open, paid, uncollectible, or void */
  status: Scalars['String'];
};

export type LinkPosition = {
  __typename?: 'LinkPosition';
  sceneIndex: Scalars['Int'];
  xAxis: Scalars['Float'];
  yAxis: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AddProject: AddProjectPayload;
  AddTeamMember: AddTeamMemberPayload;
  CreateProjectFromTemplate: CreateProjectFromTemplatePayload;
  DeletePanorama: DeletePanoramaPayload;
  DuplicateProject: DuplicateProjectPayload;
  EditTeamMember: EditTeamMemberPayload;
  RemoveProject: RemoveProjectPayload;
  RemoveTeamMember: RemoveTeamMemberPayload;
  RenamePanorama: RenamePanoramaPayload;
  SetProjectProperties: SetProjectPropertiesPayload;
  SetProjectPublicAvailability: SetProjectPublicAvailabilityPayload;
  StartProjectExport: StartProjectExportPayload;
  UpdateOnboarding: UpdateOnboardingPayload;
};


export type MutationAddProjectArgs = {
  input: InputMaybe<AddProjectInput>;
};


export type MutationAddTeamMemberArgs = {
  input: InputMaybe<AddTeamMemberInput>;
};


export type MutationCreateProjectFromTemplateArgs = {
  input: InputMaybe<CreateProjectFromTemplateInput>;
};


export type MutationDeletePanoramaArgs = {
  input: InputMaybe<DeletePanoramaInput>;
};


export type MutationDuplicateProjectArgs = {
  input: InputMaybe<DuplicateProjectInput>;
};


export type MutationEditTeamMemberArgs = {
  input: InputMaybe<EditTeamMemberInput>;
};


export type MutationRemoveProjectArgs = {
  input: InputMaybe<RemoveProjectInput>;
};


export type MutationRemoveTeamMemberArgs = {
  input: InputMaybe<RemoveTeamMemberInput>;
};


export type MutationRenamePanoramaArgs = {
  input: InputMaybe<RenamePanoramaInput>;
};


export type MutationSetProjectPropertiesArgs = {
  input: InputMaybe<SetProjectPropertiesInput>;
};


export type MutationSetProjectPublicAvailabilityArgs = {
  input: InputMaybe<SetProjectPublicAvailabilityInput>;
};


export type MutationStartProjectExportArgs = {
  input: InputMaybe<StartProjectExportInput>;
};


export type MutationUpdateOnboardingArgs = {
  input: InputMaybe<UpdateOnboardingInput>;
};

/** Represents a User's full name. */
export type Name = {
  __typename?: 'Name';
  /** Represents a first name of an User. */
  firstName: Scalars['String'];
  /** Represents a last name of an User. */
  lastName: Scalars['String'];
};

/** Represents a User's full name. */
export type NameInput = {
  /** Represents a first name of an User. */
  firstName: Scalars['String'];
  /** Represents a last name of an User. */
  lastName: Scalars['String'];
};

/** Represents a User's full name. */
export type NameSortInput = {
  /** Represents a first name of an User. */
  firstName: InputMaybe<SortEnumType>;
  /** Represents a last name of an User. */
  lastName: InputMaybe<SortEnumType>;
};

/** Indicates User's onboarding progress */
export type OnboardingState = {
  __typename?: 'OnboardingState';
  /** Indicates User's decision to end onboarding */
  isOnboardingFinished: Scalars['Boolean'];
  /** Indicates User's onboarding progress checklist */
  onboardingCheckList: Array<OnboardingTask>;
};

/** Indicates User's onboarding progress */
export type OnboardingStateSortInput = {
  /** Indicates User's decision to end onboarding */
  isOnboardingFinished: InputMaybe<SortEnumType>;
};

/** Represents onboarding tasks which User should take */
export enum OnboardingTask {
  /** User created blank template */
  BlankCourseCreated = 'BLANK_COURSE_CREATED',
  /** User verified email */
  EmailVerified = 'EMAIL_VERIFIED',
  /** User copied an template */
  InspiredByTemplates = 'INSPIRED_BY_TEMPLATES',
  /** User made a first project publicly available */
  LinkToCourseShared = 'LINK_TO_COURSE_SHARED',
  /** User uploaded first scene */
  SceneUploaded = 'SCENE_UPLOADED'
}

/** Represents object, to which are linked Projects, PanoramaScenes and Users. */
export type Organization = {
  __typename?: 'Organization';
  billingPortal: Scalars['URL'];
  /** Represents the customer ID in Stripe. */
  customerId: Scalars['String'];
  /** Unique primary identifier for an Aggregate. */
  id: Scalars['Int'];
  /** Represents Organization's name. */
  name: Scalars['String'];
  /** Pricing plan of the organization. */
  pricingPlan: SubscriptionPlan;
  proratedSeatPrice: ProratedUpcomingInvoiceDto;
  /** Respresents number of purchased seats by organization. */
  purchasedSeats: Scalars['Long'];
  subscriptionDetails: SubscriptionPageDto;
  users: Array<Maybe<User>>;
};


/** Represents object, to which are linked Projects, PanoramaScenes and Users. */
export type OrganizationBillingPortalArgs = {
  returnUrl: Scalars['URL'];
};


/** Represents object, to which are linked Projects, PanoramaScenes and Users. */
export type OrganizationProratedSeatPriceArgs = {
  nrOfSeats: Scalars['Int'];
};

export type PanoramaScene = {
  __typename?: 'PanoramaScene';
  hasProcessingFinishedSuccessfully: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isInTour: Scalars['Boolean'];
  lastUpdateTimeUTC: Scalars['DateTime'];
  name: Scalars['String'];
  renderSettings: Maybe<SceneRenderSettings>;
  thumbnailId: Maybe<Scalars['UUID']>;
  thumbnailUrl: Maybe<Scalars['URL']>;
};

export type PanoramaSceneSortInput = {
  guid: InputMaybe<SortEnumType>;
  hasProcessingFinishedSuccessfully: InputMaybe<SortEnumType>;
  id: InputMaybe<SortEnumType>;
  isPubliclyAvailable: InputMaybe<SortEnumType>;
  lastUpdateTimeUTC: InputMaybe<SortEnumType>;
  name: InputMaybe<SortEnumType>;
  renderSettings: InputMaybe<SceneRenderSettingsSortInput>;
  tenantId: InputMaybe<SortEnumType>;
  thumbnailId: InputMaybe<ThumbnailIdSortInput>;
};

export type Project = {
  __typename?: 'Project';
  guid: Scalars['UUID'];
  id: Scalars['Int'];
  internalCategory: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reference: Scalars['String'];
  settings: ProjectSettings;
  thumbnailId: Maybe<Scalars['UUID']>;
  thumbnailUrl: Maybe<Scalars['URL']>;
};

export type ProjectLogoIdSortInput = {
  value: InputMaybe<SortEnumType>;
};

/** Contains data regarding sharing project publicly. Like identifier in the URL to access. */
export type ProjectPublicSharingData = {
  __typename?: 'ProjectPublicSharingData';
  publicIdentifier: Scalars['UUID'];
};

export type ProjectReferenceSortInput = {
  value: InputMaybe<SortEnumType>;
};

export type ProjectSettings = {
  __typename?: 'ProjectSettings';
  courseFeedbackEmailAddress: Maybe<Scalars['String']>;
  cultureCode: Scalars['String'];
  logoId: Maybe<Scalars['UUID']>;
  logoUrl: Maybe<Scalars['URL']>;
  tagLine: Maybe<Scalars['String']>;
  watermark: Maybe<Scalars['String']>;
};

export type ProjectSettingsSortInput = {
  courseFeedbackEmailAddress: InputMaybe<SortEnumType>;
  cultureCode: InputMaybe<SortEnumType>;
  logoId: InputMaybe<ProjectLogoIdSortInput>;
  tagLine: InputMaybe<SortEnumType>;
  watermark: InputMaybe<SortEnumType>;
};

export type ProjectSortInput = {
  guid: InputMaybe<SortEnumType>;
  id: InputMaybe<SortEnumType>;
  internalCategory: InputMaybe<SortEnumType>;
  isPubliclyAvailable: InputMaybe<SortEnumType>;
  mediaLibraryStorageId: InputMaybe<AssetLibraryStorageIdSortInput>;
  name: InputMaybe<SortEnumType>;
  reference: InputMaybe<ProjectReferenceSortInput>;
  settings: InputMaybe<ProjectSettingsSortInput>;
  tenantId: InputMaybe<SortEnumType>;
  thumbnailId: InputMaybe<ThumbnailIdSortInput>;
  tourId: InputMaybe<SortEnumType>;
};

/** Represents prorated price for upgrade. */
export type ProratedUpcomingInvoiceDto = {
  __typename?: 'ProratedUpcomingInvoiceDto';
  /** Card brand (Visa, Mastercard...). */
  creditCardBrand: Scalars['String'];
  /** Currency */
  currency: Scalars['String'];
  /** Last four digits of the credit card. */
  lastFourDigits: Scalars['String'];
  /** NExt date and time when next billing occurs. */
  nextBillingDate: Maybe<Scalars['DateTime']>;
  /** Sum of money that the new subscription will cost it. */
  priceIncrease: Scalars['Long'];
  /** Partial charge for the time between starting the new service and your bill date. */
  prorateCharge: Scalars['Long'];
};

export type Query = {
  __typename?: 'Query';
  me: Maybe<User>;
  organizationById: Maybe<Organization>;
  projectById: Maybe<Project>;
  projectPublicSharingSettings: Maybe<ProjectPublicSharingData>;
  projectsByOrganizationId: Array<Project>;
  projectsByTourIds: Array<Project>;
  sceneById: Maybe<PanoramaScene>;
  scenesByOrganizationId: Array<PanoramaScene>;
  templates: Array<Project>;
  userById: Maybe<User>;
  usersByOrganizationId: Array<User>;
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['Int'];
};


export type QueryProjectByIdArgs = {
  id: Scalars['Int'];
};


export type QueryProjectPublicSharingSettingsArgs = {
  id: Scalars['Int'];
};


export type QueryProjectsByOrganizationIdArgs = {
  order: InputMaybe<Array<ProjectSortInput>>;
  organizationId: Scalars['Int'];
};


export type QueryProjectsByTourIdsArgs = {
  tourIds: Array<Scalars['Int']>;
};


export type QuerySceneByIdArgs = {
  id: Scalars['Int'];
};


export type QueryScenesByOrganizationIdArgs = {
  order: InputMaybe<Array<PanoramaSceneSortInput>>;
  organizationId: Scalars['Int'];
};


export type QueryTemplatesArgs = {
  order: InputMaybe<Array<ProjectSortInput>>;
};


export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUsersByOrganizationIdArgs = {
  order: InputMaybe<Array<UserSortInput>>;
  organizationId: Scalars['Int'];
};

/** Current User removes Project. */
export type RemoveProjectInput = {
  projectId: Scalars['Int'];
};

export type RemoveProjectPayload = {
  __typename?: 'RemoveProjectPayload';
  int: Maybe<Scalars['Int']>;
};

/**
 * Removes User from all sources.
 * Returns id of removed User.
 */
export type RemoveTeamMemberInput = {
  userId: Scalars['Int'];
};

export type RemoveTeamMemberPayload = {
  __typename?: 'RemoveTeamMemberPayload';
  int: Maybe<Scalars['Int']>;
};

/** Renames existing panorama. */
export type RenamePanoramaInput = {
  /** New name of the panorama scene. */
  newName: Scalars['String'];
  /** Identifies PanoramaScene. */
  sceneId: Scalars['Int'];
};

export type RenamePanoramaPayload = {
  __typename?: 'RenamePanoramaPayload';
  panoramaScene: Maybe<PanoramaScene>;
};

/**
 * Returns  as true when panorama was deleted successfully.
 * Returns  as fakse when panorama cannot be deleted as it is used inside
 */
export type Result = {
  __typename?: 'Result';
  isSuccess: Scalars['Boolean'];
  tourIds: Maybe<Array<Scalars['Int']>>;
};

/** Represents role of a User in a Organization. */
export enum Role {
  /** Can manage scenes and projects. */
  Author = 'AUTHOR',
  /** Highest Role in Organization. */
  Owner = 'OWNER'
}

export type SceneRenderSettings = {
  __typename?: 'SceneRenderSettings';
  baseDir: Scalars['URL'];
  containerId: Scalars['UUID'];
  startingSceneIndex: Scalars['Int'];
  urlSuffix: Scalars['String'];
  xmlDefinition: Scalars['String'];
};

export type SceneRenderSettingsSortInput = {
  containerId: InputMaybe<FilesContainerIdSortInput>;
  startingSceneIndex: InputMaybe<SortEnumType>;
  xmlDefinition: InputMaybe<SortEnumType>;
};

/** Sets properties for Project. */
export type SetProjectPropertiesInput = {
  newName: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
  watermark: InputMaybe<Scalars['String']>;
};

export type SetProjectPropertiesPayload = {
  __typename?: 'SetProjectPropertiesPayload';
  project: Maybe<Project>;
};

/**
 * Sets public availability by anonymous users for given Project.
 * Returns public project identifier if  is true, otherwise null.
 */
export type SetProjectPublicAvailabilityInput = {
  isActive: Scalars['Boolean'];
  projectId: Scalars['Int'];
};

export type SetProjectPublicAvailabilityPayload = {
  __typename?: 'SetProjectPublicAvailabilityPayload';
  projectPublicSharingData: Maybe<ProjectPublicSharingData>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/**
 * Current user start asynchronous Project export.
 * Returns a token identifying the export for client to check the progress.
 */
export type StartProjectExportInput = {
  exportType: ExportType;
  projectId: Scalars['Int'];
};

export type StartProjectExportPayload = {
  __typename?: 'StartProjectExportPayload';
  string: Maybe<Scalars['String']>;
};

/** Represents the data for subscription page. */
export type SubscriptionPageDto = {
  __typename?: 'SubscriptionPageDto';
  /** Billing address of customer. */
  billingAddress: Maybe<Scalars['String']>;
  /** Brand of credit card. */
  creditCardBrand: Maybe<Scalars['String']>;
  /** Currency of the billing. */
  currency: Maybe<Scalars['String']>;
  /** Indicates that downgrade was scheduled. */
  downgradeScheduled: Maybe<Scalars['Boolean']>;
  /** List of invoices. */
  invoices: Maybe<Array<InvoiceDto>>;
  /** Last four digits of credit card. */
  lastFourDigits: Maybe<Scalars['String']>;
  /** Next billing date. */
  nextBillingDate: Maybe<Scalars['DateTime']>;
  /** Price of the subscription - next billing amount. */
  price: Maybe<Scalars['Long']>;
  /** Pricing plan category. */
  pricingPlanCategory: SubscriptionTier;
  /** Number of seats. */
  seats: Maybe<Scalars['Long']>;
  /** The customer's tax IDs. */
  taxId: Maybe<Scalars['String']>;
};

/** Describes properties and limitations of Organization SubscriptionPlan. */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** Returns result describing whether new Project cannot be added. */
  canAddNewProject: SubscriptionPolicyResult;
  /** Returns result describing whether User can add another TourScene to a Tour. */
  canAddNewTourSceneToTour: SubscriptionPolicyResult;
  /** Returns result describing whether export package of  is supported in current SubscriptionPlan. */
  canExportPackage: SubscriptionPolicyResult;
  /** Returns result describing whether logo or watermark can be used in current SubscriptionPlan. */
  canUseCustomLogoOrWatermark: SubscriptionPolicyResult;
  /**
   * Changes all properties of SubscriptionPlan according to new SubscriptionTier.
   *
   *
   * **Returns:**
   * New SubscriptionPlan with correct properties.
   */
  changePricingPlan: SubscriptionPlan;
  /** Determines whether creating a SCORM dipatched package is allowed. */
  isSCORMCloudDispatchAllowed: Scalars['Boolean'];
  /** Represents ability to export courses in SCORM format. */
  isSCORMExportAllowed: Scalars['Boolean'];
  /** Represents ability to export courses in xAPI format. */
  isXAPIExportAllowed: Scalars['Boolean'];
  /** Maximum number of courses. Is null when unlimited. */
  numberOfCourses: Maybe<Scalars['Int']>;
  /** Defines type of SubscriptionPlan. */
  pricingPlanType: SubscriptionTier;
  /** Represents ability to remove watermark. */
  removedWatermark: Scalars['Boolean'];
  /** Maximum scenes per course. Is null when unlimited. */
  scenesPerCourse: Maybe<Scalars['Int']>;
  /** Available storage space. */
  storageSpaceInGB: Scalars['Int'];
};


/** Describes properties and limitations of Organization SubscriptionPlan. */
export type SubscriptionPlanCanAddNewProjectArgs = {
  currentProjectCount: Scalars['Int'];
};


/** Describes properties and limitations of Organization SubscriptionPlan. */
export type SubscriptionPlanCanAddNewTourSceneToTourArgs = {
  currentTourSceneCount: Scalars['Int'];
  scenesToAdd?: Scalars['Int'];
};


/** Describes properties and limitations of Organization SubscriptionPlan. */
export type SubscriptionPlanCanExportPackageArgs = {
  packageExportType: ExportType;
};


/** Describes properties and limitations of Organization SubscriptionPlan. */
export type SubscriptionPlanChangePricingPlanArgs = {
  pricingPlanType: SubscriptionTier;
};

/** Describes the result of the evaluation of SubscriptionPolicy. */
export type SubscriptionPolicyResult = {
  __typename?: 'SubscriptionPolicyResult';
  /** Returns true if policy evaluation has failed. */
  isFailure: Scalars['Boolean'];
  /** Returns true if policy evaluation was success. */
  isSuccess: Scalars['Boolean'];
};

/** Represents type of SubscriptionPlan. */
export enum SubscriptionTier {
  /** Highest SubscriptionPlan type. */
  Enterprise = 'ENTERPRISE',
  /** Lowest SubscriptionPlan type. */
  Free = 'FREE',
  /** Provides more features for a small price. */
  Pro = 'PRO',
  /** Provides more features for a higher price. */
  Team = 'TEAM'
}

export type ThumbnailIdSortInput = {
  value: InputMaybe<SortEnumType>;
};

export type Tour = {
  __typename?: 'Tour';
  id: Scalars['Int'];
  project: Maybe<Project>;
  tourScenes: Array<TourScene>;
};

export type TourScene = {
  __typename?: 'TourScene';
  guid: Scalars['UUID'];
  /** Indicates whether panorma processing was a success or fail. */
  hasProcessingFinishedSuccessfully: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  links: Array<TourSceneLink>;
  /** Returns name of the Tour's scene. */
  name: Scalars['String'];
  panoramaSceneId: Scalars['Int'];
  /** Returns settings required for rendering the Tour's scene via Krpano. */
  sceneRenderSettings: Maybe<SceneRenderSettings>;
  /** Returns thumbnail URL of the Tour's scene. */
  thumbnailUrl: Maybe<Scalars['URL']>;
  tourId: Scalars['Int'];
};

export type TourSceneLink = {
  __typename?: 'TourSceneLink';
  destinationTourScene: TourScene;
  destinationTourSceneGuid: Scalars['UUID'];
  destinationTourSceneId: Scalars['Int'];
  guid: Scalars['UUID'];
  originTourScene: TourScene;
  originTourSceneGuid: Scalars['UUID'];
  originTourSceneId: Scalars['Int'];
  position: Maybe<LinkPosition>;
};

/**
 * Finds logged in User and changes their OnboardingState.
 * Returns id of updated OnboardingState.
 */
export type UpdateOnboardingInput = {
  isOnboardingFinished: InputMaybe<Scalars['Boolean']>;
  newOnboardingStep: InputMaybe<OnboardingTask>;
};

export type UpdateOnboardingPayload = {
  __typename?: 'UpdateOnboardingPayload';
  onboardingState: Maybe<OnboardingState>;
};

/** Represents user in Panomio database. */
export type User = {
  __typename?: 'User';
  /** User's contact and login email. */
  email: Scalars['String'];
  /** Unique primary identifier for an Aggregate. */
  id: Scalars['Int'];
  /** Represents invitation state of User to Organization. */
  inviteState: InvitationState;
  /** User's full name. */
  name: Maybe<Name>;
  /** User's onboarding status. */
  onboarding: Maybe<OnboardingState>;
  organization: Maybe<Organization>;
  /** Identifies User's Organization. */
  organizationId: Scalars['Int'];
  /** Represents User's role in Organization. */
  role: Role;
};

/** Exception thrown when a User already exists in the system */
export type UserAlreadyExistsError = Error & {
  __typename?: 'UserAlreadyExistsError';
  message: Scalars['String'];
};

export type UserIdentityIdSortInput = {
  value: InputMaybe<SortEnumType>;
};

/** Represents user in Panomio database. */
export type UserSortInput = {
  /** Respresents date and time when the BaseAggregate was created. */
  creationDateTimeUtc: InputMaybe<SortEnumType>;
  /** User's contact and login email. */
  email: InputMaybe<SortEnumType>;
  /** Represents Aggregate's unique identifier. */
  guid: InputMaybe<SortEnumType>;
  /** Unique primary identifier for an Aggregate. */
  id: InputMaybe<SortEnumType>;
  /** Represents invitation state of User to Organization. */
  inviteState: InputMaybe<SortEnumType>;
  /** User's full name. */
  name: InputMaybe<NameSortInput>;
  /** User's onboarding status. */
  onboarding: InputMaybe<OnboardingStateSortInput>;
  /** Identifies User's Organization. */
  organizationId: InputMaybe<SortEnumType>;
  /** Represents User's role in Organization. */
  role: InputMaybe<SortEnumType>;
  /**
   * Identifies User when accessing the application via authentication token
   * as well as identifies User in the external identity service.
   */
  userIdentityId: InputMaybe<UserIdentityIdSortInput>;
};

export type FetchProratedInvoiceQueryVariables = Exact<{
  nrOfSeats: Scalars['Int'];
}>;


export type FetchProratedInvoiceQuery = { __typename?: 'Query', me: { __typename?: 'User', organization: { __typename?: 'Organization', proratedSeatPrice: { __typename?: 'ProratedUpcomingInvoiceDto', priceIncrease: number, prorateCharge: number, nextBillingDate: Date | null, currency: string, lastFourDigits: string, creditCardBrand: string } } | null } | null };

export type FetchScenesForNewProjectQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type FetchScenesForNewProjectQuery = { __typename?: 'Query', scenesByOrganizationId: Array<{ __typename?: 'PanoramaScene', id: number, name: string }> };

export type FetchSceneProcessingFinishedByIdQueryVariables = Exact<{
  sceneId: Scalars['Int'];
}>;


export type FetchSceneProcessingFinishedByIdQuery = { __typename?: 'Query', sceneById: { __typename?: 'PanoramaScene', hasProcessingFinishedSuccessfully: boolean | null } | null };

export type FetchSamplesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSamplesQuery = { __typename?: 'Query', templates: Array<{ __typename?: 'Project', name: string, guid: string, internalCategory: string | null, thumbnailUrl: string | null }> };

export type CreateProjectBySampleMutationVariables = Exact<{
  sampleGuid: Scalars['UUID'];
}>;


export type CreateProjectBySampleMutation = { __typename?: 'Mutation', CreateProjectFromTemplate: { __typename?: 'CreateProjectFromTemplatePayload', project: { __typename?: 'Project', id: number, name: string } | null } };

export type FetchSubscriptionForCurrentUserQueryVariables = Exact<{
  returnUrl: Scalars['URL'];
}>;


export type FetchSubscriptionForCurrentUserQuery = { __typename?: 'Query', me: { __typename?: 'User', organization: { __typename?: 'Organization', billingPortal: string, subscriptionDetails: { __typename?: 'SubscriptionPageDto', pricingPlanCategory: SubscriptionTier, price: number | null, currency: string | null, nextBillingDate: Date | null, seats: number | null, lastFourDigits: string | null, creditCardBrand: string | null, billingAddress: string | null, taxId: string | null, invoices: Array<{ __typename?: 'InvoiceDto', amountPaid: number, invoicePdf: string, created: Date, currency: string, status: string }> | null } } | null } | null };

export type FetchMyActivePlanQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMyActivePlanQuery = { __typename?: 'Query', me: { __typename?: 'User', organization: { __typename?: 'Organization', purchasedSeats: number, pricingPlan: { __typename?: 'SubscriptionPlan', pricingPlanType: SubscriptionTier, numberOfCourses: number | null, scenesPerCourse: number | null, isSCORMExportAllowed: boolean, isSCORMCloudDispatchAllowed: boolean, isXAPIExportAllowed: boolean } } | null } | null };

export type FetchMeQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, email: string, role: Role, inviteState: InvitationState, name: { __typename?: 'Name', firstName: string, lastName: string } | null, organization: { __typename?: 'Organization', id: number, name: string } | null } | null };

export type FetchProjectsQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type FetchProjectsQuery = { __typename?: 'Query', projectsByOrganizationId: Array<{ __typename?: 'Project', id: number, thumbnailUrl: string | null, name: string }> };

export type FetchAppearanceQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type FetchAppearanceQuery = { __typename?: 'Query', projectById: { __typename?: 'Project', settings: { __typename?: 'ProjectSettings', watermark: string | null, logoUrl: string | null } } | null };

export type UpdateProjectWatermarkMutationVariables = Exact<{
  projectId: Scalars['Int'];
  watermark: Scalars['String'];
}>;


export type UpdateProjectWatermarkMutation = { __typename?: 'Mutation', SetProjectProperties: { __typename?: 'SetProjectPropertiesPayload', project: { __typename?: 'Project', id: number } | null } };

export type FetchProjectNameQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type FetchProjectNameQuery = { __typename?: 'Query', projectById: { __typename?: 'Project', name: string } | null };

export type ProjectPublicSharingSettingsQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type ProjectPublicSharingSettingsQuery = { __typename?: 'Query', projectPublicSharingSettings: { __typename?: 'ProjectPublicSharingData', publicIdentifier: string } | null };

export type FetchScenesQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type FetchScenesQuery = { __typename?: 'Query', scenesByOrganizationId: Array<{ __typename?: 'PanoramaScene', id: number, name: string, lastUpdateTimeUTC: Date, thumbnailUrl: string | null, hasProcessingFinishedSuccessfully: boolean | null }> };

export type FetchUsersQueryVariables = Exact<{
  organizationId: Scalars['Int'];
}>;


export type FetchUsersQuery = { __typename?: 'Query', usersByOrganizationId: Array<{ __typename?: 'User', id: number, email: string, role: Role, inviteState: InvitationState, name: { __typename?: 'Name', firstName: string, lastName: string } | null }> };

export type MyOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOnboardingQuery = { __typename?: 'Query', me: { __typename?: 'User', onboarding: { __typename?: 'OnboardingState', onboardingCheckList: Array<OnboardingTask>, isOnboardingFinished: boolean } | null } | null };

export type UpdateOnboardingMutationVariables = Exact<{
  onboarding: InputMaybe<OnboardingTask>;
  isFinished: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateOnboardingMutation = { __typename?: 'Mutation', UpdateOnboarding: { __typename?: 'UpdateOnboardingPayload', onboardingState: { __typename?: 'OnboardingState', onboardingCheckList: Array<OnboardingTask>, isOnboardingFinished: boolean } | null } };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', RemoveProject: { __typename?: 'RemoveProjectPayload', int: number | null } };

export type DuplicateProjectMutationVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type DuplicateProjectMutation = { __typename?: 'Mutation', DuplicateProject: { __typename?: 'DuplicateProjectPayload', int: number | null } };

export type AddNewProjectMutationVariables = Exact<{
  name: Scalars['String'];
  sceneId: InputMaybe<Scalars['Int']>;
}>;


export type AddNewProjectMutation = { __typename?: 'Mutation', AddProject: { __typename?: 'AddProjectPayload', project: { __typename?: 'Project', id: number, name: string } | null } };

export type GetProjectsByToursQueryVariables = Exact<{
  tourIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetProjectsByToursQuery = { __typename?: 'Query', projectsByTourIds: Array<{ __typename?: 'Project', name: string }> };

export type RenameProjectMutationVariables = Exact<{
  name: Scalars['String'];
  projectId: Scalars['Int'];
}>;


export type RenameProjectMutation = { __typename?: 'Mutation', SetProjectProperties: { __typename?: 'SetProjectPropertiesPayload', project: { __typename?: 'Project', id: number, name: string } | null } };

export type SetProjectPublicAvailabilityMutationVariables = Exact<{
  projectId: Scalars['Int'];
  isActive: Scalars['Boolean'];
}>;


export type SetProjectPublicAvailabilityMutation = { __typename?: 'Mutation', SetProjectPublicAvailability: { __typename?: 'SetProjectPublicAvailabilityPayload', projectPublicSharingData: { __typename?: 'ProjectPublicSharingData', publicIdentifier: string } | null } };

export type StartProjectExportMutationVariables = Exact<{
  projectId: Scalars['Int'];
  exportType: ExportType;
}>;


export type StartProjectExportMutation = { __typename?: 'Mutation', StartProjectExport: { __typename?: 'StartProjectExportPayload', string: string | null } };

export type DeleteSceneMutationVariables = Exact<{
  sceneId: Scalars['Int'];
}>;


export type DeleteSceneMutation = { __typename?: 'Mutation', DeletePanorama: { __typename?: 'DeletePanoramaPayload', result: { __typename?: 'Result', isSuccess: boolean, tourIds: Array<number> | null } | null } };

export type RenameSceneMutationVariables = Exact<{
  newName: Scalars['String'];
  sceneId: Scalars['Int'];
}>;


export type RenameSceneMutation = { __typename?: 'Mutation', RenamePanorama: { __typename?: 'RenamePanoramaPayload', panoramaScene: { __typename?: 'PanoramaScene', id: number } | null } };

export type EditMemberMutationVariables = Exact<{
  userId: Scalars['Int'];
  name: InputMaybe<NameInput>;
  role: InputMaybe<Role>;
  invitationAccepted: InputMaybe<Scalars['Boolean']>;
  marketingConsent: InputMaybe<Scalars['Boolean']>;
}>;


export type EditMemberMutation = { __typename?: 'Mutation', EditTeamMember: { __typename?: 'EditTeamMemberPayload', int: number | null } };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  role: Role;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', AddTeamMember: { __typename?: 'AddTeamMemberPayload', user: { __typename?: 'User', id: number } | null, errors: Array<{ __typename?: 'UserAlreadyExistsError', message: string }> | null } };

export type RemoveTeamMemberMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', RemoveTeamMember: { __typename?: 'RemoveTeamMemberPayload', int: number | null } };


export const FetchProratedInvoiceDocument = `
    query fetchProratedInvoice($nrOfSeats: Int!) {
  me {
    organization {
      proratedSeatPrice(nrOfSeats: $nrOfSeats) {
        priceIncrease
        prorateCharge
        nextBillingDate
        currency
        lastFourDigits
        creditCardBrand
      }
    }
  }
}
    `;
export const useFetchProratedInvoiceQuery = <
      TData = FetchProratedInvoiceQuery,
      TError = unknown
    >(
      variables: FetchProratedInvoiceQueryVariables,
      options?: UseQueryOptions<FetchProratedInvoiceQuery, TError, TData>
    ) =>
    useQuery<FetchProratedInvoiceQuery, TError, TData>(
      ['fetchProratedInvoice', variables],
      useFetcher<FetchProratedInvoiceQuery, FetchProratedInvoiceQueryVariables>(FetchProratedInvoiceDocument).bind(null, variables),
      options
    );
export const FetchScenesForNewProjectDocument = `
    query fetchScenesForNewProject($organizationId: Int!) {
  scenesByOrganizationId(organizationId: $organizationId) {
    id
    name
  }
}
    `;
export const useFetchScenesForNewProjectQuery = <
      TData = FetchScenesForNewProjectQuery,
      TError = unknown
    >(
      variables: FetchScenesForNewProjectQueryVariables,
      options?: UseQueryOptions<FetchScenesForNewProjectQuery, TError, TData>
    ) =>
    useQuery<FetchScenesForNewProjectQuery, TError, TData>(
      ['fetchScenesForNewProject', variables],
      useFetcher<FetchScenesForNewProjectQuery, FetchScenesForNewProjectQueryVariables>(FetchScenesForNewProjectDocument).bind(null, variables),
      options
    );
export const FetchSceneProcessingFinishedByIdDocument = `
    query fetchSceneProcessingFinishedById($sceneId: Int!) {
  sceneById(id: $sceneId) {
    hasProcessingFinishedSuccessfully
  }
}
    `;
export const useFetchSceneProcessingFinishedByIdQuery = <
      TData = FetchSceneProcessingFinishedByIdQuery,
      TError = unknown
    >(
      variables: FetchSceneProcessingFinishedByIdQueryVariables,
      options?: UseQueryOptions<FetchSceneProcessingFinishedByIdQuery, TError, TData>
    ) =>
    useQuery<FetchSceneProcessingFinishedByIdQuery, TError, TData>(
      ['fetchSceneProcessingFinishedById', variables],
      useFetcher<FetchSceneProcessingFinishedByIdQuery, FetchSceneProcessingFinishedByIdQueryVariables>(FetchSceneProcessingFinishedByIdDocument).bind(null, variables),
      options
    );
export const FetchSamplesDocument = `
    query fetchSamples {
  templates(order: [{name: ASC}]) {
    name
    guid
    internalCategory
    thumbnailUrl
  }
}
    `;
export const useFetchSamplesQuery = <
      TData = FetchSamplesQuery,
      TError = unknown
    >(
      variables?: FetchSamplesQueryVariables,
      options?: UseQueryOptions<FetchSamplesQuery, TError, TData>
    ) =>
    useQuery<FetchSamplesQuery, TError, TData>(
      variables === undefined ? ['fetchSamples'] : ['fetchSamples', variables],
      useFetcher<FetchSamplesQuery, FetchSamplesQueryVariables>(FetchSamplesDocument).bind(null, variables),
      options
    );
export const CreateProjectBySampleDocument = `
    mutation createProjectBySample($sampleGuid: UUID!) {
  CreateProjectFromTemplate(input: {templateIdentifier: $sampleGuid}) {
    project {
      id
      name
    }
  }
}
    `;
export const useCreateProjectBySampleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProjectBySampleMutation, TError, CreateProjectBySampleMutationVariables, TContext>) =>
    useMutation<CreateProjectBySampleMutation, TError, CreateProjectBySampleMutationVariables, TContext>(
      ['createProjectBySample'],
      useFetcher<CreateProjectBySampleMutation, CreateProjectBySampleMutationVariables>(CreateProjectBySampleDocument),
      options
    );
export const FetchSubscriptionForCurrentUserDocument = `
    query fetchSubscriptionForCurrentUser($returnUrl: URL!) {
  me {
    organization {
      billingPortal(returnUrl: $returnUrl)
      subscriptionDetails {
        pricingPlanCategory
        price
        currency
        nextBillingDate
        seats
        lastFourDigits
        creditCardBrand
        billingAddress
        taxId
        invoices {
          amountPaid
          invoicePdf
          created
          currency
          status
        }
      }
    }
  }
}
    `;
export const useFetchSubscriptionForCurrentUserQuery = <
      TData = FetchSubscriptionForCurrentUserQuery,
      TError = unknown
    >(
      variables: FetchSubscriptionForCurrentUserQueryVariables,
      options?: UseQueryOptions<FetchSubscriptionForCurrentUserQuery, TError, TData>
    ) =>
    useQuery<FetchSubscriptionForCurrentUserQuery, TError, TData>(
      ['fetchSubscriptionForCurrentUser', variables],
      useFetcher<FetchSubscriptionForCurrentUserQuery, FetchSubscriptionForCurrentUserQueryVariables>(FetchSubscriptionForCurrentUserDocument).bind(null, variables),
      options
    );
export const FetchMyActivePlanDocument = `
    query fetchMyActivePlan {
  me {
    organization {
      pricingPlan {
        pricingPlanType
        numberOfCourses
        scenesPerCourse
        isSCORMExportAllowed
        isSCORMCloudDispatchAllowed
        isXAPIExportAllowed
      }
      purchasedSeats
    }
  }
}
    `;
export const useFetchMyActivePlanQuery = <
      TData = FetchMyActivePlanQuery,
      TError = unknown
    >(
      variables?: FetchMyActivePlanQueryVariables,
      options?: UseQueryOptions<FetchMyActivePlanQuery, TError, TData>
    ) =>
    useQuery<FetchMyActivePlanQuery, TError, TData>(
      variables === undefined ? ['fetchMyActivePlan'] : ['fetchMyActivePlan', variables],
      useFetcher<FetchMyActivePlanQuery, FetchMyActivePlanQueryVariables>(FetchMyActivePlanDocument).bind(null, variables),
      options
    );
export const FetchMeDocument = `
    query fetchMe {
  me {
    id
    name {
      firstName
      lastName
    }
    email
    role
    inviteState
    organization {
      id
      name
    }
  }
}
    `;
export const useFetchMeQuery = <
      TData = FetchMeQuery,
      TError = unknown
    >(
      variables?: FetchMeQueryVariables,
      options?: UseQueryOptions<FetchMeQuery, TError, TData>
    ) =>
    useQuery<FetchMeQuery, TError, TData>(
      variables === undefined ? ['fetchMe'] : ['fetchMe', variables],
      useFetcher<FetchMeQuery, FetchMeQueryVariables>(FetchMeDocument).bind(null, variables),
      options
    );
export const FetchProjectsDocument = `
    query fetchProjects($organizationId: Int!) {
  projectsByOrganizationId(organizationId: $organizationId) {
    id
    thumbnailUrl
    name
  }
}
    `;
export const useFetchProjectsQuery = <
      TData = FetchProjectsQuery,
      TError = unknown
    >(
      variables: FetchProjectsQueryVariables,
      options?: UseQueryOptions<FetchProjectsQuery, TError, TData>
    ) =>
    useQuery<FetchProjectsQuery, TError, TData>(
      ['fetchProjects', variables],
      useFetcher<FetchProjectsQuery, FetchProjectsQueryVariables>(FetchProjectsDocument).bind(null, variables),
      options
    );
export const FetchAppearanceDocument = `
    query fetchAppearance($projectId: Int!) {
  projectById(id: $projectId) {
    settings {
      watermark
      logoUrl
    }
  }
}
    `;
export const useFetchAppearanceQuery = <
      TData = FetchAppearanceQuery,
      TError = unknown
    >(
      variables: FetchAppearanceQueryVariables,
      options?: UseQueryOptions<FetchAppearanceQuery, TError, TData>
    ) =>
    useQuery<FetchAppearanceQuery, TError, TData>(
      ['fetchAppearance', variables],
      useFetcher<FetchAppearanceQuery, FetchAppearanceQueryVariables>(FetchAppearanceDocument).bind(null, variables),
      options
    );
export const UpdateProjectWatermarkDocument = `
    mutation updateProjectWatermark($projectId: Int!, $watermark: String!) {
  SetProjectProperties(input: {projectId: $projectId, watermark: $watermark}) {
    project {
      id
    }
  }
}
    `;
export const useUpdateProjectWatermarkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProjectWatermarkMutation, TError, UpdateProjectWatermarkMutationVariables, TContext>) =>
    useMutation<UpdateProjectWatermarkMutation, TError, UpdateProjectWatermarkMutationVariables, TContext>(
      ['updateProjectWatermark'],
      useFetcher<UpdateProjectWatermarkMutation, UpdateProjectWatermarkMutationVariables>(UpdateProjectWatermarkDocument),
      options
    );
export const FetchProjectNameDocument = `
    query fetchProjectName($projectId: Int!) {
  projectById(id: $projectId) {
    name
  }
}
    `;
export const useFetchProjectNameQuery = <
      TData = FetchProjectNameQuery,
      TError = unknown
    >(
      variables: FetchProjectNameQueryVariables,
      options?: UseQueryOptions<FetchProjectNameQuery, TError, TData>
    ) =>
    useQuery<FetchProjectNameQuery, TError, TData>(
      ['fetchProjectName', variables],
      useFetcher<FetchProjectNameQuery, FetchProjectNameQueryVariables>(FetchProjectNameDocument).bind(null, variables),
      options
    );
export const ProjectPublicSharingSettingsDocument = `
    query projectPublicSharingSettings($projectId: Int!) {
  projectPublicSharingSettings(id: $projectId) {
    publicIdentifier
  }
}
    `;
export const useProjectPublicSharingSettingsQuery = <
      TData = ProjectPublicSharingSettingsQuery,
      TError = unknown
    >(
      variables: ProjectPublicSharingSettingsQueryVariables,
      options?: UseQueryOptions<ProjectPublicSharingSettingsQuery, TError, TData>
    ) =>
    useQuery<ProjectPublicSharingSettingsQuery, TError, TData>(
      ['projectPublicSharingSettings', variables],
      useFetcher<ProjectPublicSharingSettingsQuery, ProjectPublicSharingSettingsQueryVariables>(ProjectPublicSharingSettingsDocument).bind(null, variables),
      options
    );
export const FetchScenesDocument = `
    query fetchScenes($organizationId: Int!) {
  scenesByOrganizationId(organizationId: $organizationId, order: {id: DESC}) {
    id
    name
    lastUpdateTimeUTC
    thumbnailUrl
    hasProcessingFinishedSuccessfully
  }
}
    `;
export const useFetchScenesQuery = <
      TData = FetchScenesQuery,
      TError = unknown
    >(
      variables: FetchScenesQueryVariables,
      options?: UseQueryOptions<FetchScenesQuery, TError, TData>
    ) =>
    useQuery<FetchScenesQuery, TError, TData>(
      ['fetchScenes', variables],
      useFetcher<FetchScenesQuery, FetchScenesQueryVariables>(FetchScenesDocument).bind(null, variables),
      options
    );
export const FetchUsersDocument = `
    query fetchUsers($organizationId: Int!) {
  usersByOrganizationId(organizationId: $organizationId) {
    id
    name {
      firstName
      lastName
    }
    email
    role
    inviteState
  }
}
    `;
export const useFetchUsersQuery = <
      TData = FetchUsersQuery,
      TError = unknown
    >(
      variables: FetchUsersQueryVariables,
      options?: UseQueryOptions<FetchUsersQuery, TError, TData>
    ) =>
    useQuery<FetchUsersQuery, TError, TData>(
      ['fetchUsers', variables],
      useFetcher<FetchUsersQuery, FetchUsersQueryVariables>(FetchUsersDocument).bind(null, variables),
      options
    );
export const MyOnboardingDocument = `
    query myOnboarding {
  me {
    onboarding {
      onboardingCheckList
      isOnboardingFinished
    }
  }
}
    `;
export const useMyOnboardingQuery = <
      TData = MyOnboardingQuery,
      TError = unknown
    >(
      variables?: MyOnboardingQueryVariables,
      options?: UseQueryOptions<MyOnboardingQuery, TError, TData>
    ) =>
    useQuery<MyOnboardingQuery, TError, TData>(
      variables === undefined ? ['myOnboarding'] : ['myOnboarding', variables],
      useFetcher<MyOnboardingQuery, MyOnboardingQueryVariables>(MyOnboardingDocument).bind(null, variables),
      options
    );
export const UpdateOnboardingDocument = `
    mutation updateOnboarding($onboarding: OnboardingTask, $isFinished: Boolean) {
  UpdateOnboarding(
    input: {newOnboardingStep: $onboarding, isOnboardingFinished: $isFinished}
  ) {
    onboardingState {
      onboardingCheckList
      isOnboardingFinished
    }
  }
}
    `;
export const useUpdateOnboardingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOnboardingMutation, TError, UpdateOnboardingMutationVariables, TContext>) =>
    useMutation<UpdateOnboardingMutation, TError, UpdateOnboardingMutationVariables, TContext>(
      ['updateOnboarding'],
      useFetcher<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>(UpdateOnboardingDocument),
      options
    );
export const DeleteProjectDocument = `
    mutation deleteProject($projectId: Int!) {
  RemoveProject(input: {projectId: $projectId}) {
    int
  }
}
    `;
export const useDeleteProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteProjectMutation, TError, DeleteProjectMutationVariables, TContext>) =>
    useMutation<DeleteProjectMutation, TError, DeleteProjectMutationVariables, TContext>(
      ['deleteProject'],
      useFetcher<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument),
      options
    );
export const DuplicateProjectDocument = `
    mutation duplicateProject($projectId: Int!) {
  DuplicateProject(input: {projectId: $projectId}) {
    int
  }
}
    `;
export const useDuplicateProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DuplicateProjectMutation, TError, DuplicateProjectMutationVariables, TContext>) =>
    useMutation<DuplicateProjectMutation, TError, DuplicateProjectMutationVariables, TContext>(
      ['duplicateProject'],
      useFetcher<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument),
      options
    );
export const AddNewProjectDocument = `
    mutation addNewProject($name: String!, $sceneId: Int) {
  AddProject(input: {name: $name, sceneId: $sceneId}) {
    project {
      id
      name
    }
  }
}
    `;
export const useAddNewProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddNewProjectMutation, TError, AddNewProjectMutationVariables, TContext>) =>
    useMutation<AddNewProjectMutation, TError, AddNewProjectMutationVariables, TContext>(
      ['addNewProject'],
      useFetcher<AddNewProjectMutation, AddNewProjectMutationVariables>(AddNewProjectDocument),
      options
    );
export const GetProjectsByToursDocument = `
    query getProjectsByTours($tourIds: [Int!]!) {
  projectsByTourIds(tourIds: $tourIds) {
    name
  }
}
    `;
export const useGetProjectsByToursQuery = <
      TData = GetProjectsByToursQuery,
      TError = unknown
    >(
      variables: GetProjectsByToursQueryVariables,
      options?: UseQueryOptions<GetProjectsByToursQuery, TError, TData>
    ) =>
    useQuery<GetProjectsByToursQuery, TError, TData>(
      ['getProjectsByTours', variables],
      useFetcher<GetProjectsByToursQuery, GetProjectsByToursQueryVariables>(GetProjectsByToursDocument).bind(null, variables),
      options
    );
export const RenameProjectDocument = `
    mutation renameProject($name: String!, $projectId: Int!) {
  SetProjectProperties(input: {newName: $name, projectId: $projectId}) {
    project {
      id
      name
    }
  }
}
    `;
export const useRenameProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RenameProjectMutation, TError, RenameProjectMutationVariables, TContext>) =>
    useMutation<RenameProjectMutation, TError, RenameProjectMutationVariables, TContext>(
      ['renameProject'],
      useFetcher<RenameProjectMutation, RenameProjectMutationVariables>(RenameProjectDocument),
      options
    );
export const SetProjectPublicAvailabilityDocument = `
    mutation setProjectPublicAvailability($projectId: Int!, $isActive: Boolean!) {
  SetProjectPublicAvailability(
    input: {projectId: $projectId, isActive: $isActive}
  ) {
    projectPublicSharingData {
      publicIdentifier
    }
  }
}
    `;
export const useSetProjectPublicAvailabilityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetProjectPublicAvailabilityMutation, TError, SetProjectPublicAvailabilityMutationVariables, TContext>) =>
    useMutation<SetProjectPublicAvailabilityMutation, TError, SetProjectPublicAvailabilityMutationVariables, TContext>(
      ['setProjectPublicAvailability'],
      useFetcher<SetProjectPublicAvailabilityMutation, SetProjectPublicAvailabilityMutationVariables>(SetProjectPublicAvailabilityDocument),
      options
    );
export const StartProjectExportDocument = `
    mutation startProjectExport($projectId: Int!, $exportType: ExportType!) {
  StartProjectExport(input: {projectId: $projectId, exportType: $exportType}) {
    string
  }
}
    `;
export const useStartProjectExportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<StartProjectExportMutation, TError, StartProjectExportMutationVariables, TContext>) =>
    useMutation<StartProjectExportMutation, TError, StartProjectExportMutationVariables, TContext>(
      ['startProjectExport'],
      useFetcher<StartProjectExportMutation, StartProjectExportMutationVariables>(StartProjectExportDocument),
      options
    );
export const DeleteSceneDocument = `
    mutation deleteScene($sceneId: Int!) {
  DeletePanorama(input: {sceneId: $sceneId}) {
    result {
      isSuccess
      tourIds
    }
  }
}
    `;
export const useDeleteSceneMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSceneMutation, TError, DeleteSceneMutationVariables, TContext>) =>
    useMutation<DeleteSceneMutation, TError, DeleteSceneMutationVariables, TContext>(
      ['deleteScene'],
      useFetcher<DeleteSceneMutation, DeleteSceneMutationVariables>(DeleteSceneDocument),
      options
    );
export const RenameSceneDocument = `
    mutation renameScene($newName: String!, $sceneId: Int!) {
  RenamePanorama(input: {newName: $newName, sceneId: $sceneId}) {
    panoramaScene {
      id
    }
  }
}
    `;
export const useRenameSceneMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RenameSceneMutation, TError, RenameSceneMutationVariables, TContext>) =>
    useMutation<RenameSceneMutation, TError, RenameSceneMutationVariables, TContext>(
      ['renameScene'],
      useFetcher<RenameSceneMutation, RenameSceneMutationVariables>(RenameSceneDocument),
      options
    );
export const EditMemberDocument = `
    mutation editMember($userId: Int!, $name: NameInput, $role: Role, $invitationAccepted: Boolean, $marketingConsent: Boolean) {
  EditTeamMember(
    input: {userId: $userId, name: $name, newRole: $role, hasAcceptedInvite: $invitationAccepted, hasGivenMarketingConsent: $marketingConsent}
  ) {
    int
  }
}
    `;
export const useEditMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditMemberMutation, TError, EditMemberMutationVariables, TContext>) =>
    useMutation<EditMemberMutation, TError, EditMemberMutationVariables, TContext>(
      ['editMember'],
      useFetcher<EditMemberMutation, EditMemberMutationVariables>(EditMemberDocument),
      options
    );
export const InviteUserDocument = `
    mutation inviteUser($email: String!, $role: Role!) {
  AddTeamMember(input: {newMemberEmail: $email, newMemberRole: $role}) {
    user {
      id
    }
    errors {
      ... on UserAlreadyExistsError {
        message
      }
    }
  }
}
    `;
export const useInviteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InviteUserMutation, TError, InviteUserMutationVariables, TContext>) =>
    useMutation<InviteUserMutation, TError, InviteUserMutationVariables, TContext>(
      ['inviteUser'],
      useFetcher<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument),
      options
    );
export const RemoveTeamMemberDocument = `
    mutation removeTeamMember($userId: Int!) {
  RemoveTeamMember(input: {userId: $userId}) {
    int
  }
}
    `;
export const useRemoveTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>) =>
    useMutation<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>(
      ['removeTeamMember'],
      useFetcher<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument),
      options
    );