import React from 'react';
import { Button, ButtonAction } from '../../atoms/buttons/Button';

export type ErrorProps = {
  error: string;
  title: string;
  description: string;
  primary?: ButtonAction;
  secondary?: ButtonAction;
};

export const Error: React.FC<ErrorProps> = ({ error, title, description, primary, secondary }) => {
  return (
    <div className="bg-white min-h-max px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl md:text-5xl font-extrabold text-blue-600">{error}</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl md:text-5xl sm:text-left font-extrabold text-gray-900 tracking-tight ">
                {title}
              </h1>
              <p className="mt-1 text-sm sm:text-left text-gray-500">{description}</p>
            </div>
            <div className="mt-10 flex justify-center sm:justify-start space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {primary && <Button {...primary} size="m" variant="primary" />}
              {secondary && <Button {...secondary} size="m" variant="tertiary" />}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
