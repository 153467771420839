import { createContext } from 'react';
import { OnboardingTask } from '../../api/graphql/generated';
import { OnboardingStatus } from './OnboardingStatus';

export type UpdateOnboardingParams = { taskCompleted?: OnboardingTask; isFinished?: boolean };

export type OnboardingStatusContext = {
  onboardingStatus: OnboardingStatus;
  isLoaded: boolean;
  updateOnboarding: (params: UpdateOnboardingParams) => void;
};

export const DEFAULT_ONBOARDING_STATUS = { completedTasks: [], isFinished: false };
export const OnboardingContext = createContext<OnboardingStatusContext>({
  isLoaded: true,
  onboardingStatus: DEFAULT_ONBOARDING_STATUS,
  updateOnboarding: () => {
    throw new Error('Do not use update onboaring yet!!!');
  },
});
