import React from 'react';
import classNames from 'classnames';

type GapVariant = 's' | 'm' | 'l' | 'xl';
const GapVariantToStyle: Record<GapVariant, string> = {
  s: 'gap-2',
  m: 'gap-3',
  l: 'gap-4',
  xl: 'gap-6',
};

type PlaceItemFromEndProps = React.PropsWithChildren & {
  gap: GapVariant;
};

export const ItemsFromEndLayout: React.FC<PlaceItemFromEndProps> = ({ gap, children }) => {
  return <div className={classNames('flex justify-end', GapVariantToStyle[gap])}>{children}</div>;
};
