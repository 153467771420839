import React, { useState } from 'react';
import { useOrganizationRepo } from '../../repositories/organization/OrganizationRepo';
import { Button } from '../../components/atoms/buttons/Button';
import { Divider } from '../../components/atoms/divider/Divider';
import { LabelOnLeftBox } from '../../components/atoms/inputs/layouts/LabelOnLeftBox';
import { TextInput } from '../../components/atoms/inputs/text/TextInput';
import { AlignedContent } from '../../components/atoms/layouts/AlignedContent';
import { MenuContentContainer } from '../../components/atoms/layouts/MenuContentContainer';
import { SectionHeader } from '../../components/organisms/SectionHeader';
import { useToast } from '../../hooks/useToast';
import { MAX_INPUT_LENGTH_100 } from '../../constants';
import { isStringEmptyOrWhiteSpace } from '../../utils/isStringEmptyOrWhiteSpace';
import { useMe } from '../../contexts/me/MeConsumer';

export const General: React.FC = () => {
  const meContext = useMe(true);
  const { renameOrganization } = useOrganizationRepo();

  const [defaultOrganizationName, setDefaultOrganizationName] = useState(meContext.me.organization.name);

  const [organizationName, setOrganizationName] = useState(defaultOrganizationName);
  const hasChange = defaultOrganizationName !== organizationName;

  const { addSuccessToast, addErrorToast } = useToast();

  const rename = () => {
    if (organizationName) {
      renameOrganization
        .execute(organizationName)
        .then(() => {
          void meContext.refetchMe();
          addSuccessToast('Organization renamed', `Your organization was renamed to "${organizationName}"`);
        })
        .catch(() => {
          addErrorToast(
            'Something went wrong',
            'We have detected an error when communicating with server, please try again.'
          );
        });
      setDefaultOrganizationName(organizationName);
    }
  };

  return (
    <>
      <MenuContentContainer>
        <SectionHeader>
          <SectionHeader.Title>Organization Settings</SectionHeader.Title>
          <SectionHeader.Subtitle>Manage your organization&apos;s information.</SectionHeader.Subtitle>
        </SectionHeader>
        <Divider />

        <AlignedContent gap="s" direction="column">
          {organizationName !== undefined && (
            <LabelOnLeftBox label="Organization name">
              <TextInput
                type="text"
                size="full"
                defaultValue={organizationName}
                onInput={(e) => {
                  setOrganizationName(e.currentTarget.value);
                }}
                maxLength={MAX_INPUT_LENGTH_100}
              />
            </LabelOnLeftBox>
          )}
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button
              label="Save changes"
              action={rename}
              variant="primary"
              size="m"
              disabled={!hasChange || !organizationName || isStringEmptyOrWhiteSpace(organizationName)}
              loading={renameOrganization.isLoading}
            />
          </AlignedContent>
        </AlignedContent>
      </MenuContentContainer>
    </>
  );
};
