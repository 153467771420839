import React, { useState } from 'react';
import { Select } from '../../components/atoms/inputs/select/Select';
import { SelectObject } from '../../components/atoms/inputs/select/SelectObject';
import { Option } from '../../components/atoms/inputs/select/Option';

export const SelectMenuExample: React.FC = () => {
  const people: SelectObject[] = [
    { id: 1, name: 'Wade Cooper' },
    { id: 2, name: 'Arlene Mccoy' },
    { id: 3, name: 'Devon Webb' },
    { id: 4, name: 'Tom Cook' },
    { id: 5, name: 'Tanya Fox' },
    { id: 6, name: 'Hellen Schmidt' },
    { id: 7, name: 'Caroline Schultz' },
    { id: 8, name: 'Mason Heaney' },
    { id: 9, name: 'Claudie Smitham' },
    { id: 10, name: 'Emil Schaefer' },
  ];

  const [selected, setSelected] = useState(people[0]);

  return (
    <div className="px-4 py-12 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <Select
        label="Assigned to"
        selected={selected}
        onChange={(id) => {
          const person = people.find((person) => person.id === id);
          if (person) {
            setSelected(person);
          }
        }}
      >
        {people.map((person) => (
          <Option key={person.id} value={person} />
        ))}
      </Select>
    </div>
  );
};
