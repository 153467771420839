import React from 'react';
import classnames from 'classnames';

type Direction = 'row' | 'column';

const DirectionToClassname: Record<Direction, string> = {
  row: 'flex flex-row',
  column: 'flex flex-col',
};

type Gap = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xxl' | 'xxxl' | 'space-between';

const GapToClassname: Record<Gap, string> = {
  xxs: 'gap-1',
  xs: 'gap-2',
  s: 'gap-3',
  m: 'gap-5',
  l: 'gap-6',
  xxl: 'gap-12',
  xxxl: 'gap-16',
  'space-between': 'justify-between gap-3',
};

type Placing = 'fromStart' | 'fromEnd' | 'space-between';

const PlacingToClassname: Record<Placing, string> = {
  'space-between': 'justify-between',
  fromEnd: 'justify-end',
  fromStart: 'justify-start',
};

type Positioning = 'normal' | 'center';

const PositioningToClassname: Record<Positioning, string> = {
  center: 'items-center',
  normal: '',
};

type AlignedContentProps = React.PropsWithChildren & {
  direction: Direction;
  gap?: Gap;
  placing?: Placing;
  positioning?: Positioning;
};

export const AlignedContent: React.FC<AlignedContentProps> = ({
  direction,
  gap = 's',
  placing = 'fromStart',
  positioning = 'normal',
  children,
}) => {
  return (
    <div
      className={classnames(
        DirectionToClassname[direction],
        GapToClassname[gap],
        PlacingToClassname[placing],
        PositioningToClassname[positioning]
      )}
    >
      {children}
    </div>
  );
};
