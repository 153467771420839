import { useRenameProjectMutation } from '../../api/graphql/generated';
import { RenameFunction, RenameModal } from './RenameModal';
import React from 'react';
import { useToast } from '../../hooks/useToast';

type RenameProjectModalProps = {
  projectId: number;
  oldName: string;
  onRename: (newName: string) => void;
  onCancel: () => void;
};

export const RenameProjectModal = ({ projectId, oldName, onRename, onCancel }: RenameProjectModalProps) => {
  const renameProject = useRenameProjectMutation();
  const { addSuccessToast, addErrorToast } = useToast();
  const renameProjectHandler: RenameFunction = Object.assign(
    (newName: string) => {
      renameProject
        .mutateAsync({ projectId, name: newName })
        .then(() => {
          addSuccessToast('Course renamed', `Your course was successfully renamed to ${newName}.`);
          onRename(newName);
        })
        .catch((reason) => {
          addErrorToast('Something went wrong', "We were not able to change course's name.");
        });
    },
    {
      isLoading: renameProject.isLoading,
    }
  );
  return (
    <RenameModal
      config={{ title: `Rename Course`, nameInputLabel: 'Course Name' }}
      oldName={oldName}
      onRename={renameProjectHandler}
      onCancel={onCancel}
    />
  );
};
