import React from 'react';
import { UserActions } from '../../../plugins/auth/Authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import classnames from 'classnames';

type MenuProfileProps = React.PropsWithChildren & {
  viewProfileAction?: () => void;
  user: UserActions['user'];
  logout: () => void;
};
export const MenuProfile: React.FC<MenuProfileProps> = ({ viewProfileAction, user, logout, children }) => {
  //TODO: change span back to anchor, give it href to view profile, remove viewProfileAction
  return (
    <div className="flex-shrink-0 flex flex-col space-y-5 border-t border-gray-700 p-4">
      <span className="flex-shrink-0 w-full group block" onClick={viewProfileAction}>
        <div className="flex items-center">
          <div>
            <img className="inline-block h-9 w-9 rounded-full" src={user.picture} alt={user.name} />
          </div>
          <div className="ml-3">
            <p
              className={classnames('text-sm font-medium text-white', {
                'group-hover:text-gray-400': !!viewProfileAction,
              })}
            >
              {user.name}
            </p>
            {viewProfileAction && (
              <p className="text-xs font-medium text-gray-500 group-hover:text-gray-600">View profile</p>
            )}
          </div>
        </div>
      </span>
      <button className="flex items-center text-sm font-medium text-white hover:text-gray-400" onClick={logout}>
        <FontAwesomeIcon className="mr-3 flex-shrink-0 h-4 w-4" icon={regular('arrow-right-from-bracket')} />
        Log out
      </button>
    </div>
  );
};
