import React from 'react';
import classNames from 'classnames';
import { Menu } from '@headlessui/react';
import { ButtonSize, sizeToClassnames } from '../../atoms/buttons/Button';

type Variant = 'default' | 'text';

const VariantToClassname: Record<Variant, string> = {
  default:
    'text-gray-700 border border-gray-300 shadow-sm focus:ring-2 focus:ring-offset-2' +
    'focus:ring-offset-gray-100 focus:ring-blue-500 hover:bg-gray-50 focus:outline-none',
  text: 'text-blue-500 font-semibold disabled:text-gray-500',
};

type DropDownButtonProps = React.PropsWithChildren & {
  size?: ButtonSize;
  variant?: Variant;
  disabled?: boolean;
};

export const DropDownButton: React.FC<DropDownButtonProps> = ({
  variant = 'default',
  size = 'm',
  disabled,
  children,
}) => {
  return (
    <Menu.Button
      className={classNames(
        VariantToClassname[variant],
        {
          [sizeToClassnames[size]]: variant !== 'default',
        },
        'inline-flex justify-center w-full  overflow-auto rounded-md p-1 bg-white text-base '
      )}
      disabled={disabled}
    >
      {children}
    </Menu.Button>
  );
};
