import React from 'react';

type LabelOnLeftBoxProps = React.PropsWithChildren & {
  label: React.ReactNode;
};

export const LabelOnLeftBox: React.FC<LabelOnLeftBoxProps> = ({ label, children }) => {
  return (
    <div className="@container">
      <div className="space-y-1 @md:space-y-0 @md:flex @md:flex-row @md:items-center @md:gap-4 @md:items-start">
        <div className="grow-0">{label}</div>
        <div className="grow">{children}</div>
      </div>
    </div>
  );
};
