import { useMutation } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type Seats = {
  nrOfSeats: number;
};

export const useModifySeatsMutation = () => {
  const { post } = useFetch();

  return useMutation({
    mutationFn: async (nrOfSeats: number) => {
      return await post<Response, Seats>('/api/ModifySeats', { nrOfSeats: nrOfSeats });
    },
  });
};
