import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FilesListItem } from './FileListItem';

type FileListItemProps = {
  id: string;
  file: File;
  onRemove: (id: string) => void;
};

export const FilesListUploadItem: React.FC<FileListItemProps> = ({ file, id, onRemove }) => {
  return (
    <FilesListItem
      icon={solid('image')}
      filename={file.name}
      button={
        <span
          className="float-right mr-2 text-grey-400"
          role="button"
          aria-label="remove file"
          onClick={() => onRemove(id)}
        >
          <FontAwesomeIcon icon={regular('xmark')} />
        </span>
      }
    />
  );
};
