import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';

type Variant = 'pending' | 'completed' | 'focused';

const VariantToTextColor: Record<Variant, string> = {
  completed: 'text-white',
  focused: 'text-grey-300',
  pending: 'text-grey-500',
};

const VariantToIconColor: Record<Variant, string> = {
  completed: 'text-blue-500',
  focused: 'text-grey-300',
  pending: 'text-grey-500',
};

const VariantToBoxClassnames: Record<Variant, string> = {
  completed: '',
  focused: 'bg-grey-800 rounded',
  pending: '',
};

type ChecklistItemProps = {
  variant: Variant;
  children: React.ReactNode;
};
export const ChecklistItem = ({ variant, children }: ChecklistItemProps) => {
  return (
    <div className={classNames('flex flex-row gap-3 py-2.5 px-3 ', VariantToBoxClassnames[variant])}>
      <div className={VariantToIconColor[variant]}>
        <FontAwesomeIcon icon={solid('check-circle')} />
      </div>
      <div className={VariantToTextColor[variant]}>{children}</div>
    </div>
  );
};
