import React, { useState } from 'react';
import { RadioObject } from '../atoms/inputs/radios/RadioObject';
import { Role } from '../../api/graphql/generated';
import { RadioOption } from '../atoms/inputs/radios/RadioOption';
import { RadioGroup } from '../atoms/inputs/radios/RadioGroup';

type RoleOption = RadioObject & {
  role: Role;
};

const EditorOptions: RoleOption[] = [
  { id: 1, role: Role.Author, label: 'Author', description: 'Course authoring capabilities' },
  {
    id: 2,
    role: Role.Owner,
    label: 'Owner',
    description: 'Course authoring capabilities, managing members, billing and other company information.',
  },
];

type RoleRadioGroupProps = {
  id?: string;
  preselected?: Role;
  onChange: (selected: Role) => void;
};

export const RoleRadioGroup: React.FC<RoleRadioGroupProps> = ({ id, preselected, onChange }) => {
  const [selected, setSelected] = useState(
    EditorOptions.find((search) => search.role === preselected) ?? EditorOptions[0]
  );
  return (
    <RadioGroup
      id={id}
      selected={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected.role);
      }}
    >
      {EditorOptions.map((role) => (
        <RadioOption key={role.id} value={role} />
      ))}
    </RadioGroup>
  );
};
