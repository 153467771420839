import { SectionHeader } from '../../components/organisms/SectionHeader';
import { Button } from '../../components/atoms/buttons/Button';
import { Box } from '../../components/atoms/layouts/Box';
import React, { useState } from 'react';
import { Select } from '../../components/atoms/inputs/select/Select';
import { Option } from '../../components/atoms/inputs/select/Option';
import { RadioObject } from '../../components/atoms/inputs/radios/RadioObject';
import { RadioGroupTest } from './RadioGroupTest';

export function HeaderSectionExampleWithTabsChangingToSelectForSmallScreens() {
  const tabs = [
    { name: 'SCENES', current: false },
    { name: 'TOURS', current: true },
  ];

  const [stateTabs, setStateTabs] = useState(tabs);

  const click = (name: string) => {
    const newTabs = stateTabs.map((tab) => {
      tab.current = tab.name === name;
      return tab;
    });
    setStateTabs(newTabs);
  };

  const select = (idx: number) => {
    const newTabs = stateTabs.map((tab, tabIdx) => {
      tab.current = tabIdx === idx;
      return tab;
    });
    setStateTabs(newTabs);
  };

  const selectedIdx = stateTabs.findIndex((tab) => tab.current);

  const radioOptions: RadioObject[] = [
    { id: 1, label: 'Public access', description: 'This course would be available to anyone who has the link' },
    { id: 2, label: 'Private to Course Members' },
    { id: 3, label: 'Private to you', description: 'You are the only one able to access this course' },
  ];

  return (
    <div className="px-4 py-12 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <SectionHeader>
        <SectionHeader.TitleWithActions title={'Tour Builder'}>
          <Button label={'Create new tour'} action={() => alert('click')} size={'m'} variant={'primary'} />
          <Button label={'Delete tour'} action={() => alert('click')} size={'m'} variant={'secondary'} />
        </SectionHeader.TitleWithActions>
        <SectionHeader.Tabs>
          <Box visibleFor="sm">
            <Select
              name="current-tab"
              label="Select a tab"
              selected={{ id: selectedIdx, name: stateTabs[selectedIdx].name }}
              onChange={select}
            >
              {stateTabs.map((tab, tabIdx) => (
                <Option key={tabIdx} value={{ id: tabIdx, name: tab.name }} />
              ))}
            </Select>
          </Box>
          <Box visibleFor=">sm">
            <SectionHeader.TabBar>
              {stateTabs.map((tab) => (
                // eslint-disable-next-line
                <SectionHeader.Tab key={tab.name} onClick={() => click(tab.name)} selected={tab.current}>
                  {tab.name}
                </SectionHeader.Tab>
              ))}
            </SectionHeader.TabBar>
          </Box>
        </SectionHeader.Tabs>
      </SectionHeader>
      <RadioGroupTest radioOptions={radioOptions} />
    </div>
  );
}
