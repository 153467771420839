import React from 'react';
import classNames from 'classnames';

type Variant = 'blue' | 'white';

const VariantToClassname: Record<Variant, string> = {
  blue: 'bg-blue-500/80',
  white: 'bg-white/80',
};

type CardActionBoxProps = React.PropsWithChildren & {
  variant: Variant;
};

export const CardActionBox = ({ variant, children }: CardActionBoxProps): JSX.Element => {
  return (
    <div className={classNames(VariantToClassname[variant], 'w-full h-full flex justify-center items-center')}>
      {children}
    </div>
  );
};
