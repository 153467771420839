import React from 'react';
import classNames from 'classnames';

type Variant = 'thumbnail' | 'logo';

const VariantToClassname: Record<Variant, string> = {
  thumbnail: 'aspect-w-220 aspect-h-149 w-full min-w-max',
  logo: 'aspect-w-220 aspect-h-149 w-full min-w-max',
};

type AspectRatioBoxProps = React.PropsWithChildren & {
  variant: Variant;
};

export const AspectRatioBox = ({ variant, children }: AspectRatioBoxProps): JSX.Element => {
  return <div className={classNames(VariantToClassname[variant])}>{children}</div>;
};
