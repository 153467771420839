import React from 'react';

type SettingDescriptionListRowProps = {
  label: string;
  value: React.ReactNode;
  buttons?: { label: string; action: () => void }[];
};

export const SettingDescriptionListRow: React.FC<SettingDescriptionListRowProps> = ({ label, value, buttons }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow capitalize">{value}</span>
        <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
          {buttons &&
            buttons.map((button, index) => {
              return (
                <React.Fragment key={index}>
                  <button
                    type="button"
                    className="bg-white rounded-md font-medium text-blue-500 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={button.action}
                  >
                    {button.label}
                  </button>
                  {buttons.length > 1 && index !== buttons.length - 1 && (
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                  )}
                </React.Fragment>
              );
            })}
        </span>
      </dd>
    </div>
  );
};
