import React, { PropsWithChildren, useEffect } from 'react';
import { SubmenuItem } from './SubmenuItem';
import { useSwitch } from '../../../hooks/useSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

type SubmenuContent = {
  Item: typeof SubmenuItem;
};

type SubmenuProps = PropsWithChildren & {
  label: React.ReactNode;
};

export const Submenu: React.FC<SubmenuProps> & SubmenuContent = ({ label, children }) => {
  const mobileMenuVisible = useSwitch(false);

  const { pathname } = useLocation();
  useEffect(() => {
    mobileMenuVisible.turnOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isSmallScreenSize = useMediaQuery('(max-width: 1024px)');
  // Disable scrolling on body when mobile menu is open
  useEffect(() => {
    if (isSmallScreenSize && mobileMenuVisible.isOn) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSmallScreenSize, mobileMenuVisible.isOn]);

  return (
    <>
      <div className="sticky z-20 flex items-center justify-start w-full p-3 bg-white border-b-2 top-44px md:top-0 lg:hidden border-grey-350">
        <FontAwesomeIcon
          icon={mobileMenuVisible.isOn ? solid('xmark') : solid('bars')}
          className="absolute p-2 text-sm cursor-pointer text-grey-1000"
          onClick={mobileMenuVisible.toggle}
          fixedWidth
        />
        <div className="mx-auto flex-grow-1">{label}</div>
      </div>
      <nav
        aria-label="Sections"
        className={classNames(
          'w-full h-screen shrink-0 grow-0',
          'flex-col bg-white border-t border-b-2 border-gray-300 overflow-auto ',
          'lg:w-96 lg:flex lg:pt-0 lg:border-r-2 lg:sticky lg:top-0',
          mobileMenuVisible.isOn ? 'flex fixed z-10 pt-10' : 'hidden'
        )}
      >
        <div className="items-center flex-shrink-0 hidden px-6 py-5 border-b-2 border-gray-300 lg:flex">
          <p className="font-semibold text-gray-800 text-md">{label}</p>
        </div>
        <div className="flex-1">{children}</div>
      </nav>
    </>
  );
};

Submenu.Item = SubmenuItem;
