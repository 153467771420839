import React, { useEffect, useState } from 'react';
import { useNonValidatedUser } from '../plugins/auth/Authentication';
import { AlignedContent } from '../components/atoms/layouts/AlignedContent';
import Inbox from '../assets/images/inbox_checked.png';
import { Text } from '../components/atoms/texts/Text';
import { TextSpan } from '../components/atoms/texts/TextSpan';
import { InTextButton } from '../components/atoms/buttons/InTextButton';
import { EmptyScreen } from '../components/molecules/layouts/EmptyScreen';
import { useNavigate } from 'react-router-dom';

export const Verified = () => {
  const user = useNonValidatedUser();
  const [seconds, setSeconds] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.verified() && !user.isLoading) {
      user.forceNewToken().catch((e) => {
        //hopefuly redirect uri in  forceTOken will no trigger
        throw e;
      });
    }
  });

  if (seconds > 0) {
    setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
  }

  useEffect(() => {
    if (seconds === 0) {
      navigate('/');
    }
  }, [seconds, navigate]);

  return (
    <EmptyScreen>
      <AlignedContent direction="column" gap="m" positioning="center">
        <img src={Inbox} alt="inbox" height="122" width="215" />
        <Text size="m" weight="semibold">
          You have been verified
        </Text>
        <p className="text-base font-normal text-center">
          Your email{' '}
          <TextSpan size="base" weight="semibold">
            {user.email}
          </TextSpan>{' '}
          has been successfully verified
          <br />
        </p>
        <AlignedContent direction="column" gap="xs" positioning="center">
          <Text size="base" weight="semibold" align="center">
            What should you do now?
          </Text>
          <Text size="base" weight="normal" align="center">
            In {seconds} seconds you will be redirected to app, where you can prepare your first course. Or don’t wait
            and <InTextButton variant="blue" size="base" label={'start now'} action={() => navigate('/')} />.
          </Text>
        </AlignedContent>
      </AlignedContent>
    </EmptyScreen>
  );
};
