import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonAction } from '../../atoms/buttons/Button';

type EmptyStateProps = {
  icon: IconProp;
  title: React.ReactNode;
  description: React.ReactNode;
  button: ButtonAction;
};

export const EmptyState: React.FC<EmptyStateProps> = ({ icon, title, description, button }) => {
  return (
    <div className="text-center max-w-max">
      <FontAwesomeIcon icon={icon} className="mx-auto h-12 w-12 text-gray-300" />
      <h3 className="mt-2 text-2xl font-semibold text-gray-800">{title}</h3>
      <p className="mt-1 text-sm font-normal text-gray-600">{description}</p>
      <div className="mt-6">
        <Button label={button.label} action={button.action} size="m" variant="primary" />
      </div>
    </div>
  );
};
