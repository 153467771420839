import React from 'react';
import { Register } from '../pages/Register';
import { useMe } from '../contexts/me/MeConsumer';
import { usePrivateNavigation } from '../PrivateRoutes';

export function withOrganizationRegistered<Props>(Component: React.ComponentType<Props>): React.FC<Props> {
  return (props: Props) => {
    const meContext = useMe(false);
    const navigate = usePrivateNavigation();

    const register = () => {
      meContext.refetchMe();
      navigate.toProjectsWithWelcome();
    };

    return meContext.me?.id ? <Component {...props} /> : <Register onRegister={register} />;
  };
}

export default withOrganizationRegistered;
