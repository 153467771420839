import React, { useMemo } from 'react';
import { useFetchScenesQuery } from '../../api/graphql/generated';
import { SectionHeader } from '../../components/organisms/SectionHeader';
import { Button } from '../../components/atoms/buttons/Button';
import { Divider } from '../../components/atoms/divider/Divider';
import { Centered } from '../../components/atoms/layouts/Centered';
import { Loader } from '../../components/molecules/loader/Loader';
import { EmptyState } from '../../components/molecules/emptyState/EmptyState';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import gql from 'graphql-tag';
import { useSwitch } from '../../hooks/useSwitch';
import { UploadNewSceneWizard } from '../../components/organisms/UploadNewSceneWizard';
import { SceneTile, SceneTiles } from '../../components/organisms/SceneTile';
import { MenuContentContainer } from '../../components/atoms/layouts/MenuContentContainer';
import { Banner } from '../../components/atoms/banners/Banner';
import { useMe } from '../../contexts/me/MeConsumer';
import { GetSampleSceneText } from '../../components/organisms/GetSampleSceneText';
import { AlignedContent } from '../../components/atoms/layouts/AlignedContent';

export const Scenes: React.FC = () => {
  const meContext = useMe(true);

  const scenesQuery = useFetchScenesQuery(
    { organizationId: meContext.me.organization.id },
    { refetchOnWindowFocus: false }
  );
  const wizard = useSwitch(false);

  const scenes = useMemo(() => {
    return scenesQuery.data?.scenesByOrganizationId ?? [];
  }, [scenesQuery.data?.scenesByOrganizationId]);

  const beingProcessedScenes = useMemo(
    () => scenes.filter((scene) => !Boolean(scene.hasProcessingFinishedSuccessfully)),
    [scenes]
  );

  return (
    <MenuContentContainer>
      <SectionHeader>
        <SectionHeader.TitleWithActions title={'Scenes'}>
          <Button label={'Upload new'} action={() => wizard.turnOn()} size={'m'} variant={'primary'} />
        </SectionHeader.TitleWithActions>
      </SectionHeader>
      <Divider />
      {scenesQuery.isLoading && (
        <Centered tryGoldenCenter>
          <Loader label="Loading your scenes..." />
        </Centered>
      )}
      {beingProcessedScenes.length > 0 && (
        <Banner
          icon={solid('timer')}
          text="We're processing your scene to ensure optimal viewing experience. This should take 3-5 minutes, depending on the image size."
        />
      )}
      {!scenesQuery.isLoading && scenes?.length !== 0 && (
        <SceneTiles>
          {scenes.map((scene) => (
            <SceneTile
              key={scene.id}
              sceneId={scene.id}
              sceneName={scene.name}
              lastUpdate={scene.lastUpdateTimeUTC}
              isProcessing={scene.hasProcessingFinishedSuccessfully === null}
              thumbnailUrl={scene.thumbnailUrl ?? undefined}
              onDeleteScene={() => {
                scenesQuery.refetch().catch((reason) => {
                  throw reason;
                });
              }}
              onRenameScene={() => {
                scenesQuery.refetch().catch((reason) => {
                  throw reason;
                });
              }}
              onThumbnailSet={() => {
                scenesQuery.refetch().catch((reason) => {
                  throw reason;
                });
              }}
              onProcessingFinished={() => {
                scenesQuery.refetch().catch((reason) => {
                  throw reason;
                });
              }}
            />
          ))}
        </SceneTiles>
      )}
      {!scenesQuery.isLoading && scenes?.length === 0 && (
        <Centered tryGoldenCenter>
          <AlignedContent gap="l" direction="column">
            <EmptyState
              icon={regular('panorama')}
              title="No scenes"
              description="Upload your first scene by clicking on the button below"
              button={{ label: 'Upload scene', action: () => wizard.turnOn() }}
            />
            <Centered>
              <GetSampleSceneText />
            </Centered>
          </AlignedContent>
        </Centered>
      )}
      {wizard.isOn && (
        <UploadNewSceneWizard
          onCreate={() => {
            wizard.turnOff();
            scenesQuery.refetch().catch((reason) => {
              throw reason;
            });
          }}
          onCancel={() => {
            wizard.turnOff();
          }}
        />
      )}
    </MenuContentContainer>
  );
};
gql`
  query fetchScenes($organizationId: Int!) {
    scenesByOrganizationId(organizationId: $organizationId, order: { id: DESC }) {
      id
      name
      lastUpdateTimeUTC
      thumbnailUrl
      hasProcessingFinishedSuccessfully
    }
  }
`;
