import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Moment from 'moment';
import { SettingDescriptionList } from '../../molecules/settings/SettingDescriptionList';
import { Loader } from '../../molecules/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { SubscriptionTier, useFetchSubscriptionForCurrentUserQuery } from '../../../api/graphql/generated';
import gql from 'graphql-tag';
import { useSwitch } from '../../../hooks/useSwitch';
import { AddSeatsModal } from '../../organisms/AddSeatsModal';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { SettingDescriptionListTitle } from '../../molecules/settings/SettingDescriptionListTitle';
import { Table } from '../../atoms/table/Table';
import { TableHead } from '../../atoms/table/TableHead';
import { TableRow } from '../../atoms/table/TableRow';
import { TableHeader } from '../../atoms/table/TableHeader';
import { TableBody } from '../../atoms/table/TableBody';
import { TableData } from '../../atoms/table/TableData';
import { LinkButton } from '../../atoms/buttons/LinkButton';
import { useUser } from '../../../plugins/auth/Authentication';
import { pricingPlanToText } from '../../../utils/pricingPlanToText';

const OWNER_SEAT_COUNT = 1;

function convertAmount(amount: number | null) {
  return amount ? amount / 100 : 0;
}

export function SubscriptionTemplate() {
  const navigate = useNavigate();
  const returnUrl = new URL(`/settings/billing`, window.location.origin);
  const fetchSubscriptionDetails = useFetchSubscriptionForCurrentUserQuery(
    {
      returnUrl: returnUrl.href,
    },
    { refetchOnWindowFocus: false }
  );

  const addSeatsModal = useSwitch(false);
  const { user } = useUser();

  if (fetchSubscriptionDetails.isLoading) {
    return <Loader />;
  }

  if (fetchSubscriptionDetails.error || !fetchSubscriptionDetails.data) {
    return <>Error!</>;
  }

  const organization = fetchSubscriptionDetails.data.me?.organization;
  const subscriptionDetails = organization?.subscriptionDetails;

  const redirectToPortal = () => {
    if (organization?.billingPortal) {
      window.location.href = organization.billingPortal;
    }
  };

  return (
    <>
      <AlignedContent direction="column" gap="s">
        <SettingDescriptionList>
          <SettingDescriptionList.Title>Your subscription</SettingDescriptionList.Title>
          {subscriptionDetails?.pricingPlanCategory && (
            <SettingDescriptionList.Row
              label="Active plan"
              value={pricingPlanToText(subscriptionDetails.pricingPlanCategory)}
              buttons={
                subscriptionDetails.pricingPlanCategory === SubscriptionTier.Free
                  ? [{ label: 'Upgrade', action: () => navigate('/pricing') }]
                  : [
                      { label: 'Change', action: () => navigate('/pricing') },
                      { label: 'Cancel', action: redirectToPortal },
                    ]
              }
            />
          )}
          <SettingDescriptionList.Row
            label="Seats"
            value={subscriptionDetails?.seats ? subscriptionDetails.seats + OWNER_SEAT_COUNT : OWNER_SEAT_COUNT}
            buttons={
              subscriptionDetails?.pricingPlanCategory === SubscriptionTier.Free ||
              subscriptionDetails?.pricingPlanCategory === SubscriptionTier.Pro
                ? undefined
                : [
                    { label: 'Add', action: addSeatsModal.turnOn },
                    {
                      label: 'Remove',
                      action: () => {
                        window.open(
                          `https://share.hsforms.com/1_7oMnh26TWWzQLTTlQ8EKg2wtil?email=${user.email}`,
                          '_blank'
                        );
                      },
                    },
                  ]
            }
          />
          {subscriptionDetails?.nextBillingDate && (
            <SettingDescriptionList.Row
              label="Next billing date"
              value={Moment(subscriptionDetails.nextBillingDate).format('DD-MMM-YYYY')}
            />
          )}
          {subscriptionDetails?.price !== undefined &&
            subscriptionDetails?.price !== null &&
            subscriptionDetails.currency && (
              <SettingDescriptionList.Row
                label="To be billed"
                value={`${convertAmount(subscriptionDetails.price)}  ${subscriptionDetails.currency}`}
              />
            )}
        </SettingDescriptionList>
      </AlignedContent>

      {(subscriptionDetails?.lastFourDigits || subscriptionDetails?.billingAddress || subscriptionDetails?.taxId) && (
        <SettingDescriptionList>
          <SettingDescriptionList.Title>Billing information</SettingDescriptionList.Title>
          {subscriptionDetails?.lastFourDigits && (
            <SettingDescriptionList.Row
              label="Payment details"
              value={
                <>
                  <span className="mr-2">{subscriptionDetails.creditCardBrand}</span>
                  <span className="mr-2">• • • •</span>
                  <span className="mr-2">• • • •</span>
                  <span className="mr-2">• • • •</span>
                  {subscriptionDetails.lastFourDigits}
                </>
              }
              buttons={[
                {
                  label: 'Change',
                  action: redirectToPortal,
                },
              ]}
            />
          )}
          <SettingDescriptionList.Row
            label="Billing Address"
            value={subscriptionDetails.billingAddress}
            buttons={[{ label: 'Change', action: redirectToPortal }]}
          />
          {subscriptionDetails.taxId && (
            <SettingDescriptionList.Row
              label="Tax ID"
              value={subscriptionDetails.taxId}
              buttons={[{ label: 'Change', action: redirectToPortal }]}
            />
          )}
        </SettingDescriptionList>
      )}

      {subscriptionDetails?.invoices && subscriptionDetails.invoices.length > 0 && (
        <>
          <SettingDescriptionListTitle>Transaction history</SettingDescriptionListTitle>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Date</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader>Payment status</TableHeader>
                <TableHeader>
                  <span className="sr-only">Download</span>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionDetails.invoices.map((transaction, index) => (
                <TableRow key={index}>
                  <TableData>{Moment(transaction.created).format('DD-MMM-YYYY')}</TableData>
                  <TableData>
                    {convertAmount(transaction.amountPaid)} {transaction.currency}{' '}
                  </TableData>
                  <TableData>{transaction.status}</TableData>
                  <TableData>
                    <LinkButton
                      variant="blue"
                      label={
                        <span>
                          <FontAwesomeIcon icon={regular('download')} /> Download
                        </span>
                      }
                      href={transaction.invoicePdf}
                    />
                  </TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      {addSeatsModal.isOn && (
        <AddSeatsModal
          onCancel={() => addSeatsModal.turnOff()}
          onConfirm={() => {
            fetchSubscriptionDetails
              .refetch()
              .then(() => addSeatsModal.turnOff())
              .catch((e) => console.log(e));
          }}
        />
      )}
    </>
  );
}

gql`
  query fetchSubscriptionForCurrentUser($returnUrl: URL!) {
    me {
      organization {
        billingPortal(returnUrl: $returnUrl)
        subscriptionDetails {
          pricingPlanCategory
          price
          currency
          nextBillingDate
          seats
          lastFourDigits
          creditCardBrand
          billingAddress
          taxId
          invoices {
            amountPaid
            invoicePdf
            created
            currency
            status
          }
        }
      }
    }
  }
`;
