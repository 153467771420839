import * as React from 'react';
import { Link } from '../atoms/links/Link';
import { Text } from '../atoms/texts/Text';

export const GetSampleSceneText: React.FC = () => (
  <Text size="s">
    Don&apos;t have any scene at hand? Get a sample{' '}
    <Link href="https://panomio.com/about-360" size="s" target="_blank">
      here
    </Link>
    .
  </Text>
);
