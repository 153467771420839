import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type IconButtonProps = {
  icon: IconProp;
  onClick?: () => void;
};

export const IconButton = ({ icon, onClick }: IconButtonProps) => {
  return (
    <div className="cursor-pointer text-white" onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};
