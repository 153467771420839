import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from '@tanstack/react-query';
import { Me } from '../me/MeEntity';
import { useFetch } from '../../api/fetch';

type RegisterNewUserOptions = Omit<UseMutationOptions<Me, unknown, NewUser>, 'mutationFn'>;

type NewUser = {
  email: string;
  firstname: string;
  lastname: string;
  marketingConsent: boolean;
  companyName?: string;
  jobPosition?: string;
  phoneNumber?: string;
};

type RegisterUser = {
  email: string;
  name: {
    firstname: string;
    lastname: string;
  };
  hasGivenMarketingConsent: boolean;
  organizationName?: string;
  jobTitle?: string;
  phone?: string;
};

export const useRegisterNewUser = (options?: RegisterNewUserOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async ({
      firstname,
      lastname,
      email,
      marketingConsent,
      companyName,
      jobPosition,
      phoneNumber,
    }: NewUser) => {
      const responseMe = await post<Me, RegisterUser>('/api/RegisterNewUser', {
        email,
        name: {
          firstname,
          lastname,
        },
        hasGivenMarketingConsent: marketingConsent,
        organizationName: companyName,
        jobTitle: jobPosition,
        phone: phoneNumber,
      });
      return responseMe.data;
    },
  });
};
