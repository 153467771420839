import { useFetch } from '../fetch';

type GraphqlError = {
  message: string;
  locations: Location[];
  extensions: Extensions;
};

type Extensions = {
  code: string;
  exception: Exception;
};

type Exception = {
  stacktrace: string[];
};

type Location = {
  line: number;
  column: number;
};
type QueryData<T> = {
  data: T;
  errors?: GraphqlError[];
};

export function useFetcher<TData, TVariables>(query: string, variables?: TVariables) {
  const { post } = useFetch();

  return async (variables?: TVariables): Promise<TData> => {
    const response = await post<QueryData<TData>, string>(
      '/graphql',
      JSON.stringify({
        query,
        variables,
      })
    );

    if (response.data.errors) {
      const message = response.data.errors ? response.data.errors[0].message : 'GraphQL fetching error';
      throw message;
    }

    return response.data?.data;
  };
}
