import React from 'react';

type ProgressBarProps = {
  completePercentage: number;
};

export const ProgressBar = ({ completePercentage }: ProgressBarProps) => {
  return (
    <div className="flex justify-start w-full h-2 rounded-lg bg-white">
      <div className="h-2 bg-blue-500 rounded-lg" style={{ width: `${completePercentage}%` }} />
    </div>
  );
};
