import { MeProvider } from './contexts/me/MeProvider';
import { ActivePlanContextProvider } from './contexts/activePlan/ActivePlanProvider';
import React from 'react';
import PrivateRoutes from './PrivateRoutes';
import withEmailVerified from './hoc/withEmailVerified';
import { OnboardingProvider } from './contexts/onboarding/OnboardingProvider';

const PrivateApp: React.FC = () => {
  return (
    <MeProvider>
      <ActivePlanContextProvider>
        <OnboardingProvider>
          <PrivateRoutes />
        </OnboardingProvider>
      </ActivePlanContextProvider>
    </MeProvider>
  );
};

export default withEmailVerified(PrivateApp);
