import React from 'react';
import { useUser } from '../plugins/auth/Authentication';
import { useOrganizationRepo } from '../repositories/organization/OrganizationRepo';
import { useOnboarding } from '../contexts/onboarding/OnboardingConsumer';
import { OnboardingTask } from '../api/graphql/generated';
import { LoadableFunction, RegistrationWizzard } from '../components/templates/registration/RegistrationWizzard';

type RegisterProps = {
  onRegister: () => void;
};

export const Register: React.FC<RegisterProps> = ({ onRegister }) => {
  const { user } = useUser();
  const { registerNewUser } = useOrganizationRepo();
  const onboarding = useOnboarding();

  const registerHandler: LoadableFunction = Object.assign(
    (
      firstname: string,
      lastname: string,
      marketingConsent: boolean,
      companyName?: string,
      jobPosition?: string,
      phoneNumber?: string
    ) => {
      registerNewUser
        .execute(firstname, lastname, user.email, marketingConsent, companyName, jobPosition, phoneNumber)
        .then(() => {
          onboarding.updateOnboarding({ taskCompleted: OnboardingTask.EmailVerified });
          onRegister();
        })
        .catch((e) => {
          throw e;
        });
    },
    {
      isLoading: registerNewUser.isLoading,
    }
  );

  return <RegistrationWizzard onRegister={registerHandler} user={user} isInvited={false} />;
};
