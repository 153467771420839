import React from 'react';
import { TextInput, TextInputProps } from '../atoms/inputs/text/TextInput';
import { Button } from '../atoms/buttons/Button';

type ActivateableLinkProps = TextInputProps & {
  activated: boolean;
  loading: boolean;
  onActivate: () => void;
  onDeactivate: () => void;
  onCopy?: () => void;
};

export const ActivateableLink: React.FC<ActivateableLinkProps> = ({
  activated,
  loading,
  onActivate,
  onDeactivate,
  onCopy,
  ...props
}) => {
  return (
    <>
      <TextInput {...props} />
      {!activated && <Button label="Activate link" action={onActivate} loading={loading} size="m" variant="primary" />}
      {activated && onCopy && <Button label="Copy" action={onCopy} size="m" variant="tertiary" />}
      {activated && <Button label="Disable" action={onDeactivate} loading={loading} size="m" variant="text" />}
    </>
  );
};
