import React from 'react';
import { useUser } from '../plugins/auth/Authentication';
import { LoadableFunction, RegistrationWizzard } from '../components/templates/registration/RegistrationWizzard';
import { useEditTeamMateMutation } from '../repositories/team/EditTeamMateMutation';
import { useMe } from '../contexts/me/MeConsumer';
import { useOnboarding } from '../contexts/onboarding/OnboardingConsumer';
import { OnboardingTask } from '../api/graphql/generated';

type AcceptInvitationProps = {
  onAccepted: () => void;
};

export const AcceptInvitation: React.FC<AcceptInvitationProps> = ({ onAccepted }) => {
  const { user } = useUser();
  const contextMe = useMe(false);
  const editUser = useEditTeamMateMutation();
  const onboarding = useOnboarding();

  const acceptInvitationHandler: LoadableFunction = Object.assign(
    (firstname: string, lastname: string, marketingAccepted: boolean) => {
      editUser
        .mutateAsync({
          name: {
            firstName: firstname,
            lastName: lastname,
          },
          invitationAccepted: true,
          marketingConsent: marketingAccepted,
          userId: contextMe.me?.id as number, //should be always contain number (we are after registration)
          role: null, //irrelevant, but forced by avoidOptionals
        })
        .then(() => {
          onboarding.updateOnboarding({ taskCompleted: OnboardingTask.EmailVerified });
          onAccepted();
        })
        .catch((e) => {
          throw e;
        });
    },
    {
      isLoading: editUser.isLoading,
    }
  );

  return (
    <RegistrationWizzard
      onRegister={acceptInvitationHandler}
      user={{ firstname: user.given_name ?? '', lastname: user.family_name ?? '' }}
      isInvited={true}
    />
  );
};
