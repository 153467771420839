import React, { useState } from 'react';
import { Button } from '../../atoms/buttons/Button';
import { Checkbox } from '../../atoms/inputs/checkbox/Checkbox';
import { Label } from '../../atoms/inputs/label/Label';
import { Link } from '../../atoms/links/Link';
import { EmptyScreen } from '../../molecules/layouts/EmptyScreen';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Centered } from '../../atoms/layouts/Centered';
import { ReactComponent as Logo } from '../../../assets/logos/logo_only.svg';
import { Text } from '../../atoms/texts/Text';
import { LabelOnTopLeftBox } from '../../atoms/inputs/layouts/LabelOnTopLeftBox';
import { TextInput } from '../../atoms/inputs/text/TextInput';
import { isStringEmptyOrWhiteSpace } from '../../../utils/isStringEmptyOrWhiteSpace';
import { MAX_INPUT_LENGTH_100 } from '../../../constants';
import { PhoneNumberInput } from '../../atoms/inputs/phoneNumberInput/PhoneNumberInput';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Select } from '../../atoms/inputs/select/Select';
import { SelectObject } from '../../atoms/inputs/select/SelectObject';
import { Option } from '../../atoms/inputs/select/Option';

export type RegisterLoadableFunction = {
  (marketingAccepted: boolean, companyName?: string, jobPosition?: string, phoneNumber?: string): void;
  isLoading: boolean;
};

type RegisterStep2Props = {
  isInvited: boolean;
  onRegister: RegisterLoadableFunction;
};

export const RegisterStep2: React.FC<RegisterStep2Props> = ({ isInvited, onRegister }) => {
  const [companyName, setCompanyName] = useState('');
  const [jobPosition, setJobPosition] = useState<SelectObject>({ id: 0, name: 'Please select...' });

  const [termsChecked, setTermsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string | undefined>(undefined);

  const validatePhoneNumber = () => {
    const isValid = isValidPhoneNumber(phoneNumber);
    const isEmpty = isStringEmptyOrWhiteSpace(phoneNumber);

    if (isEmpty) {
      setPhoneError('Cannot be empty');
      return false;
    }
    if (!isValid) {
      setPhoneError('Invalid phone number');
      return false;
    }

    setPhoneError(undefined);
    return true;
  };

  const registerAction = () => {
    if (!termsChecked || (!isInvited && !validatePhoneNumber())) {
      return;
    }
    onRegister(marketingChecked, companyName, jobPosition.id > 0 ? jobPosition.name : undefined, phoneNumber);
  };

  const jobPositionOptions: SelectObject[] = [
    {
      id: 1,
      name: 'Instructional Designer / eLearning Developer',
    },
    {
      id: 2,
      name: 'Corporate Trainer / HR Professional / Learning Manager',
    },
    {
      id: 3,
      name: 'Subject Matter Expert (SME)',
    },
    {
      id: 4,
      name: 'Professor / Educator',
    },
    {
      id: 5,
      name: 'Instructor',
    },
    {
      id: 6,
      name: 'Other',
    },
  ];

  return (
    <EmptyScreen>
      <AlignedContent direction="column" gap="m">
        <Centered>
          <AlignedContent direction="column" gap="m">
            <Centered>
              <Logo className="w-20 h-20" />
            </Centered>
            <Centered>
              <Text size="l" weight="semibold">
                Last step!
              </Text>
            </Centered>
            <Centered>
              <Text size="s">
                {isInvited ? 'Please accept our T&C before proceeding.' : 'Can you tell us something about yourself?'}
              </Text>
            </Centered>
          </AlignedContent>
        </Centered>
        <AlignedContent direction="column" gap="m">
          <AlignedContent direction="column" gap="m">
            {!isInvited && (
              <>
                <LabelOnTopLeftBox>
                  <Label size="s">Phone number</Label>
                  <PhoneNumberInput
                    phone={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                      setPhoneError(undefined);
                    }}
                    isValid={!Boolean(phoneError)}
                  />
                  {Boolean(phoneError) && (
                    <Text size="s" color="error">
                      {phoneError}
                    </Text>
                  )}
                </LabelOnTopLeftBox>
                <LabelOnTopLeftBox>
                  <Label size="s">Company name</Label>
                  <TextInput
                    size="full"
                    type="text"
                    defaultValue={companyName}
                    onInput={(event) => {
                      setCompanyName(event.currentTarget.value);
                    }}
                    maxLength={MAX_INPUT_LENGTH_100}
                  />
                </LabelOnTopLeftBox>
                <LabelOnTopLeftBox>
                  <Label size="s">Role in the company</Label>
                  <Select
                    size="full"
                    selected={jobPosition}
                    onChange={(id) => {
                      const jobPosition = jobPositionOptions.find((x) => x.id === id);
                      if (jobPosition) {
                        setJobPosition(jobPosition);
                      }
                    }}
                  >
                    {jobPositionOptions.map((jobPosition) => (
                      <Option key={jobPosition.id} value={jobPosition} />
                    ))}
                  </Select>
                </LabelOnTopLeftBox>
              </>
            )}
            <Checkbox
              label={
                <Label size="s">
                  I accept{' '}
                  <Link size="s" href="https://panomio.com/terms-and-conditions" target={'_blank'}>
                    Terms & Conditions
                  </Link>
                </Label>
              }
              onInput={(checked) => setTermsChecked(checked)}
            />
            <Checkbox
              label={<Label size="s">Inform me about latest updates, tips & tricks and more</Label>}
              onInput={(checked) => setMarketingChecked(checked)}
            />
          </AlignedContent>
          <Button
            size="full"
            label="Continue to Panomio"
            action={registerAction}
            loading={onRegister.isLoading}
            disabled={!termsChecked}
          />
        </AlignedContent>
      </AlignedContent>
    </EmptyScreen>
  );
};
