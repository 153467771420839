import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Text } from '../../atoms/texts/Text';

type DocumentLinkProps = {
  icon: IconProp;
  href: string;
  title: string;
  description: string;
};

export const DocumentLink = ({ icon, href, title, description }: DocumentLinkProps) => {
  return (
    <div
      className="flex flex-row w-full gap-3 p-3 border border-solid rounded border-grey-300 hover:bg-grey-200 hover:cursor-pointer"
      onClick={() => window?.open(href, '_blank')?.focus()}
    >
      <div className="flex w-8 h-8 text-blue-500 rounded shrink-0 bg-grey-200">
        <FontAwesomeIcon icon={icon} className="m-auto" />
      </div>
      <AlignedContent direction="column" gap="xxs">
        <Text color="blue" size="base" weight="semibold">
          {title}
        </Text>
        <Text color="grey" size="s" weight="normal">
          {description}
        </Text>
      </AlignedContent>
    </div>
  );
};
