import React from 'react';

type MenuGroupProps = React.PropsWithChildren & {
  label: string;
};
export const MenuGroup: React.FC<MenuGroupProps> = ({ label, children }) => {
  return (
    <div className="flex flex-col">
      <div className={'text-grey-500 text-left text-xs font-semibold mb-2'}>{label}</div>
      <nav className="flex flex-col flex-1 space-y-1">{children}</nav>
    </div>
  );
};
