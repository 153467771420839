import React from 'react';
import { useOnboarding } from '../../../contexts/onboarding/OnboardingConsumer';
import { OnboardingTask } from '../../../api/graphql/generated';
import { ProgressBar } from '../../atoms/progress/ProgressBar';
import { Text } from '../../atoms/texts/Text';
import { Checklist } from '../../atoms/checklist/Checklist';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Button } from '../../atoms/buttons/Button';
import { useSwitch } from '../../../hooks/useSwitch';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconButton } from '../../atoms/buttons/IconButton';
import { DropDown } from '../../molecules/dropDown/DropDown';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WelcomeModal } from '../WelcomeModal';
import { PromptModal } from '../PromptModal';
import { LearnHowLink } from '../../molecules/link/LearnHowLink';

const ONBOARDING_STEP_ORDER = [
  OnboardingTask.EmailVerified,
  OnboardingTask.InspiredByTemplates,
  OnboardingTask.SceneUploaded,
  OnboardingTask.BlankCourseCreated,
  OnboardingTask.LinkToCourseShared,
];

type OnboardingStepTranslations = {
  title: string;
  description: string;
  userAction: JSX.Element;
};

const ONBOARDING_STEP_TEXTS: Record<OnboardingTask, OnboardingStepTranslations> = {
  BLANK_COURSE_CREATED: {
    title: 'Create blank course',
    description: 'Create a brand new course from scratch that is uniquely yours.',
    userAction: <LearnHowLink href="https://panomio.com/resources/docs/create-course" />,
  },
  EMAIL_VERIFIED: {
    title: 'Verify email account',
    description: '',
    userAction: <></>,
  },
  INSPIRED_BY_TEMPLATES: {
    title: 'Get inspired by one of our templates',
    description: 'Create new course from a template and gather some inspiration.',
    userAction: <LearnHowLink href="https://panomio.com/resources/docs/create-course" />,
  },
  LINK_TO_COURSE_SHARED: {
    title: 'Share a link to course',
    description: 'Share your course to the world!',
    userAction: <LearnHowLink href="https://panomio.com/resources/docs/sharing-link" />,
  },
  SCENE_UPLOADED: {
    title: 'Upload your own scene',
    description: 'Scenes are essential for creating truly immersive courses.',
    userAction: <LearnHowLink href="https://panomio.com/resources/docs/scenes-introduction" />,
  },
};

type OnboardingProgressCardProps = {
  onOnboardingFinished: () => void;
};

export const OnboardingProgressCard = ({ onOnboardingFinished }: OnboardingProgressCardProps) => {
  const onboarding = useOnboarding();

  const checklistSwitch = useSwitch(true);
  const welcomeModalSwitch = useSwitch(false);
  const terminateOnboardingModalSwitch = useSwitch(false);

  const percent = (onboarding.onboardingStatus.completedTasks.length / Object.keys(OnboardingTask).length) * 100;

  if (onboarding.onboardingStatus.isFinished) {
    return <></>;
  }

  return (
    <>
      <div className="fixed bottom-4 left-4 right-4 sm:bottom-7 sm:right-7 bg-grey-900 border rounded-md border-grey-700 border-solid py-2.5 px-6 sm:left-auto sm:max-w-100">
        <AlignedContent direction="column">
          <AlignedContent direction="row" placing="space-between">
            <Text color="white" size="m" weight="bold">
              First steps
            </Text>
            <AlignedContent direction="row" gap="m" positioning="center">
              <DropDown
                menuAlignment="right"
                menuWidth="w-52"
                button={
                  <Menu.Button>
                    <span className="sr-only">Open onboarding options</span>
                    <FontAwesomeIcon className="w-4 h-4 text-white" icon={solid('ellipsis')} />
                  </Menu.Button>
                }
              >
                <DropDown.Option
                  label="Open welcome message"
                  action={() => {
                    welcomeModalSwitch.turnOn();
                  }}
                />
                <DropDown.Option
                  label="Finish without completing"
                  variant="danger"
                  action={() => terminateOnboardingModalSwitch.turnOn()}
                />
              </DropDown>
              <IconButton
                icon={checklistSwitch.isOn ? solid('chevron-down') : solid('chevron-up')}
                onClick={() => checklistSwitch.toggle()}
              />
            </AlignedContent>
          </AlignedContent>
          <AlignedContent direction="column" gap="xs">
            <ProgressBar completePercentage={percent} />
            <Text color="blue-400" size="s" weight="semibold">
              {percentageToText(percent)}
            </Text>
          </AlignedContent>

          <div className={checklistSwitch.isOn ? '' : 'h-0 overflow-hidden'}>
            <Checklist>
              {ONBOARDING_STEP_ORDER.map((step) => {
                if (onboarding.onboardingStatus.completedTasks.includes(step)) {
                  return (
                    <Checklist.Item key={step} variant="completed">
                      <Text color="default" weight="semibold" size="base">
                        {ONBOARDING_STEP_TEXTS[step].title}
                      </Text>
                    </Checklist.Item>
                  );
                }

                const focused = shouldBeFocused(onboarding.onboardingStatus.completedTasks, step);
                return (
                  <Checklist.Item key={step} variant={focused ? 'focused' : 'pending'}>
                    <Text color="default" weight="semibold" size="base">
                      {ONBOARDING_STEP_TEXTS[step].title}
                    </Text>
                    {focused && (
                      <>
                        <Text color="default" weight="normal" size="s">
                          {ONBOARDING_STEP_TEXTS[step].description}
                        </Text>
                        {ONBOARDING_STEP_TEXTS[step].userAction}
                      </>
                    )}
                  </Checklist.Item>
                );
              })}
            </Checklist>
          </div>

          {onboarding.onboardingStatus.completedTasks.length === ONBOARDING_STEP_ORDER.length && (
            <div className="flex flex-col pb-3 ">
              <Button
                label="Finish"
                variant="primary"
                action={() => {
                  onOnboardingFinished();
                  onboarding.updateOnboarding({ isFinished: true });
                }}
              />
            </div>
          )}
        </AlignedContent>
      </div>
      {welcomeModalSwitch.isOn && (
        <WelcomeModal
          onClose={() => {
            welcomeModalSwitch.turnOff();
          }}
        />
      )}

      {terminateOnboardingModalSwitch.isOn && (
        <PromptModal
          variant="primary"
          title="Are you sure?"
          description="Users that finish the onboarding are more likely to create successful courses with Panomio."
          buttonText="Finish anyway"
          executing={false}
          onApprove={() => onboarding.updateOnboarding({ isFinished: true })}
          onCancel={() => terminateOnboardingModalSwitch.turnOff()}
        />
      )}
    </>
  );
};

const percentageToText = (percent: number): string => {
  if (percent === 100) {
    return '100% Complete - Congratulations!';
  }

  return `${Math.round(percent)}% Complete - Nice work!`;
};

const shouldBeFocused = (completed: OnboardingTask[], step: OnboardingTask) => {
  const firstNotCompleted = ONBOARDING_STEP_ORDER.reduce<OnboardingTask | undefined>(
    (firstNotCompleted, stepFormOrder) => {
      if (firstNotCompleted) {
        return firstNotCompleted;
      }

      if (completed.includes(stepFormOrder)) {
        return firstNotCompleted;
      }

      return stepFormOrder;
    },
    undefined
  );

  return firstNotCompleted === step;
};
