import React from 'react';

import { Image, ImageProps } from '../atoms/image/Image';
import Logo from '../../assets/logos/logo.svg';

type LogoPreviewProps = {
  logo?: ImageProps['imageSrc'];
};

export const LogoPreview = ({ logo }: LogoPreviewProps) => {
  return (
    <div className="w-44 h-28 flex justify-center rounded overflow-hidden p-2 bg-grey-900 ">
      <Image imageSrc={logo ?? Logo} grow="fit" />
    </div>
  );
};
