import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IEventTelemetry } from '@microsoft/applicationinsights-web/dist/applicationinsights-web.rollup';
import { LOG_CONFIG } from './log.config';

type Properties = IEventTelemetry['properties'];

export const Logger = class {
  private static appInsights: ApplicationInsights | null;

  public static initialize() {
    const connectionString = LOG_CONFIG.LOG_CONNECTION_STRING;

    Logger.appInsights = connectionString
      ? new ApplicationInsights({
          config: {
            connectionString,
          },
        })
      : null;
    Logger.appInsights?.loadAppInsights();
  }

  public static trackException(exception: Error) {
    Logger.appInsights?.trackException({ exception });
  }

  public static logEvent(name: string, properties?: Properties) {
    Logger.appInsights?.trackEvent({ name, properties });
  }
};

Logger.initialize();
