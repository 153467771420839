import React from 'react';
import { SectionHeader } from '../../../components/organisms/SectionHeader';
import { Divider } from '../../../components/atoms/divider/Divider';
import { LabelOnLeftBox } from '../../../components/atoms/inputs/layouts/LabelOnLeftBox';
import { Label } from '../../../components/atoms/inputs/label/Label';
import { ItemsFromEndLayout } from '../../../components/atoms/inputs/layouts/PlaceItemFromEnd';
import { ActivateableLink } from '../../../components/organisms/ActivateableLink';
import { MenuContentContainer } from '../../../components/atoms/layouts/MenuContentContainer';
import gql from 'graphql-tag';
import {
  OnboardingTask,
  useProjectPublicSharingSettingsQuery,
  useSetProjectPublicAvailabilityMutation,
} from '../../../api/graphql/generated';
import { Loader } from '../../../components/molecules/loader/Loader';
import { ProjectPageProps, withProjectId } from '../../../hoc/withProjectId';
import { useOnboarding } from '../../../contexts/onboarding/OnboardingConsumer';

const SHARE_LINK_ID = 'share_link';

const Sharing: React.FC<ProjectPageProps> = ({ projectId }) => {
  const projectQuery = useProjectPublicSharingSettingsQuery({ projectId });
  const shareProjectQuery = useSetProjectPublicAvailabilityMutation();
  const url = new URL(
    `/public/${projectQuery.data?.projectPublicSharingSettings?.publicIdentifier ?? ''}`,
    window.location.origin
  );

  const onboarding = useOnboarding();

  const activationAction = (activate: boolean) => {
    shareProjectQuery
      .mutateAsync(
        { projectId, isActive: activate },
        {
          onSuccess() {
            onboarding.updateOnboarding({ taskCompleted: OnboardingTask.LinkToCourseShared });
            // Not really effective as we have data from mutation, why refetch?!
            projectQuery.refetch().catch((reason) => {
              throw reason;
            });
          },
        }
      )
      .catch((reason) => {
        throw reason;
      });
  };

  return (
    <MenuContentContainer>
      <SectionHeader>
        <SectionHeader.Title>Share link</SectionHeader.Title>
        <SectionHeader.Subtitle>
          Create a shareable link with access to the course. Perfect for internal reviews and feedback from
          stakeholders.
        </SectionHeader.Subtitle>
      </SectionHeader>
      <Divider />
      <LabelOnLeftBox label={<Label htmlFor={SHARE_LINK_ID}>Link</Label>}>
        <ItemsFromEndLayout gap="m">
          {projectQuery.isLoading && <Loader label="loading link status" />}
          {!projectQuery.isLoading && (
            <ActivateableLink
              readOnly
              disabled={!projectQuery.data?.projectPublicSharingSettings}
              size="l"
              type="url"
              id={SHARE_LINK_ID}
              loading={projectQuery.isLoading || shareProjectQuery.isLoading}
              placeholder="Click button to generate link"
              defaultValue={projectQuery.data?.projectPublicSharingSettings ? url.href : ''}
              onCopy={() => {
                if (projectQuery.data?.projectPublicSharingSettings?.publicIdentifier !== null) {
                  navigator.clipboard.writeText(url.href).catch(() => {
                    console.log('Failed to copy URL');
                  });
                }
              }}
              activated={!!projectQuery.data?.projectPublicSharingSettings}
              onActivate={() => activationAction(true)}
              onDeactivate={() => activationAction(false)}
            />
          )}
        </ItemsFromEndLayout>
      </LabelOnLeftBox>
      <Divider />
    </MenuContentContainer>
  );
};

export default withProjectId(Sharing);

gql`
  query projectPublicSharingSettings($projectId: Int!) {
    projectPublicSharingSettings(id: $projectId) {
      publicIdentifier
    }
  }
`;
