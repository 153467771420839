import React, { useState } from 'react';
import { Modal } from '../atoms/layouts/Modal';
import { Button } from '../atoms/buttons/Button';
import { Text } from '../atoms/texts/Text';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Label } from '../atoms/inputs/label/Label';
import { TextInput } from '../atoms/inputs/text/TextInput';
import { LabelOnTopLeftBox } from '../atoms/inputs/layouts/LabelOnTopLeftBox';
import { MAX_INPUT_LENGTH_100 } from '../../constants';
import { isStringEmptyOrWhiteSpace } from '../../utils/isStringEmptyOrWhiteSpace';

type RenameModalConfig = {
  title: React.ReactNode;
  nameInputLabel: React.ReactNode;
};

export type RenameFunction = {
  (newName: string): void;
  isLoading: boolean;
};

type RenameModalProps = {
  config: RenameModalConfig;
  oldName: string | null;
  onRename: RenameFunction;
  onCancel: () => void;
};

export const RenameModal: React.FC<RenameModalProps> = ({ config, oldName, onCancel, onRename }) => {
  const [newName, setNewName] = useState(oldName ?? '');

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            {config.title}
          </Text>
          <LabelOnTopLeftBox>
            <Label htmlFor="sceneName" size="s">
              {config.nameInputLabel}
            </Label>
            <TextInput
              id="sceneName"
              size="full"
              type="text"
              placeholder="File name"
              defaultValue={newName}
              onInput={(e) => setNewName(e.currentTarget.value)}
              maxLength={MAX_INPUT_LENGTH_100}
            />
          </LabelOnTopLeftBox>
        </AlignedContent>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button
            label="Rename"
            action={() => onRename(newName)}
            loading={onRename.isLoading}
            disabled={isStringEmptyOrWhiteSpace(newName)}
          />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
