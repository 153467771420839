import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SubscriptionTier } from '../../../api/graphql/generated';
import { Centered } from '../../atoms/layouts/Centered';
import { FullScreenSize } from '../../atoms/layouts/FullScreenSize';
import { Success } from '../../molecules/successMessage/SuccessMessage';

interface SubscriptionActtiveTemplateProps {
  subscriptionType: SubscriptionTier;
}

export function SubscriptionActiveTemplate({ subscriptionType }: SubscriptionActtiveTemplateProps) {
  return (
    <FullScreenSize>
      <Centered tryGoldenCenter>
        <Success
          title="Thank you for upgrading!"
          description={`You are now on the ${subscriptionType} plan.`}
          subDescription={`We are setting up your account, you will be redirected shortly.`}
          icon={regular('party-horn')}
        />
      </Centered>
    </FullScreenSize>
  );
}
