import { UseMutationOptions } from '@tanstack/react-query';
import { EditMemberMutation, EditMemberMutationVariables, useEditMemberMutation } from '../../api/graphql/generated';
import gql from 'graphql-tag';

type EditTeamMateOptions = UseMutationOptions<EditMemberMutation, unknown, EditMemberMutationVariables>;

export const useEditTeamMateMutation = (options?: EditTeamMateOptions) => {
  return useEditMemberMutation(options);
};

gql`
  mutation editMember(
    $userId: Int!
    $name: NameInput
    $role: Role
    $invitationAccepted: Boolean
    $marketingConsent: Boolean
  ) {
    EditTeamMember(
      input: {
        userId: $userId
        name: $name
        newRole: $role
        hasAcceptedInvite: $invitationAccepted
        hasGivenMarketingConsent: $marketingConsent
      }
    ) {
      int
    }
  }
`;
