import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

export enum SceneType {
  FlatImage = 'FlatImage',
  MultiResPanorama = 'MultiResPanorama',
}

type NewScene = {
  name: string;
  file: File;
  sceneType: SceneType;
};

type UploadSceneOptions = Omit<UseMutationOptions<void, unknown, NewScene>, 'mutationFn'>;

export const useUploadSceneMutation = (options?: UploadSceneOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async (scene: NewScene) => {
      const formData = new FormData();
      formData.append('Name', scene.name);
      formData.append('FormFile', scene.file);
      formData.append('ProcessingSceneType', scene.sceneType);

      const responseMe = await post<void, FormData>('/api/upload-panorama', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return responseMe.data;
    },
  });
};
