import { useContext } from 'react';
import { Me, MeContext } from './MeContext';
import { InvitationState, Role } from '../../api/graphql/generated';

type Organization = {
  id: number;
  name: string;
};

type PanomioMe = {
  id: number;
  email: string;
  name: {
    firstname: string;
    lastname: string;
  };
  role: Role;
  inviteState: InvitationState;
  organization: Organization;
};

type ConditionalMe<T extends true | false> = T extends true ? PanomioMe : Me['me'];

type AppMe<T extends true | false> = Omit<Me, 'me'> & {
  me: ConditionalMe<T>;
};

export const useMe = <T extends true | false>(withChecks: T): AppMe<T> => {
  const contextMe = useContext(MeContext);

  return {
    me: withChecks ? (hydrateMe(contextMe.me) as ConditionalMe<T>) : (contextMe.me as ConditionalMe<T>),
    isLoaded: contextMe.isLoaded,
    refetchMe: contextMe.refetchMe,
  };
};

function hydrateMe(me: Me['me']): PanomioMe {
  if (!me) {
    throw Error('cannot hydrate me');
  }

  if (!me.id) {
    throw Error('me not registered');
  }

  if (!me.organization) {
    throw Error('me not registered');
  }

  if (!me.name?.firstName || !me.name.lastName) {
    throw Error('me not properly registered');
  }

  return {
    name: { firstname: me.name?.firstName, lastname: me.name?.lastName },
    email: me.email,
    role: me.role,
    id: me.id,
    inviteState: me.inviteState,
    organization: {
      id: me.organization.id,
      name: me.organization.name,
    },
  };
}
