import React from 'react';
import { FullScreenSize } from '../../components/atoms/layouts/FullScreenSize';
import { Centered } from '../../components/atoms/layouts/Centered';
import { Loader } from '../../components/molecules/loader/Loader';
import { useMe } from './MeConsumer';

export function withMeLoaded<Props>(Component: React.ComponentType<Props>): React.FC<Props> {
  return (props: Props) => {
    const { isLoaded } = useMe(false);

    if (!isLoaded) {
      return (
        <FullScreenSize>
          <Centered tryGoldenCenter>
            <Loader label="Loading your details..." />
          </Centered>
        </FullScreenSize>
      );
    }

    return <Component {...props} />;
  };
}
