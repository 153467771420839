import React from 'react';
import { TextSize, TextSizeToClassname } from '../texts/Text';
import classnames from 'classnames';

type LinkProps = React.PropsWithChildren & {
  size?: TextSize;
  href: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>['href'];
  target?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>['target'];
};

export const Link: React.FC<LinkProps> = ({ size = 'base', children, ...props }) => {
  return (
    <a {...props} className={classnames(TextSizeToClassname[size], 'text-blue-500 hover:text-blue-600')}>
      {children}
    </a>
  );
};
