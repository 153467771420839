import React from 'react';
import { useMe } from '../contexts/me/MeConsumer';
import { InvitationState } from '../api/graphql/generated';
import { AcceptInvitation } from '../pages/AcceptInvitation';
import { usePrivateNavigation } from '../PrivateRoutes';

export function withAcceptedInvitation<Props>(Component: React.ComponentType<Props>): React.FC<Props> {
  return (props: Props) => {
    const meContext = useMe(false);
    const navigate = usePrivateNavigation();

    const acceptInvitation = () => {
      meContext.refetchMe();
      navigate.toProjectsWithWelcome();
    };

    return meContext.me?.name && meContext.me.inviteState === InvitationState.Accepted ? (
      <Component {...props} />
    ) : (
      <AcceptInvitation onAccepted={acceptInvitation} />
    );
  };
}

export default withAcceptedInvitation;
