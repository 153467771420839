import React from 'react';
import { H3 } from '../atoms/texts/H3';
import { H4 } from '../atoms/texts/H4';
import { H3WithActionsContainer } from '../molecules/layouts/H3WithActionsContainer';
import { TabBarContainer } from '../atoms/layouts/TabBarContainer';
import { TabBar } from '../atoms/navigation/TabBar';
import { Tab } from '../atoms/navigation/Tab';
import { SectionHeaderContainer } from '../atoms/layouts/SectionHeaderContainer';

type Extensions = {
  Title: typeof H3;
  Subtitle: typeof H4;
  TitleWithActions: typeof H3WithActionsContainer;
  Tabs: typeof TabBarContainer;
  TabBar: typeof TabBar;
  Tab: typeof Tab;
};
export const SectionHeader: React.FC<React.PropsWithChildren> & Extensions = ({ children }) => {
  return <SectionHeaderContainer>{children}</SectionHeaderContainer>;
};
SectionHeader.Title = H3;
SectionHeader.Subtitle = H4;
SectionHeader.TitleWithActions = H3WithActionsContainer;
SectionHeader.Tabs = TabBarContainer;
SectionHeader.TabBar = TabBar;
SectionHeader.Tab = Tab;
