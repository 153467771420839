import React, { useState } from 'react';
import { DeleteSceneFailedModal } from './DeleteSceneFailedModal';
import { DeleteSceneModal } from './DeleteSceneModal';

type DeleteSceneWizardProps = {
  sceneId: number;
  sceneName: string;
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteSceneWizard = ({ sceneId, sceneName, onDelete, onCancel }: DeleteSceneWizardProps) => {
  const [linkedTourIds, setLinkedTourIds] = useState<number[]>([]);

  return (
    <>
      {linkedTourIds.length === 0 ? (
        <DeleteSceneModal
          sceneId={sceneId}
          sceneName={sceneName}
          onDelete={(deleteResult) => {
            if (deleteResult.isSuccess) {
              onDelete();
              return;
            }

            setLinkedTourIds(deleteResult.blockedByTourIds);
          }}
          onCancel={onCancel}
        />
      ) : (
        <DeleteSceneFailedModal sceneName={sceneName} blockingTourIds={linkedTourIds} onUnderstand={onCancel} />
      )}
    </>
  );
};
