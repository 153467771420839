import React, { useState } from 'react';
import { Button } from '../../atoms/buttons/Button';
import { Label } from '../../atoms/inputs/label/Label';
import { EmptyScreen } from '../../molecules/layouts/EmptyScreen';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Centered } from '../../atoms/layouts/Centered';
import { ReactComponent as Logo } from '../../../assets/logos/logo_only.svg';
import { Text } from '../../atoms/texts/Text';
import { LabelOnTopLeftBox } from '../../atoms/inputs/layouts/LabelOnTopLeftBox';
import { TextInput } from '../../atoms/inputs/text/TextInput';
import { MAX_INPUT_LENGTH_100 } from '../../../constants';

type RegisterUser = {
  firstname: string;
  lastname: string;
};

type RegisterStep1Props = {
  user?: RegisterUser;
  onContinue: (firstName: string, lastName: string) => void;
};

export const RegisterStep1: React.FC<RegisterStep1Props> = ({ user, onContinue }) => {
  const [firstname, setFirstname] = useState(user?.firstname ?? '');
  const [lastname, setLastname] = useState(user?.lastname ?? '');

  const continueAction = () => {
    onContinue(firstname, lastname);
  };

  return (
    <EmptyScreen>
      <AlignedContent direction="column" gap="m">
        <Centered>
          <AlignedContent direction="column" gap="m">
            <Centered>
              <Logo className="w-20 h-20" />
            </Centered>
            <Centered>
              <Text size="l" weight="semibold">
                Welcome!
              </Text>
            </Centered>
            <Centered>
              <Text size="s">What should we call you?</Text>
            </Centered>
          </AlignedContent>
        </Centered>
        <AlignedContent direction="column" gap="m">
          <AlignedContent direction="column" gap="m">
            <LabelOnTopLeftBox>
              <Label size="s">First Name</Label>
              <TextInput
                size="full"
                type="text"
                defaultValue={firstname}
                onInput={(event) => {
                  setFirstname(event.currentTarget.value);
                }}
                maxLength={MAX_INPUT_LENGTH_100}
              />
            </LabelOnTopLeftBox>
            <LabelOnTopLeftBox>
              <Label size="s">Last Name</Label>
              <TextInput
                size="full"
                type="text"
                defaultValue={lastname}
                onInput={(event) => {
                  setLastname(event.currentTarget.value);
                }}
                maxLength={MAX_INPUT_LENGTH_100}
              />
            </LabelOnTopLeftBox>
          </AlignedContent>
          <Button
            size="full"
            label="Continue"
            action={continueAction}
            disabled={!(Boolean(lastname) && Boolean(firstname))}
          />
        </AlignedContent>
      </AlignedContent>
    </EmptyScreen>
  );
};
