import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from '../atoms/layouts/Modal';
import { Button } from '../atoms/buttons/Button';
import { Text } from '../atoms/texts/Text';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { TextInput } from '../atoms/inputs/text/TextInput';
import gql from 'graphql-tag';
import { useFetchProratedInvoiceQuery } from '../../api/graphql/generated';
import { useModifySeatsMutation } from '../../repositories/subscription/ModifySeatsMutation';
import { LabelOnLeftBox } from '../atoms/inputs/layouts/LabelOnLeftBox';
import { Loader } from '../molecules/loader/Loader';
import moment from 'moment';
import { Centered } from '../atoms/layouts/Centered';
import { LoaderBoxCover } from '../molecules/loader/LoaderBoxCover';
import { useActivePlan } from '../../contexts/activePlan/ActivePlanCosumer';

const REGEXP_WHOLE_DIGITS = /^[1-9][0-9]*$/;

type AddSeatsModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const AddSeatsModal: React.FC<AddSeatsModalProps> = ({ onCancel, onConfirm }) => {
  const modifySeatsQuery = useModifySeatsMutation();
  const { refetchActivePlan } = useActivePlan();

  const refetchPlanAndConfirmDelay = useCallback(() => {
    const timer = setTimeout(() => {
      refetchActivePlan();
      onConfirm();
    }, 2000);
    return () => clearTimeout(timer);
  }, [onConfirm, refetchActivePlan]);

  const modifySeats = useCallback(
    (nrOfSeats: number) => {
      modifySeatsQuery.mutate(nrOfSeats, {
        onSuccess: refetchPlanAndConfirmDelay,
      });
    },
    [refetchPlanAndConfirmDelay, modifySeatsQuery]
  );

  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchProratedInvoice = useFetchProratedInvoiceQuery(
    {
      nrOfSeats: numberOfSeats,
    },
    { refetchOnWindowFocus: false }
  );

  const proratedDetails = fetchProratedInvoice.data?.me?.organization?.proratedSeatPrice;

  const proratedCharge = useMemo(() => {
    const proratedPrice = proratedDetails?.prorateCharge;
    return proratedPrice ? proratedPrice / 100 : 0;
  }, [proratedDetails?.prorateCharge]);

  const priceIncrease = useMemo(() => {
    const priceIncrease = proratedDetails?.priceIncrease;
    return priceIncrease ? priceIncrease / 100 : 0;
  }, [proratedDetails?.priceIncrease]);

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          if (!modifySeatsQuery.isLoading) {
            onCancel();
          }
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            Add seats
          </Text>
          {!modifySeatsQuery.isSuccess && (
            <LabelOnLeftBox label="Seats">
              <TextInput
                id="nrOfseats"
                size="s"
                type="number"
                placeholder="seats"
                defaultValue={numberOfSeats}
                onInput={(e) => {
                  if (e.currentTarget.value.match(REGEXP_WHOLE_DIGITS)) {
                    setNumberOfSeats(Number(e.currentTarget.value));
                    setErrorMessage(undefined);
                  } else {
                    setErrorMessage('Invalid input. This entry can only contain numbers above 0.');
                  }
                }}
                maxLength={3}
                errorMessage={errorMessage}
              />
            </LabelOnLeftBox>
          )}
          {fetchProratedInvoice.isLoading && !fetchProratedInvoice.data && <Loader />}
          {proratedDetails && !modifySeatsQuery.isSuccess && (
            <>
              <Text size="s" align="center">
                Adding <strong>{numberOfSeats}</strong> {numberOfSeats > 1 ? 'seats' : 'seat'} will add{' '}
                <strong>
                  {proratedDetails.currency.toUpperCase()} {priceIncrease}
                </strong>{' '}
                to your subscription bill starting{' '}
                <strong>{moment(proratedDetails.nextBillingDate).format('DD-MMM-YYYY')}</strong>. Panomio will charge
                you for this seat even if you haven&apos;t assigned a user to it.
              </Text>
              <Text size="s" align="center">
                An estimated prorated charge of{' '}
                <strong>
                  {proratedDetails.currency.toUpperCase()} {proratedCharge}
                </strong>{' '}
                will be applied today to your <strong>{proratedDetails.creditCardBrand}</strong> ending in{' '}
                <strong>{proratedDetails.lastFourDigits}</strong>.
              </Text>
            </>
          )}
          {modifySeatsQuery.isLoading && (
            <LoaderBoxCover>
              <Centered tryGoldenCenter>
                <Loader label="Purchasing..." />
              </Centered>
            </LoaderBoxCover>
          )}
          {modifySeatsQuery.isSuccess && (
            <>
              <Text size="s" align="center">
                Thank you for your purchase!
              </Text>
              <Text size="s" align="center">
                {numberOfSeats} {numberOfSeats > 1 ? 'seats were' : 'seat was'} added to your subscription.
              </Text>
            </>
          )}
        </AlignedContent>
        {!modifySeatsQuery.isSuccess && (
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button
              label="Add seats"
              action={() => modifySeats(numberOfSeats)}
              loading={fetchProratedInvoice.isLoading}
              disabled={Boolean(errorMessage)}
            />
          </AlignedContent>
        )}
      </AlignedContent>
    </Modal>
  );
};

gql`
  query fetchProratedInvoice($nrOfSeats: Int!) {
    me {
      organization {
        proratedSeatPrice(nrOfSeats: $nrOfSeats) {
          priceIncrease
          prorateCharge
          nextBillingDate
          currency
          lastFourDigits
          creditCardBrand
        }
      }
    }
  }
`;
