import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectObject } from './SelectObject';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InputSize, SizeToClassname } from '../text/TextInput';
import classNames from 'classnames';

type SelectProps<T extends SelectObject> = React.PropsWithChildren & {
  label?: string;
  size?: InputSize;
  name?: string;
  selected: T;
  onChange: (newlySelected: SelectObject['id']) => void;
};

export const Select = <T extends SelectObject>({
  label,
  size = 'l',
  name,
  selected,
  onChange,
  children,
}: SelectProps<T>): JSX.Element => {
  return (
    <Listbox name={name} value={selected.id} onChange={onChange}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700 text-left">{label}</Listbox.Label>
          )}
          <div className={classNames('relative font-semibold', SizeToClassname[size])}>
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <span className="block truncate">{selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FontAwesomeIcon aria-hidden="true" icon={regular('chevron-down')} size={'sm'} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
