import { useMutation } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type Checkout = {
  lookupKey: string;
  successUrl: string;
  cancelUrl: string;
};
export const useGenerateCheckoutSession = () => {
  const { post } = useFetch();

  return useMutation({
    mutationFn: async ({ lookupKey, successUrl, cancelUrl }: Checkout) => {
      const response = await post<string, Checkout>('/api/CreateCheckoutSession', {
        lookupKey,
        successUrl,
        cancelUrl,
      });
      return response.data;
    },
  });
};
