import React from 'react';
import { Listbox } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { SelectObject } from './SelectObject';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

type OptionProps = {
  value: SelectObject;
};

export const Option: React.FC<OptionProps> = ({ value }) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? 'text-white bg-blue-600' : 'text-gray-900',
          'text-left cursor-default select-none relative py-2 pl-3 pr-9'
        )
      }
      value={value.id}
    >
      {({ selected, active }) => (
        <>
          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{value.name}</span>

          {selected ? (
            <span
              className={classNames(
                active ? 'text-white' : 'text-blue-600',
                'absolute inset-y-0 right-0 flex items-center pr-4'
              )}
            >
              <FontAwesomeIcon icon={regular('check')} size={'sm'} />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};
