import React from 'react';
import { ImageListItem } from './ImageListItem';
import classNames from 'classnames';

type ImageListProps = React.PropsWithChildren;

export const ImageList: React.FC<ImageListProps> & { Item: typeof ImageListItem } = ({ children }) => {
  return (
    <div className="@container">
      <ul
        className={classNames(
          'grid grid-cols-1 gap-x-4  gap-y-6 @lg:grid-cols-2 @lg:gap-x-6 @lg:gap-y-8 @2xl:grid-cols-3 @4xl:grid-cols-4 @4xl:gap-x-8'
        )}
      >
        {children}
      </ul>
    </div>
  );
};

ImageList.Item = ImageListItem;
