import { createContext } from 'react';
import { FetchMeQuery, InvitationState, Role } from '../../api/graphql/generated';
import { WithRequiredProperty } from '../../utils/WithRequiredProperty';

type FetchedMe = WithRequiredProperty<FetchMeQuery, 'me'>;

export type Me = {
  me: FetchedMe['me'];
  isLoaded: boolean;
  refetchMe: () => void;
};

export const DEFAULT_ME: FetchedMe['me'] = {
  email: 'unknown',
  id: 0,
  name: {
    firstName: 'unknown',
    lastName: 'unknown',
  },
  role: Role.Author,
  inviteState: InvitationState.Pending,
  organization: {
    id: -1,
    name: 'unknown company',
  },
};

export const MeContext = createContext<Me>({
  me: DEFAULT_ME,
  isLoaded: false,
  refetchMe: () => new Error(`Not implemented exception`),
});
