import React from 'react';
import { ButtonAction } from './Button';
import { TextSize, TextSizeToClassname } from '../texts/Text';
import classNames from 'classnames';

type Variant = 'default' | 'blue';
const VariantToClassname: Record<Variant, string> = {
  default: 'text-grey-900 my-auto hover:text-grey-600',
  blue: 'text-blue-500 hover:text-blue-600',
};

type InTextButtonProps = ButtonAction & {
  variant?: Variant;
  size?: TextSize;
  disabled?: boolean;
};

export const InTextButton: React.FC<InTextButtonProps> = ({
  action,
  label,
  size = 's',
  variant = 'default',
  disabled,
}) => {
  return (
    <span
      onClick={action}
      className={classNames(
        'font-semibold text-center hover:cursor-pointer',
        TextSizeToClassname[size],
        VariantToClassname[variant],
        { 'hover:cursor-auto': disabled }
      )}
    >
      {label}
    </span>
  );
};
