import React, { useState } from 'react';

import { Modal } from '../atoms/layouts/Modal';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Text } from '../atoms/texts/Text';
import { LabelOnTopLeftBox } from '../atoms/inputs/layouts/LabelOnTopLeftBox';
import { Label } from '../atoms/inputs/label/Label';
import { TextInput } from '../atoms/inputs/text/TextInput';
import { Button } from '../atoms/buttons/Button';
import { SceneType, useUploadSceneMutation } from '../../repositories/scene/UploadSceneMutation';
import { MAX_INPUT_LENGTH_100 } from '../../constants';
import { isStringEmptyOrWhiteSpace } from '../../utils/isStringEmptyOrWhiteSpace';
import { SceneTypeRadioGroup } from './SceneTypeRadioGroup';
import { OnboardingTask } from '../../api/graphql/generated';
import { useOnboarding } from '../../contexts/onboarding/OnboardingConsumer';

type NewSceneModalProps = {
  file: File | undefined;
  onCreate: () => void;
  onCancel: () => void;
};

export const UploadSceneModal: React.FC<NewSceneModalProps> = ({ file, onCancel, onCreate }) => {
  const upload = useUploadSceneMutation();
  const [sceneName, setSceneName] = useState(file ? estimateFileName(file.name) : 'Scene name');
  const [sceneType, setSceneType] = useState<SceneType>(SceneType.MultiResPanorama);
  const onboarding = useOnboarding();

  const createAction = () => {
    if (sceneName && file) {
      upload
        .mutateAsync({ name: sceneName, file: file, sceneType: sceneType })
        .then(() => {
          onboarding.updateOnboarding({ taskCompleted: OnboardingTask.SceneUploaded });
          onCreate();
        })
        .catch((reason) => {
          throw reason;
        });
    }
  };

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="s">
        <Text size="m" weight="semibold">
          Upload new scene
        </Text>
        <AlignedContent direction="column" gap="m">
          <LabelOnTopLeftBox>
            <Label htmlFor="sceneName" size="s">
              Scene Name
            </Label>
            <TextInput
              id="sceneName"
              size="full"
              type="text"
              placeholder="Set scene name"
              defaultValue={sceneName}
              onInput={(e) => setSceneName(e.currentTarget.value)}
              maxLength={MAX_INPUT_LENGTH_100}
            />
          </LabelOnTopLeftBox>
          <LabelOnTopLeftBox>
            <Label htmlFor="newUserRole">Image type</Label>
            <SceneTypeRadioGroup
              id="newUserRole"
              preselected={sceneType}
              onChange={(selected) => setSceneType(selected)}
            />
          </LabelOnTopLeftBox>
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button
              label="Upload"
              action={() => sceneName && createAction()}
              disabled={isStringEmptyOrWhiteSpace(sceneName) || !file}
              loading={upload.isLoading}
            />
          </AlignedContent>
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};

function estimateFileName(filename: string): string {
  const filenameParts = filename.split('.');

  if (filenameParts.length > 1) {
    filenameParts.pop(); //remove extension
  }

  const preCapitalizedFilename = filenameParts.join('.').replaceAll('_', ' ');

  return `${preCapitalizedFilename.charAt(0).toUpperCase()}${preCapitalizedFilename.slice(1)}`;
}
