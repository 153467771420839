import React from 'react';

export const Table: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
      <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className={'shadow ring-1 ring-black ring-opacity-5'}>
          <table className="min-w-full divide-y divide-gray-300 w-full table-fixed">{children}</table>
        </div>
      </div>
    </div>
  );
};
