import React, { useState } from 'react';

import { Modal } from '../../atoms/layouts/Modal';
import { Text } from '../../atoms/texts/Text';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Button } from '../../atoms/buttons/Button';
import { TemplateCard } from '../TemplateCard';
import gql from 'graphql-tag';
import { OnboardingTask, useCreateProjectBySampleMutation, useFetchSamplesQuery } from '../../../api/graphql/generated';
import { groupBy } from '../../../utils/GroupBy';
import { Loader } from '../../molecules/loader/Loader';
import { Divider } from '../../atoms/divider/Divider';
import { ImageList } from '../../atoms/lists/ImageList';
import { FlexChild } from '../../atoms/layouts/FlexChild';
import { useOnboarding } from '../../../contexts/onboarding/OnboardingConsumer';

type PickProjectSampleProps = {
  onPick: (projectId: number, projectName: string) => void;
  onCancel: () => void;
};

type Project = {
  guid: string;
  name: string;
};

export const PickProjectSample = ({ onPick, onCancel }: PickProjectSampleProps) => {
  const [selected, setSelected] = useState<Project>();
  const fetchedSamples = useFetchSamplesQuery();
  const samples = groupBy(fetchedSamples.data?.templates ?? [], (template) => template.internalCategory || 'Other');
  const createFromSample = useCreateProjectBySampleMutation();
  const onboarding = useOnboarding();

  const copySampleHandler = () => {
    if (selected) {
      createFromSample
        .mutateAsync(
          { sampleGuid: selected.guid },
          {
            onSuccess(data, variables, context) {
              if (!!!data.CreateProjectFromTemplate.project) {
                throw Error('Data missing');
              }

              onboarding.updateOnboarding({ taskCompleted: OnboardingTask.InspiredByTemplates });
              onPick(data.CreateProjectFromTemplate.project.id, data.CreateProjectFromTemplate.project.name);
            },
          }
        )
        .catch((e) => {
          throw e;
        });
    }
  };

  return (
    <Modal
      size="75%"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="s">
        <Text size="m" weight="semibold">
          Select template
        </Text>
        <div className="max-h-500 overflow-auto flex flex-col gap-4 py-8">
          {fetchedSamples.isLoading && <Loader label="Loading available samples" />}
          {Object.keys(samples).map((name, idx) => (
            <div key={idx}>
              <AlignedContent direction="row">
                <FlexChild variant="ignore" margin="s-r" width="s">
                  <Text size="base" weight="semibold">
                    {name}
                  </Text>
                </FlexChild>
                <FlexChild variant="auto">
                  <ImageList>
                    {samples[name].map((sample) => (
                      <TemplateCard
                        key={sample.guid}
                        title={sample.name}
                        thumbnailUrl={sample.thumbnailUrl || undefined}
                        selected={sample.guid === selected?.guid}
                        onSelect={() => setSelected({ guid: sample.guid, name: sample.name || '' })}
                      />
                    ))}
                  </ImageList>
                </FlexChild>
              </AlignedContent>

              <Divider />
            </div>
          ))}
        </div>

        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button
            label="Create new"
            action={copySampleHandler}
            loading={createFromSample.isLoading}
            disabled={!selected}
          />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};

gql`
  query fetchSamples {
    templates(order: [{ name: ASC }]) {
      name
      guid
      internalCategory
      thumbnailUrl
    }
  }
`;

gql`
  mutation createProjectBySample($sampleGuid: UUID!) {
    CreateProjectFromTemplate(input: { templateIdentifier: $sampleGuid }) {
      project {
        id
        name
      }
    }
  }
`;
