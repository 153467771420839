import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Notification, NotificationVariant } from '../components/atoms/notification/Notification';

const DEFAULT_TOAST_DURATION = 5000;

export const useToast = () => {
  const addToast = useCallback(
    (title: string, description: string, variant: NotificationVariant, duration?: number) => {
      toast.custom(
        (toast) => <Notification title={title} description={description} variant={variant} toast={toast} />,
        {
          duration: duration ?? DEFAULT_TOAST_DURATION,
        }
      );
    },
    []
  );
  const addSuccessToast = useCallback(
    (title: string, description: string, duration?: number) => {
      addToast(title, description, 'success', duration);
    },
    [addToast]
  );

  const addWarningToast = useCallback(
    (title: string, description: string, duration?: number) => {
      addToast(title, description, 'warning', duration);
    },
    [addToast]
  );

  const addErrorToast = useCallback(
    (title: string, description: string, duration?: number) => {
      addToast(title, description, 'error', duration);
    },
    [addToast]
  );

  return { addSuccessToast, addWarningToast, addErrorToast };
};
