import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type NewProjectThumbnail = {
  projectId: number;
  thumbnail: File;
};

type UploadProjectThumbnailOptions = Omit<UseMutationOptions<void, unknown, NewProjectThumbnail>, 'mutationFn'>;

export const useUploadProjectThumbnailMutation = (options?: UploadProjectThumbnailOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async (newProjectThumbnail: NewProjectThumbnail) => {
      const formData = new FormData();
      formData.append('ProjectId', newProjectThumbnail.projectId.toString());
      formData.append('FormFile', newProjectThumbnail.thumbnail);

      const responseMe = await post<void, FormData>('/api/project/upload-thumbnail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return responseMe.data;
    },
  });
};
