import classNames from 'classnames';
import { Text } from '../texts/Text';
import React from 'react';

type CardTitleProps = React.PropsWithChildren & {
  selected?: boolean;
};

export const CardTitle = ({ selected, children }: CardTitleProps) => {
  return (
    <div className={classNames('group-hover/card:text-blue-500', { 'text-blue-500': selected })}>
      <Text align="left" weight="semibold" size="base">
        {children}
      </Text>
    </div>
  );
};
