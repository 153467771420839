import React, { useState } from 'react';
import { SelectFileModal, UploadFileFunction } from './SelectFileModal';
import { useSwitch } from '../../hooks/useSwitch';
import { UploadSceneModal } from './UploadSceneModal';
import { GetSampleSceneText } from './GetSampleSceneText';

const MAX_150MB = 157_288_400;

type UploadNewSceneWizzardProps = {
  onCreate: () => void;
  onCancel: () => void;
};

export const UploadNewSceneWizard: React.FC<UploadNewSceneWizzardProps> = ({ onCreate, onCancel }) => {
  const [file, setFile] = useState<File>();
  const selectSceneFile = useSwitch(true);
  const createNewScene = useSwitch(false);

  const uploadFileHandler: UploadFileFunction = Object.assign(
    (file: File) => {
      setFile(file);
      selectSceneFile.turnOff();
      createNewScene.turnOn();
    },
    {
      isLoading: false,
    }
  );

  return (
    <>
      {selectSceneFile.isOn && (
        <SelectFileModal
          title="Upload new scene"
          maxFileSize={MAX_150MB}
          acceptFileType={{ 'image/jpeg': 'JPG' }}
          onCreate={uploadFileHandler}
          onCancel={() => onCancel()}
          footerText={<GetSampleSceneText />}
        />
      )}
      {createNewScene.isOn && <UploadSceneModal file={file} onCreate={onCreate} onCancel={() => onCancel()} />}
    </>
  );
};
