import { Auth0ContextInterface, Auth0Provider, useAuth0, withAuthenticationRequired, User } from '@auth0/auth0-react';
import { WithRequiredProperty } from '../../utils/WithRequiredProperty';

export type UserActions = {
  user: PanomioUser;
  getAccessTokenSilently: () => Promise<string>;
  logout: () => void;
  isLoading: Auth0ContextInterface<User>['isLoading'];
  error: Auth0ContextInterface<User>['error'];
};

type PanomioUser = WithRequiredProperty<User, 'email'>;

type NonValidatedUserActions = {
  email: string;
  verified: () => boolean;
  logout: () => void;
  isLoading: Auth0ContextInterface<User>['isLoading'];
  forceNewToken: () => Promise<void>;
};

export const AuthenticatedUserProvider = Auth0Provider;
export const withRequiredAuthenticatedUser = withAuthenticationRequired;

export function useNonValidatedUser(): NonValidatedUserActions {
  const auth0 = useAuth0();

  return {
    email: auth0.user?.email ?? '',
    isLoading: auth0.isLoading,
    logout: createLogout(auth0),
    verified: () => auth0.isAuthenticated && !!auth0.user?.email_verified,
    forceNewToken: () =>
      // auth0.loginWithRedirect({ redirect_uri: window.location.origin }),
      auth0.getAccessTokenSilently({ ignoreCache: true, redirect_uri: window.location.origin }).then(),
  };
}

export function useUser(): UserActions {
  const auth0 = useAuth0();

  if (!auth0.user) {
    throw new Error('Unable to get user');
  }

  return {
    user: hydrateUser(auth0.user),
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    logout: createLogout(auth0),
    isLoading: auth0.isLoading,
    error: auth0.error,
  };
}

function hydrateUser({ email, ...props }: User): PanomioUser {
  if (!email) {
    throw new Error('User not properly registered');
  }
  return {
    ...props,
    email,
  };
}

function createLogout(auth0: Auth0ContextInterface): () => void {
  return () => auth0.logout({ returnTo: window.location.origin });
}
