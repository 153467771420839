import { SubscriptionTier } from '../api/graphql/generated';

export const pricingPlanToText = (pricingPlan: SubscriptionTier) => {
  switch (pricingPlan) {
    case SubscriptionTier.Free:
      return 'FREE';
    case SubscriptionTier.Pro:
      return 'BASIC';
    case SubscriptionTier.Team:
      return 'PRO';
    case SubscriptionTier.Enterprise:
      return 'ENTERPRISE';
  }
};
