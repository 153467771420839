const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export const bytesToSizeString = (bytes: number, separator = ' ', decimals = 0): string => {
  if (bytes === 0) {
    return 'n/a';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return `${bytes}${separator}${sizes[i]}`;
  }

  return `${(bytes / 1024 ** i).toFixed(decimals)}${separator}${sizes[i]}`;
};
