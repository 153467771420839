import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export type InputSize = 's' | 'l' | 'full';
export const SizeToClassname: Record<InputSize, string> = {
  s: 'max-w-40',
  l: 'max-w-80',
  full: 'w-full',
};

export type TextInputProps = {
  id?: InputHTMLAttributes<HTMLInputElement>['id'];
  name?: InputHTMLAttributes<HTMLInputElement>['name'];
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder'];
  defaultValue?: InputHTMLAttributes<HTMLInputElement>['defaultValue'];
  onInput?: InputHTMLAttributes<HTMLInputElement>['onInput'];
  pattern?: InputHTMLAttributes<HTMLInputElement>['pattern'];
  readOnly?: InputHTMLAttributes<HTMLInputElement>['readOnly'];
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  size?: InputSize;
  type: Extract<
    InputHTMLAttributes<HTMLInputElement>['type'],
    'email' | 'number' | 'password' | 'tel' | 'text' | 'url'
  >;
  errorMessage?: string;
  maxLength?: number;
};

export const TextInput: React.FC<TextInputProps> = ({ size = 'full', id, errorMessage, ...props }) => {
  return (
    <div className={classNames('flex justify-end mt-0 grow')}>
      <input
        {...props}
        className={classNames(
          'block w-full duration-150 shadow-sm focus:ring-1 focus:ring-blue-400 focus:border-blue-500',
          'border-gray-300 text-gray-800 font-semibold rounded-md text-sm',
          'disabled:bg-grey-300 disabled:text-grey-500',
          SizeToClassname[size],
          { ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': !!errorMessage }
        )}
        aria-invalid={!!errorMessage}
        aria-describedby={`${id ?? ''}-input-error`}
      />
      {errorMessage && (
        <p className="mt-0 text-xs text-red-600" id={`${id ?? ''}-input-error`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
