import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ImagePlaceholderProps = {
  icon: IconProp;
};

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ icon }) => {
  return (
    <div className="w-full h-full bg-grey-300 flex justify-center items-center">
      <FontAwesomeIcon className="h-16 text-grey-400" icon={icon} />
    </div>
  );
};
