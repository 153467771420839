import React, { useEffect, useState } from 'react';
import { OnboardingTask, useMyOnboardingQuery, useUpdateOnboardingMutation } from '../../api/graphql/generated';
import {
  DEFAULT_ONBOARDING_STATUS,
  OnboardingContext,
  OnboardingStatusContext,
  UpdateOnboardingParams,
} from './OnboardingContext';
import { OnboardingStatus } from './OnboardingStatus';

export const OnboardingProvider = ({ children }: React.PropsWithChildren) => {
  const onboardingQuery = useMyOnboardingQuery(undefined, { refetchOnWindowFocus: false });

  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>(DEFAULT_ONBOARDING_STATUS);

  useEffect(() => {
    if (onboardingQuery.data?.me) {
      setOnboardingStatus({
        isFinished: onboardingQuery.data.me.onboarding?.isOnboardingFinished ?? false,
        completedTasks: transformOnboardingFromGQL(onboardingQuery.data.me.onboarding?.onboardingCheckList),
      });
    }
  }, [onboardingQuery.data]);

  const updateOnboardingMutation = useUpdateOnboardingMutation();
  const updateOnboarding = (params: UpdateOnboardingParams) => {
    if (!onboardingStatus) {
      return;
    }

    if (
      (params.taskCompleted && onboardingStatus.completedTasks.includes(params.taskCompleted)) ||
      onboardingStatus.isFinished
    ) {
      return;
    }

    updateOnboardingMutation.mutate({
      onboarding: params.taskCompleted ?? null,
      isFinished: params.isFinished ?? null,
    });

    if (params.taskCompleted) {
      onboardingStatus.completedTasks.push(params.taskCompleted);
    }

    if (params.isFinished) {
      onboardingStatus.isFinished = params.isFinished;
    }
    setOnboardingStatus(onboardingStatus);
  };

  const provided: OnboardingStatusContext = {
    onboardingStatus,
    isLoaded: onboardingQuery.isSuccess,
    updateOnboarding,
  };

  return <OnboardingContext.Provider value={provided}>{children}</OnboardingContext.Provider>;
};

const transformOnboardingFromGQL = (completedTasks?: Array<OnboardingTask | null> | null): OnboardingTask[] => {
  if (!completedTasks) {
    return [];
  }

  return completedTasks.reduce((withoutMaybe: OnboardingTask[], onboardingFlag) => {
    if (onboardingFlag) {
      withoutMaybe.push(onboardingFlag);
    }
    return withoutMaybe;
  }, []);
};
