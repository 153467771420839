import { UseMutationOptions } from '@tanstack/react-query';
import gql from 'graphql-tag';
import {
  RemoveTeamMemberMutation,
  RemoveTeamMemberMutationVariables,
  useRemoveTeamMemberMutation,
} from '../../api/graphql/generated';

type RemoveCollaboratorOptions = UseMutationOptions<
  RemoveTeamMemberMutation,
  unknown,
  RemoveTeamMemberMutationVariables
>;

export const useRemoveTeamMateMutation = (options?: RemoveCollaboratorOptions) => {
  return useRemoveTeamMemberMutation(options);
};

gql`
  mutation removeTeamMember($userId: Int!) {
    RemoveTeamMember(input: { userId: $userId }) {
      int
    }
  }
`;
