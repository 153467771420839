import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FilesListItem } from './FileListItem';
import { LinkButton } from '../../buttons/LinkButton';

type FileListItemProps = {
  filename: string;
  onDownloadLink: string;
};

export const FilesListDownloadItem: React.FC<FileListItemProps> = ({ filename, onDownloadLink }) => {
  return (
    <FilesListItem
      icon={solid('file')}
      filename={filename}
      button={
        <span className="float-right mr-2 font-semibold text-sm" aria-label="download file">
          <LinkButton variant="blue" href={onDownloadLink} label="Download" />
        </span>
      }
    />
  );
};
