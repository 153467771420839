import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Submenu } from '../../components/molecules/submenu/Submenu';
import { Outlet } from 'react-router-dom';
import { LayoutWithSubmenu } from '../../components/atoms/layouts/LayoutWithSubmenu';

export const SettingsPage: React.FC = () => {
  return (
    <>
      <LayoutWithSubmenu>
        <Submenu label="Settings">
          <Submenu.Item
            label="General"
            description="Manage universal settings and preferences of this organization."
            icon={regular('gear')}
            to="general"
          />
          <Submenu.Item
            label="Subscription &amp; Billing"
            description="Change your tier, add seats or manage your billing information."
            icon={regular('credit-card')}
            to="billing"
          />
        </Submenu>
        <Outlet />
      </LayoutWithSubmenu>
    </>
  );
};
