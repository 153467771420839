import React, { useState } from 'react';

import { Modal } from '../atoms/layouts/Modal';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Text } from '../atoms/texts/Text';
import { Button } from '../atoms/buttons/Button';
import { FilePicker, FilePickerProps } from '../atoms/inputs/FilePicker';

export type UploadFileFunction = {
  (file: File): void;
  isLoading: boolean;
};

type NewSceneModalProps = {
  title: React.ReactNode;
  maxFileSize: FilePickerProps['maxFileSize'];
  acceptFileType: FilePickerProps['acceptFileType'];
  onlyOneFile?: FilePickerProps['onlyOneFile'];
  createButtonText?: React.ReactNode;
  onCreate: UploadFileFunction;
  footerText?: React.ReactNode;
  onCancel: () => void;
};

export const SelectFileModal: React.FC<NewSceneModalProps> = ({
  title,
  maxFileSize,
  acceptFileType,
  onlyOneFile,
  createButtonText = 'Next',
  onCreate,
  footerText,
  onCancel,
}) => {
  const [file, setFile] = useState<File>();

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="s">
        <Text size="m" weight="semibold">
          {title}
        </Text>
        <AlignedContent direction="column" gap="m">
          <AlignedContent direction="column" gap="s">
            <FilePicker
              id="file-picker"
              onlyOneFile={onlyOneFile}
              maxFileSize={maxFileSize}
              acceptFileType={acceptFileType}
              onChange={(files) => {
                setFile(files.length > 0 ? files[0] : undefined);
              }}
            />
            {footerText}
          </AlignedContent>
          <AlignedContent direction="row" gap="s" placing="fromEnd" positioning="center">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button
              label={createButtonText}
              action={() => file && onCreate(file)}
              loading={onCreate.isLoading}
              disabled={!file}
            />
          </AlignedContent>
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
