import React from 'react';
import { ImageList } from '../atoms/lists/ImageList';
import { DropDown } from '../molecules/dropDown/DropDown';
import { useNavigate } from 'react-router-dom';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FloatingItemLayout } from '../atoms/layouts/FloatingItemLayout';
import { useSwitch } from '../../hooks/useSwitch';
import { DeleteProjectModal } from './DeleteProjectModal';
import { SelectFileModal, UploadFileFunction } from './SelectFileModal';
import { useUploadProjectThumbnailMutation } from '../../repositories/project/UploadProjectThumbnailMutation';
import { useDuplicateProjectMutation } from '../../api/graphql/generated';
import { RenameProjectModal } from './RenameProjectModal';

const MAX_5MB = 5_242_880;

type ProjectTileProps = {
  projectId: number;
  projectName: string | null;
  thumbnailUrl?: string;
  onThumbnailSet: () => void;
  onProjectRenamed: () => void;
  canDuplicate: boolean; //TODO: refactor immediately, parent has to define dropwdown...
  onDuplicateProject: () => void;
  onDeleteProject: () => void;
};

export const ProjectTile: React.FC<ProjectTileProps> = ({
  projectId,
  projectName,
  thumbnailUrl,
  onThumbnailSet,
  onDuplicateProject,
  onProjectRenamed,
  onDeleteProject,
  canDuplicate,
}) => {
  const navigate = useNavigate();
  const upload = useUploadProjectThumbnailMutation();
  const uploadThumbnail = useSwitch(false);
  const uploadFileHandler: UploadFileFunction = Object.assign(
    (thumbnail: File) => {
      upload
        .mutateAsync({ projectId, thumbnail })
        .then(() => {
          onThumbnailSet();
          uploadThumbnail.turnOff();
        })
        .catch((reason) => {
          throw reason;
        });
    },
    {
      isLoading: upload.isLoading,
    }
  );
  const renameProject = useSwitch(false);

  const deleteModal = useSwitch(false);
  const duplicate = useDuplicateProjectMutation();

  const dropDown = (
    <DropDown menuAlignment="right">
      <DropDown.Option
        label="Settings"
        action={() => {
          navigate(`${projectId}/settings/sharing`);
        }}
      />
      <DropDown.Option label="Set thumbnail" action={() => uploadThumbnail.turnOn()} />
      <DropDown.Option
        label="Duplicate"
        action={() => {
          duplicate
            .mutateAsync({ projectId })
            .then(() => onDuplicateProject())
            .catch((reason) => {
              throw reason;
            });
        }}
        disabled={!canDuplicate}
      />
      <DropDown.Option
        label="Rename"
        action={() => {
          renameProject.turnOn();
        }}
      />
      <DropDown.Option
        variant="danger"
        label="Delete"
        action={() => {
          deleteModal.turnOn();
        }}
      />
    </DropDown>
  );

  return (
    <>
      <FloatingItemLayout floatingItem={dropDown}>
        <ImageList.Item
          imageSrc={thumbnailUrl}
          placeholderIcon={solid('layer-group')}
          screenReaderLabel={`View details for ${projectName ?? 'unknown course name'}`}
          action={() => {
            const url = new URL(`/projects/open/${projectId}`, window.location.origin);
            window.location.href = url.href;
          }}
        >
          <ImageList.Item.Description text={projectName ?? 'name not provided'} />
        </ImageList.Item>
      </FloatingItemLayout>
      {uploadThumbnail.isOn && (
        <SelectFileModal
          title={`Set thumbnail for ${projectName ?? ''}`}
          onlyOneFile
          maxFileSize={MAX_5MB}
          acceptFileType={{ 'image/jpeg': 'JPG', 'image/png': 'PNG' }}
          createButtonText="Set thumbnail"
          onCreate={uploadFileHandler}
          onCancel={() => uploadThumbnail.turnOff()}
        />
      )}
      {deleteModal.isOn && (
        <DeleteProjectModal
          projectId={projectId}
          projectName={projectName ?? ''}
          onDelete={() => {
            deleteModal.turnOff();
            onDeleteProject();
          }}
          onCancel={() => deleteModal.turnOff()}
        />
      )}
      {renameProject.isOn && (
        <RenameProjectModal
          projectId={projectId}
          oldName={projectName ?? ''}
          onRename={() => {
            renameProject.turnOff();
            onProjectRenamed();
          }}
          onCancel={() => renameProject.turnOff()}
        />
      )}
    </>
  );
};

export const ProjectTiles = ImageList;
