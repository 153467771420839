import React from 'react';
import { SectionHeader } from '../../components/organisms/SectionHeader';
import { EmptyState } from '../../components/molecules/emptyState/EmptyState';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '../../components/atoms/buttons/Button';
import { Centered } from '../../components/atoms/layouts/Centered';
import gql from 'graphql-tag';
import { Divider } from '../../components/atoms/divider/Divider';
import { useFetchProjectsQuery } from '../../api/graphql/generated';
import { Loader } from '../../components/molecules/loader/Loader';
import { ProjectTile, ProjectTiles } from '../../components/organisms/ProjectTile';
import { MenuContentContainer } from '../../components/atoms/layouts/MenuContentContainer';
import { Banner } from '../../components/atoms/banners/Banner';
import { useActivePlan } from '../../contexts/activePlan/ActivePlanCosumer';
import { useMe } from '../../contexts/me/MeConsumer';
import { useSwitch } from '../../hooks/useSwitch';
import { CreateNewProjectWizard } from '../../components/organisms/project/CreateNewProjectWizard';
import { WelcomeModal } from '../../components/organisms/WelcomeModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OnboardingProgressCard } from '../../components/organisms/onboarding/OnboardingProgressCard';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useFireworksConfetti } from '../../hooks/confetti/useFireworks';
import { pricingPlanToText } from '../../utils/pricingPlanToText';

export const Projects: React.FC = () => {
  const meContext = useMe(true);
  const projectsQuery = useFetchProjectsQuery(
    { organizationId: meContext.me.organization.id },
    { refetchOnWindowFocus: false }
  );
  const { activePlan } = useActivePlan();
  const newProjectOpened = useSwitch(false);
  const navigate = useNavigate();
  const projects = [...(projectsQuery.data?.projectsByOrganizationId ?? [])].reverse();
  const limitReached = activePlan.projectLimit !== null && projects.length >= activePlan.projectLimit;

  const [searchParams, setSearchParams] = useSearchParams();

  const fireworks = useFireworksConfetti({ delayBetweenExplosionsInMilliseconds: 400, explosionCount: 5 });

  const refetchProjects = () => {
    projectsQuery.refetch().catch((reason) => {
      throw reason;
    });
  };

  return (
    <>
      <MenuContentContainer>
        <SectionHeader>
          <SectionHeader.TitleWithActions title={'Courses'}>
            <Button
              label={'Create new course'}
              action={newProjectOpened.turnOn}
              size={'m'}
              variant={'primary'}
              disabled={limitReached}
            />
          </SectionHeader.TitleWithActions>
        </SectionHeader>
        <Divider />
        {projectsQuery.isLoading && (
          <Centered tryGoldenCenter>
            <Loader label="Loading your courses..." />
          </Centered>
        )}
        {limitReached && activePlan.projectLimit !== null && (
          <Banner
            icon={solid('circle-info')}
            text={`${pricingPlanToText(activePlan.type)} pricing plan allows you to build ${
              activePlan.projectLimit
            } courses. If you want to build more, upgrade your account.`}
            button={{ label: 'Upgrade', action: () => navigate('/pricing') }}
          />
        )}
        {!projectsQuery.isLoading && projects?.length !== 0 && (
          <ProjectTiles>
            {projects.map((project) => (
              <ProjectTile
                key={`project${project.id}`}
                projectName={project.name ?? null}
                projectId={project.id}
                thumbnailUrl={project.thumbnailUrl ?? undefined}
                onThumbnailSet={refetchProjects}
                onProjectRenamed={refetchProjects}
                onDeleteProject={refetchProjects}
                onDuplicateProject={refetchProjects}
                canDuplicate={!limitReached}
              />
            ))}
          </ProjectTiles>
        )}
        <OnboardingProgressCard onOnboardingFinished={fireworks.startAnimation} />
        <ReactCanvasConfetti
          refConfetti={fireworks.confettiRef}
          className="fixed top-0 left-0 w-screen h-screen pointer-events-none"
        />
        {!projectsQuery.isLoading && projects?.length === 0 && (
          <Centered tryGoldenCenter>
            <EmptyState
              icon={regular('box')}
              title="No Courses"
              description="Let's create your first Course"
              button={{ label: 'Create new course', action: newProjectOpened.turnOn }}
            />
          </Centered>
        )}
        {newProjectOpened.isOn && (
          <CreateNewProjectWizard
            organizationId={meContext.me.organization.id}
            onCreate={(id, projectName) => {
              const url = new URL(`/projects/open/${id}`, window.location.origin);
              window.location.href = url.href;
            }}
            onCancel={newProjectOpened.turnOff}
          />
        )}
      </MenuContentContainer>
      {searchParams.has('welcome') && (
        <WelcomeModal
          onClose={() => {
            searchParams.delete('welcome');
            setSearchParams(searchParams);
            refetchProjects();
          }}
        />
      )}
    </>
  );
};

gql`
  query fetchProjects($organizationId: Int!) {
    projectsByOrganizationId(organizationId: $organizationId) {
      id
      thumbnailUrl
      name
    }
  }
`;
