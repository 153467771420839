import React, { useMemo } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Menu } from '../molecules/menu/Menu';
import { SubscriptionTier } from '../../api/graphql/generated';
import { useActivePlan } from '../../contexts/activePlan/ActivePlanCosumer';
import { useMe } from '../../contexts/me/MeConsumer';
import { isOwnerRole } from '../../utils/isOwnerRole';
import { useUser } from '../../plugins/auth/Authentication';

const TEAM_SUBSCRIPTION = [SubscriptionTier.Team, SubscriptionTier.Enterprise];

type HomeWithMenuProps = React.PropsWithChildren;

export const HomeWithMenu: React.FC<HomeWithMenuProps> = ({ children }) => {
  const { activePlan } = useActivePlan();
  const userActions = useUser();
  const {
    me: { role },
  } = useMe(true);
  const defaultMenu = useMemo(
    () => [
      {
        groupLabel: 'COURSES',
        items: [
          { label: 'Courses', icon: regular('grid-2'), href: 'project' },
          { label: 'Scenes', icon: regular('panorama'), href: 'scene' },
          { label: 'Media', icon: regular('photo-film-music'), href: 'media' },
          // { label: 'COMPONENTS', icon: regular('star'), href: 'components' },
        ],
      },
      {
        groupLabel: 'MANAGEMENT',
        items: [
          ...(isOwnerRole(role)
            ? [{ label: 'Settings', icon: regular('gear'), selected: false, href: 'settings' }]
            : []),
          ...(TEAM_SUBSCRIPTION.includes(activePlan.type)
            ? [{ label: 'Team', icon: regular('users'), selected: false, href: 'team' }]
            : []),
        ],
      },
    ],
    [activePlan.type, role]
  );

  return (
    <>
      <Menu>
        <Menu.Groups>
          {defaultMenu.map(({ groupLabel, items }, groupIdx) => {
            return (
              <Menu.Group key={`MenuGroup-${groupIdx}`} label={groupLabel}>
                <Menu.Items>
                  {items.map(({ label, icon, href }, itemIdx) => {
                    return <Menu.Item key={`MenuItem-${itemIdx}`} label={label} icon={icon} href={href} />;
                  })}
                </Menu.Items>
              </Menu.Group>
            );
          })}
        </Menu.Groups>
        <Menu.Group label="Help">
          <Menu.Items>
            <Menu.ExternalLink
              label="Documentation"
              icon={regular('book')}
              href="https://panomio.com/resources/docs/introduction"
            />
          </Menu.Items>
        </Menu.Group>
        <br />

        {activePlan.type === SubscriptionTier.Free && <Menu.UpgradePlan href={'/pricing'} selected={false} />}
        <Menu.Profile user={userActions.user} logout={userActions.logout} />
      </Menu>

      {children}
    </>
  );
};
