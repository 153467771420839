import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type NewSceneThumbnail = {
  sceneId: number;
  thumbnail: File;
};

type UploadSceneThumbnailOptions = Omit<UseMutationOptions<void, unknown, NewSceneThumbnail>, 'mutationFn'>;

export const useUploadSceneThumbnailMutation = (options?: UploadSceneThumbnailOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async (newSceneThumbnail: NewSceneThumbnail) => {
      const formData = new FormData();
      formData.append('SceneId', newSceneThumbnail.sceneId.toString());
      formData.append('FormFile', newSceneThumbnail.thumbnail);

      const responseMe = await post<void, FormData>('/api/scene/upload-thumbnail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return responseMe.data;
    },
  });
};
