import React from 'react';

type CheckboxProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  id?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>['id'];
  name?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>['name'];
  onInput?: (checked: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({ label, description, onInput, ...props }) => {
  return (
    <div className="relative flex items-center">
      <div className="flex h-5 items-center">
        <input
          onInput={(event) => {
            onInput && onInput(event.currentTarget.checked);
          }}
          {...props}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-0"
        />
      </div>
      <div className="ml-3 text-sm">
        {label}
        {description}
      </div>
    </div>
  );
};
