import { useGenerateCheckoutSession } from './GenerateCheckoutSessionMutation';

type SubscriptionRepo = {
  generateCheckoutSession: (lookupKey: string, cancelUrl: string, successUrl: string) => Promise<string>;
};

export const useSubscriptionRepo = (): SubscriptionRepo => {
  const generateCheckoutSession = useGenerateCheckoutSession();

  return {
    generateCheckoutSession: (lookupKey: string, cancelUrl: string, successUrl: string) =>
      generateCheckoutSession.mutateAsync({ lookupKey, cancelUrl, successUrl }),
  };
};
