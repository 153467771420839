import React, { useEffect } from 'react';
import logo from '../../../assets/logos/logo.svg';
import { MenuGroups } from './MenuGroups';
import { MenuGroup } from './MenuGroup';
import { MenuItems } from './MenuItems';
import { MenuItem } from './MenuItem';
import { MenuProfile } from './MenuProfile';
import { MenuUpgradePlan } from './MenuUpgradePlan';
import { Link, useLocation } from 'react-router-dom';
import { MenuLink } from './MenuLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSwitch } from '../../../hooks/useSwitch';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import classNames from 'classnames';

type MenuContent = {
  Groups: typeof MenuGroups;
  Group: typeof MenuGroup;
  Items: typeof MenuItems;
  Item: typeof MenuItem;
  ExternalLink: typeof MenuLink;
  Profile: typeof MenuProfile;
  UpgradePlan: typeof MenuUpgradePlan;
};

type MenuProps = React.PropsWithChildren;
export const Menu: React.FC<MenuProps> & MenuContent = ({ children }) => {
  const mobileMenuVisible = useSwitch(false);

  const isSmallScreenSize = useMediaQuery('(max-width: 768px)');

  const { pathname } = useLocation();
  useEffect(() => {
    mobileMenuVisible.turnOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Disable scrolling on body when mobile menu is open
  useEffect(() => {
    if (isSmallScreenSize && mobileMenuVisible.isOn) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSmallScreenSize, mobileMenuVisible.isOn]);

  return (
    <div className="md:w-80 min-h-44px shrink-0">
      <div className="fixed z-40 flex items-center justify-start w-full p-3 bg-gray-800 md:hidden">
        <FontAwesomeIcon
          icon={mobileMenuVisible.isOn ? solid('xmark') : solid('bars')}
          className="absolute p-2 text-sm text-white cursor-pointer"
          onClick={mobileMenuVisible.toggle}
          fixedWidth
        />
        <Link to="/" className="mx-auto flex-grow-1">
          <img className="w-auto h-5" src={logo} alt="Panomio" />
        </Link>
      </div>
      <div
        className={classNames(
          'fixed z-30 flex flex-col flex-1 w-full h-full px-4 pt-16 pb-4 overflow-y-auto bg-gray-800 border-r border-gray-800 md:pt-5 md:w-80',
          { 'hidden md:flex': !mobileMenuVisible.isOn }
        )}
      >
        <div className="flex items-center flex-shrink-0 hidden px-4 py-6 space-y-5 md:block">
          <Link to="/">
            <img className="w-auto h-8" src={logo} alt="Panomio" />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

Menu.Groups = MenuGroups;
Menu.Group = MenuGroup;
Menu.Items = MenuItems;
Menu.Item = MenuItem;
Menu.ExternalLink = MenuLink;
Menu.Profile = MenuProfile;
Menu.UpgradePlan = MenuUpgradePlan;
