import React from 'react';
import classNames from 'classnames';

type CardSelectableProps = React.PropsWithChildren & {
  action: React.ReactNode;
  selected?: boolean;
};

export const CardHoverableActions = ({ action, selected, children }: CardSelectableProps): JSX.Element => {
  return (
    <div className="relative rounded-lg truncate">
      <div className={classNames(`flex justify-center align-middle`)}>{children}</div>
      <div
        className={classNames(
          selected ? 'flex' : 'hidden',
          'absolute inset-0 items-center justify-center w-full h-full',
          `group-hover/card:flex`
        )}
      >
        {action}
      </div>
    </div>
  );
};
