import React from 'react';
import classNames from 'classnames';

type Variant = 'ignore' | 'auto';
const VariantToClassname: Record<Variant, string> = {
  auto: 'flex-auto',
  ignore: 'flex-none',
};

type Width = 's';
const WidthToClassname: Record<Width, string> = {
  s: 'w-32',
};

type Margin = 's-r';
const MarginToClassname: Record<Margin, string> = {
  's-r': ' mr-7',
};

type FlexChildProps = React.PropsWithChildren & {
  variant: Variant;
  width?: Width;
  margin?: Margin;
};

export const FlexChild = ({ variant, width, margin, children }: FlexChildProps) => {
  return (
    <div
      className={classNames(
        VariantToClassname[variant],
        width && WidthToClassname[width],
        margin && MarginToClassname[margin]
      )}
    >
      {children}
    </div>
  );
};
