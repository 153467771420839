import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from '@tanstack/react-query';
import { Me } from '../me/MeEntity';
import { useFetch } from '../../api/fetch';

type RenameOrganizationOptions = Omit<UseMutationOptions<Me, unknown, Rename>, 'mutationFn'>;

type Rename = {
  organizationName: string;
};
export const useRenameOrganization = (options?: RenameOrganizationOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async ({ organizationName }: Rename) => {
      const responseMe = await post<Me, Rename>('/api/RenameOrganization', {
        organizationName: organizationName,
      });
      return responseMe.data;
    },
  });
};
