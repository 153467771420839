import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type ProjectExportProgressOptions = Omit<UseQueryOptions<boolean, unknown, boolean, string[]>, 'queryFn' | 'queryKey'>;

export const useProjectExportProgressQuery = (token: string, options?: ProjectExportProgressOptions) => {
  const { get } = useFetch();
  return useQuery(
    ['get export status', token.toString()],
    async () => {
      try {
        await get<void, string>(`/api/export-project/check-progress/${token.toString()}`, undefined, {});
        return true;
      } catch (e) {
        return false;
      }
    },
    options
  );
};
