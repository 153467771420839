import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, To } from 'react-router-dom';
import classNames from 'classnames';

interface MenuUpgradePlanProps {
  href: To;
  selected: boolean;
}
export const MenuUpgradePlan: React.FC<MenuUpgradePlanProps> = ({ href, selected }) => {
  return (
    <div className="my-2.5">
      <Link
        className={classNames(
          selected
            ? 'bg-blue-600 border-blue-500 text-blue-500 '
            : 'bg-blue-500 text-white hover:bg-blue-600 animate-background duration-150 ',
          'group flex items-center px-4 py-4 text-base font-semibold rounded-lg'
        )}
        to={href}
      >
        <FontAwesomeIcon
          icon={regular('sparkles')}
          className={classNames(selected ? 'text-blue-500' : 'text-white', 'mr-3 flex-shrink-0 h-4 w-4')}
          aria-hidden="true"
        />
        Upgrade plan
      </Link>
    </div>
  );
};
