import { Modal } from '../atoms/layouts/Modal';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Text } from '../atoms/texts/Text';
import { Divider } from '../atoms/divider/Divider';
import { DocumentLink } from '../molecules/documentLink/DocumentLink';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '../atoms/buttons/Button';
import { ImageList } from '../atoms/lists/ImageList';
import { TemplateCard } from './TemplateCard';

import React, { useState } from 'react';
import { useSwitch } from '../../hooks/useSwitch';
import { useUser } from '../../plugins/auth/Authentication';
import { OnboardingTask, useCreateProjectBySampleMutation, useFetchSamplesQuery } from '../../api/graphql/generated';
import { useToast } from '../../hooks/useToast';
import { PickProjectSample } from './project/PickProjectSample';
import { useOnboarding } from '../../contexts/onboarding/OnboardingConsumer';

const SAMPLES_TO_SHOW = 4;

type WelcomeModalProps = {
  onClose: () => void;
};
export const WelcomeModal = ({ onClose }: WelcomeModalProps) => {
  const showAllSamplesSwitch = useSwitch(false);
  const { user } = useUser();
  const fetchedSamples = useFetchSamplesQuery();
  const samplesForWelcome = fetchedSamples.data?.templates.slice(0, SAMPLES_TO_SHOW);
  const [copiedSamples, setCopiedSamples] = useState<string[]>([]);
  const createFromSample = useCreateProjectBySampleMutation();
  const onboarding = useOnboarding();
  const { addErrorToast } = useToast();

  const copySampleHandler = (sampleGuid: string) => {
    createFromSample
      .mutateAsync({ sampleGuid })
      .then(() => onboarding.updateOnboarding({ taskCompleted: OnboardingTask.InspiredByTemplates }))
      .catch((e) => {
        addErrorToast('Course limit reached', 'Free plan allows you to have have 3 courses at the time.');
      });
  };

  return (
    <>
      <Modal size="4xl" padding="l" backdrop>
        <div className="pr-4 mb-4 -mr-3 overflow-auto">
          <AlignedContent direction="column" gap="xs">
            <Text size="xl" weight="semibold">
              Welcome {user.given_name}! 🎉
            </Text>
            <Text size="s" weight="normal">
              Let’s get you set up with Panomio. Here are some useful resources you might like that will help you build
              amazing courses with Panomio.
            </Text>
          </AlignedContent>

          <Divider />
          <AlignedContent direction="column" gap="xxl">
            <AlignedContent direction="column" gap="l">
              <AlignedContent direction="column" gap="xxs">
                <Text size="m" weight="semibold">
                  Getting started
                </Text>
                <Text color="grey" size="s" weight="normal">
                  Everything you need to know to get started with Panomio
                </Text>
              </AlignedContent>
              <ImageList>
                <DocumentLink
                  icon={solid('play')}
                  href="https://youtu.be/npnhHZVVjAQ"
                  title="Getting started"
                  description="Short video highlighting main features of Panomio"
                />
              </ImageList>
            </AlignedContent>
            <AlignedContent direction="column" gap="l">
              <AlignedContent direction="column" gap="xxs">
                <Text size="m" weight="semibold">
                  Learn how to use Panomio
                </Text>
                <Text color="grey" size="s" weight="normal">
                  Visit our documentation pages and learn the basics.
                </Text>
              </AlignedContent>
              <ImageList>
                <DocumentLink
                  icon={solid('book')}
                  href="https://panomio.com/resources/docs/introduction"
                  title="Introduction"
                  description="Complete basics of Panomio course authoring"
                />
                <DocumentLink
                  icon={solid('book')}
                  href="https://panomio.com/resources/docs/lesson-types"
                  title="Lesson Types"
                  description="Learn the differences between different lesson types"
                />
                <DocumentLink
                  icon={solid('book')}
                  href="https://panomio.com/resources/docs/upload-scene"
                  title="Uploading your first scene"
                  description="Learn about scenes and their role in Panomio"
                />
              </ImageList>
            </AlignedContent>
            <AlignedContent direction="column" gap="m">
              <AlignedContent direction="row" positioning="center" gap="space-between">
                <AlignedContent direction="column" gap="xxs">
                  <Text size="m" weight="semibold">
                    Get inspired with other projects
                  </Text>
                  <Text color="grey" size="s" weight="normal">
                    See some exaple projects built with Panomio and get inspired!
                  </Text>
                </AlignedContent>
                <Button variant="tertiary" label="See more templates" action={() => showAllSamplesSwitch.turnOn()} />
              </AlignedContent>
            </AlignedContent>
            <ImageList>
              {samplesForWelcome?.map((sample) => (
                <TemplateCard
                  key={sample.guid}
                  title={sample.name}
                  thumbnailUrl={sample.thumbnailUrl || undefined}
                  selected={copiedSamples.includes(sample.guid)}
                  onSelect={() => {
                    setCopiedSamples([...copiedSamples, sample.guid]);
                    copySampleHandler(sample.guid);
                  }}
                  selectedText="Copied"
                  selectText="Get a copy"
                />
              ))}
            </ImageList>
          </AlignedContent>
        </div>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Start creating" action={onClose} variant="primary" loading={createFromSample.isLoading} />
        </AlignedContent>
      </Modal>

      {showAllSamplesSwitch.isOn && (
        <PickProjectSample
          onPick={() => showAllSamplesSwitch.turnOff()}
          onCancel={() => showAllSamplesSwitch.turnOff()}
        />
      )}
    </>
  );
};
