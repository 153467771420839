import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Toast, toast as toastable } from 'react-hot-toast';
import classNames from 'classnames';

export type NotificationVariant = 'success' | 'error' | 'warning';

const variantToIcon: Record<NotificationVariant, React.ReactNode> = {
  success: <FontAwesomeIcon icon={regular('check-circle')} className="h-5 w-5 text-blue-500" aria-hidden="true" />,
  error: <FontAwesomeIcon icon={regular('exclamation-circle')} className="h-5 w-5 text-red-500" aria-hidden="true" />,
  warning: (
    <FontAwesomeIcon icon={regular('triangle-exclamation')} className="h-5 w-5 text-yellow-500" aria-hidden="true" />
  ),
};

type NotificationProps = {
  title: string;
  description: string;
  variant: NotificationVariant;
  toast: Toast;
};

export const Notification: React.FC<NotificationProps> = ({ title, description, variant, toast }) => {
  return (
    <div
      className={classNames(
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        { 'animate-fade-in opacity-100': toast.visible },
        { 'animate-fade-out opacity-0': !toast.visible }
      )}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">{variantToIcon[variant]}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-semibold text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-600">{description}</p>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => toastable.dismiss(toast.id)}
            >
              <span className="sr-only">Close</span>
              <FontAwesomeIcon icon={regular('close')} className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
