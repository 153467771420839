import React from 'react';
import { useDeleteSceneMutation } from '../../../api/graphql/generated';
import { Modal } from '../../atoms/layouts/Modal';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Button } from '../../atoms/buttons/Button';
import { Text } from '../../atoms/texts/Text';

type DeleteResult = {
  isSuccess: boolean;
  blockedByTourIds: number[];
};

type DeleteSceneModalProps = {
  sceneId: number;
  sceneName: string;
  onDelete: (result: DeleteResult) => void;
  onCancel: () => void;
};

export const DeleteSceneModal: React.FC<DeleteSceneModalProps> = ({ sceneId, sceneName, onCancel, onDelete }) => {
  const deleteScene = useDeleteSceneMutation({
    onSuccess: (result) => {
      onDelete({
        isSuccess: result.DeletePanorama.result?.isSuccess ?? false,
        blockedByTourIds: result.DeletePanorama.result?.tourIds ?? [],
      });
    },
  });

  const onDeleteAction = () => {
    deleteScene.mutate({ sceneId });
  };

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            Delete scene
          </Text>
          <Text size="s" weight="normal">
            {`You are about to permanently delete scene “${sceneName}”.
                This action cannot be undone. Are you sure?`}
          </Text>
        </AlignedContent>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button label="Delete" variant="danger" action={onDeleteAction} loading={deleteScene.isLoading} />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
