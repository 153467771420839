import React from 'react';

type FloatingItemLayoutProps = React.PropsWithChildren & {
  floatingItem: React.ReactNode;
};

export const FloatingItemLayout: React.FC<FloatingItemLayoutProps> = ({ floatingItem, children }) => {
  return (
    <div className="relative">
      {children}
      <div className="absolute top-3 right-3">{floatingItem}</div>
    </div>
  );
};
