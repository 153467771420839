import React from 'react';
import { Modal } from '../atoms/layouts/Modal';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Text } from '../atoms/texts/Text';
import { Button } from '../atoms/buttons/Button';

type PromptModalVariant = 'danger' | 'primary';

type PromptModalProps = {
  variant: PromptModalVariant;
  onApprove: () => void;
  executing: boolean;
  onCancel: () => void;
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText: React.ReactNode;
  cancelOnBackgroundClick?: true;
};

export const PromptModal: React.FC<PromptModalProps> = ({
  variant,
  onApprove,
  executing,
  onCancel,
  title,
  description,
  buttonText,
  cancelOnBackgroundClick,
}) => {
  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (cancelOnBackgroundClick && event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            {title}
          </Text>
          <Text size="s" weight="normal">
            {description}
          </Text>
        </AlignedContent>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button label={buttonText} variant={variant} action={onApprove} loading={executing} />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
