import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Text } from '../../atoms/texts/Text';
import { LinkButton, LinkButtonProps } from '../../atoms/buttons/LinkButton';
import React from 'react';

type LearnHowLinkProps = {
  href: LinkButtonProps['href'];
};

export const LearnHowLink = ({ href }: LearnHowLinkProps) => {
  return (
    <LinkButton
      variant="blue"
      href={href}
      label={
        <AlignedContent direction="row" positioning="center" gap="xs">
          <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
          <Text color="default" weight="semibold" size="s">
            Learn how
          </Text>
        </AlignedContent>
      }
    />
  );
};
