import React, { useEffect } from 'react';
import { useNonValidatedUser } from '../../../plugins/auth/Authentication';
import { ReactComponent as Logo } from '../../../assets/logos/logo_only.svg';
import waves from '../../../assets/images/waves.svg';
import { LinkButton } from '../../atoms/buttons/LinkButton';
import { InTextButton } from '../../atoms/buttons/InTextButton';

export const EmptyScreen: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { logout } = useNonValidatedUser();

  // Looks strange, but this sets the gray color to the body instead of the container
  useEffect(() => {
    document.body.classList.add('bg-grey-100');
    console.log('run');
    return () => document.body.classList.remove('bg-grey-100');
  }, []);

  return (
    <div
      className="relative flex justify-center w-screen h-screen bg-no-repeat bg-grey-100"
      style={{ borderImage: waves }}
    >
      <div
        className="fixed"
        style={{
          transform: 'matrix(1.11,-0.67,-0.41,-0.69,0,0)',
          opacity: 0.4,
          height: '1166px',
          left: '0%',
          top: '14%',
          overflow: 'hidden',
        }}
      >
        <img className="w-full h-full" src={waves} />
      </div>
      <div className="relative z-10 flex flex-col items-center w-full h-full max-w-md ">
        <div className="w-full p-6 mt-auto mb-auto bg-white rounded-lg shadow-xl sm:p-10">{children}</div>
        <div className="flex justify-center w-full gap-6 p-2 mb-0">
          <LinkButton href="mailto:support@panomio.com" label="Contact" />
          <Logo className="w-8 h-8" />
          <InTextButton action={logout} label="Log out" />
        </div>
      </div>
    </div>
  );
};
