import React, { useMemo } from 'react';
import { TableHead } from '../../atoms/table/TableHead';
import { TableRow } from '../../atoms/table/TableRow';
import { TableHeader } from '../../atoms/table/TableHeader';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableBody } from '../../atoms/table/TableBody';
import { TableData } from '../../atoms/table/TableData';
import { Table } from '../../atoms/table/Table';
import { Role } from '../../../api/graphql/generated';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';

export type UserTableData = {
  id: number;
  name: string;
  email: string;
  role: Role;
};

export type ActionItemProps = {
  user: UserTableData;
};

type Column = 'Name' | 'Email' | 'Role';

type UserTableProps = {
  data: UserTableData[];
  excludedColumns?: Column[];
  ActionItem: React.FC<ActionItemProps>;
};

const RoleToPresentationString: Record<Role, string> = {
  [Role.Author]: 'Author',
  [Role.Owner]: 'Owner',
};

const columnHelper = createColumnHelper<UserTableData>();

export const UserTable: React.FC<UserTableProps> = ({ data, excludedColumns = [], ActionItem }): JSX.Element => {
  const columns = useMemo(
    () => [
      ...(excludedColumns.includes('Name')
        ? []
        : [
            columnHelper.accessor('name', {
              header: 'Name',
            }),
          ]),
      ...(excludedColumns.includes('Email')
        ? []
        : [
            columnHelper.accessor('email', {
              header: () => 'Email',
            }),
          ]),
      ...(excludedColumns.includes('Role')
        ? []
        : [
            columnHelper.accessor('role', {
              header: 'Role',
              cell: (data) => RoleToPresentationString[data.getValue()],
            }),
          ]),
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: '',
        cell: (data) => (
          <AlignedContent direction="row" placing="fromEnd">
            <ActionItem user={data.row.original} />
          </AlignedContent>
        ),
      }),
    ],
    [ActionItem, excludedColumns]
  );

  const userTable = useReactTable<UserTableData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHead>
        {userTable.getHeaderGroups().map((headGroup) => (
          <TableRow key={headGroup.id}>
            {headGroup.headers.map((header) => (
              <TableHeader key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {userTable.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableData key={cell.id}> {flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
