import React from 'react';

type LayoutWithSubmenuProps = {
  children: React.ReactNode;
};

export const LayoutWithSubmenu = ({ children }: LayoutWithSubmenuProps) => (
  <div className="w-full">
    <div className="flex flex-col lg:flex-row">{children}</div>
  </div>
);
