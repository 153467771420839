import React from 'react';
import classnames from 'classnames';

type CenteredContentProps = {
  tryGoldenCenter?: true;
  children: React.ReactNode;
};

export const Centered: React.FC<CenteredContentProps> = ({ tryGoldenCenter, children }) => {
  return (
    <div className={classnames('flex grow justify-center items-center', { 'mb-[10vh]': tryGoldenCenter })}>
      <div className="max-w-max m-auto">{children}</div>
    </div>
  );
};
