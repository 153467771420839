import React, { PropsWithChildren } from 'react';

export const SettingDescriptionListTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="sm:flex sm:items-center mb-5">
      <div className="sm:flex-auto">
        <h2 className="text-m font-semibold text-gray-900">{children}</h2>
      </div>
    </div>
  );
};
