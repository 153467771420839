import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FetchMeQuery, useFetchMeQuery } from '../../api/graphql/generated';
import gql from 'graphql-tag';
import { DEFAULT_ME, Me, MeContext } from './MeContext';
import { WithRequiredProperty } from '../../utils/WithRequiredProperty';

export const MeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [me, setMe] = useState<WithRequiredProperty<FetchMeQuery, 'me'>['me']>(DEFAULT_ME);
  const meQuery = useFetchMeQuery(undefined, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (meQuery.data?.me) {
      setMe(meQuery.data.me);
    }
  }, [meQuery.data]);

  const provided: Me = {
    me,
    isLoaded: meQuery.isSuccess,
    refetchMe: () => {
      meQuery.refetch().catch((e) => console.log(e));
    },
  };

  return <MeContext.Provider value={provided}>{children}</MeContext.Provider>;
};

gql`
  query fetchMe {
    me {
      id
      name {
        firstName
        lastName
      }
      email
      role
      inviteState
      organization {
        id
        name
      }
    }
  }
`;
