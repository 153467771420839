import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type NewProjectLogo = {
  projectId: number;
  logo?: File;
};

type UploadLogoOptions = Omit<UseMutationOptions<void, unknown, NewProjectLogo>, 'mutationFn'>;

export const useUploadLogoMutation = (options?: UploadLogoOptions) => {
  const { post } = useFetch();
  return useMutation({
    ...options,
    mutationFn: async (newProjectLogo: NewProjectLogo) => {
      const formData = new FormData();
      formData.append('ProjectId', newProjectLogo.projectId.toString());

      if (newProjectLogo.logo) {
        formData.append('FormFile', newProjectLogo.logo);
      }

      const responseMe = await post<void, FormData>('/api/project/upload-logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return responseMe.data;
    },
  });
};
