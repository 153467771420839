import React from 'react';
import { useParams } from 'react-router-dom';
import { PROJECT_ID_URL_SLUG } from '../routing/constants';

/**
 * Base props for Project pages for injecting the current project id
 **/
export type ProjectPageProps = {
  projectId: number;
};

type UrlParametersToExtract = typeof PROJECT_ID_URL_SLUG;

/**
 * Decorates given component (Page) with the current project id from the router.
 **/
export function withProjectId<ComponentProps>(
  Component: React.ComponentType<ComponentProps & ProjectPageProps>
): React.FC<ComponentProps> {
  return (props: ComponentProps) => {
    const { project_id } = useParams<UrlParametersToExtract>();

    if (!project_id) {
      throw new Error('project Id not set in URL path');
    }

    const projectId: number = parseInt(project_id ?? '', 10);
    return <Component {...props} projectId={projectId} />;
  };
}
