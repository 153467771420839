import React from "react";

type VisibilityVariant = 'sm' | '>sm';

type BoxProps = {
    visibleFor: VisibilityVariant
    children: React.ReactNode
}

const variantToClassname: Record<VisibilityVariant, string> = {
    'sm': 'sm:hidden',
    '>sm': 'hidden sm:block'
};

export const Box: React.FC<BoxProps> = ({visibleFor, children}) => {
    return <div className={variantToClassname[visibleFor]} >{children}</div>
}