import React from 'react';
import classnames from 'classnames';

type SpaceProps = {
  size: Size;
};

type Size = 'xxxl';
const SizeToClassname: Record<Size, string> = {
  xxxl: 'h-16',
};

export const Space: React.FC<SpaceProps> = ({ size }) => {
  return <div className={classnames('w-full ', SizeToClassname[size])} />;
};
