import React from 'react';

type ImageListItemDescriptionProps = {
  text: string;
};

export const ImageListItemDescription: React.FC<ImageListItemDescriptionProps> = ({ text }) => {
  return (
    <p className="mt-2 block text-base text-left font-semibold text-grey-800 truncate pointer-events-none">{text}</p>
  );
};
