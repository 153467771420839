import React from 'react';
import classNames from 'classnames';
import { TextSize, TextSizeToClassname } from '../texts/Text';

type Variant = 'default' | 'blue';

const VariantToClassname: Record<Variant, string> = {
  default: 'font-semibold text-grey-900 hover:text-grey-600 my-auto ',
  blue: 'text-blue-500 hover:text-blue-600',
};

export type LinkButtonProps = {
  variant?: Variant;
  textSize?: TextSize;
  label: React.ReactNode;
  href: string;
};

export const LinkButton: React.FC<LinkButtonProps> = ({ variant = 'default', textSize = 's', label, href }) => {
  return (
    <a className={classNames(VariantToClassname[variant], TextSizeToClassname[textSize])} href={href}>
      {label}
    </a>
  );
};
