import React, { PropsWithChildren } from 'react';
import { SettingDescriptionListRow } from './SettingDescriptionListRow';
import { SettingDescriptionListTitle } from './SettingDescriptionListTitle';

type SettingDescriptionListContent = {
  Row: typeof SettingDescriptionListRow;
  Title: typeof SettingDescriptionListTitle;
};

export const SettingDescriptionList: React.FC<PropsWithChildren> & SettingDescriptionListContent = ({ children }) => {
  return (
    <div className="mt-6">
      <dl className="divide-y divide-gray-200">{children}</dl>
    </div>
  );
};

SettingDescriptionList.Row = SettingDescriptionListRow;
SettingDescriptionList.Title = SettingDescriptionListTitle;
