import React, { useState } from 'react';

import { Modal } from '../atoms/layouts/Modal';
import { Label } from '../atoms/inputs/label/Label';
import { LabelOnTopLeftBox } from '../atoms/inputs/layouts/LabelOnTopLeftBox';
import { Button } from '../atoms/buttons/Button';
import { Text } from '../atoms/texts/Text';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { useEditTeamMateMutation } from '../../repositories/team/EditTeamMateMutation';
import { Role } from '../../api/graphql/generated';
import { RoleRadioGroup } from './RoleRadioGroup';

type ChangeRoleModalProps = {
  title: string;
  defaultRole: Role;
  userId: number;
  onUpdate: () => void;
  onCancel: () => void;
};

export const ChangeRoleModal: React.FC<ChangeRoleModalProps> = ({ userId, defaultRole, title, onCancel, onUpdate }) => {
  const [role, setRole] = useState<Role>(defaultRole);
  const changeRoleMutation = useEditTeamMateMutation();

  const onUpdateAction = () => {
    if (!role) {
      return;
    }
    changeRoleMutation
      .mutateAsync({ userId, role, marketingConsent: null, invitationAccepted: null, name: null })
      .then(() => onUpdate())
      .catch((reason) => {
        throw reason;
      });
  };

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
    >
      <AlignedContent direction="column" gap="s">
        <Text size="m" weight="semibold">
          {title}
        </Text>
        <AlignedContent direction="column" gap="m">
          <LabelOnTopLeftBox>
            <Label htmlFor="scene">Role</Label>
            <RoleRadioGroup preselected={defaultRole} onChange={setRole} />
          </LabelOnTopLeftBox>
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button
              label="Update"
              action={onUpdateAction}
              loading={changeRoleMutation.isLoading}
              disabled={role === defaultRole}
            />
          </AlignedContent>
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
