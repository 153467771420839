import React, { useCallback } from 'react';
import { SubscriptionTier } from '../../api/graphql/generated';
import { useUser } from '../../plugins/auth/Authentication';
import { Button } from '../atoms/buttons/Button';
import { Text } from '../atoms/texts/Text';
import { Tier } from '../templates/pricing/PricingTemplate';

interface SubscriptionButtonProps {
  tier: Tier;
  isActive: boolean;
  monthlyActive: boolean;
  action: (lookupKey: string) => void;
}

export function SubscriptionButton({ tier, isActive, monthlyActive, action }: SubscriptionButtonProps) {
  const { user } = useUser();

  const onButtonClick = useCallback(
    (lookupKey: string) => {
      action(lookupKey);
    },
    [action]
  );

  if (isActive) {
    return (
      <div className="mb-2 text-blue-500">
        <Text size="s" weight="semibold">
          Current plan
        </Text>
      </div>
    );
  }

  const monthlyPriceId = tier.monthlyPriceId;
  const yearlyPriceId = tier.yearlyPriceId;

  if (!(monthlyPriceId && yearlyPriceId)) {
    if (tier.type === SubscriptionTier.Free) {
      return <Button label="Downgrade to Free" action={() => onButtonClick('free')} />;
    }
    return (
      <Button
        label="Contact us"
        action={() =>
          window.open(`https://share.hsforms.com/1IWYsgqyhSuiMfu6Ao_KQ4w2wtil?email=${user.email}`, '_blank')
        }
      />
    );
  }

  return (
    <Button
      label={`Get ${tier.name}`}
      action={() => (monthlyActive ? onButtonClick(monthlyPriceId) : onButtonClick(yearlyPriceId))}
      variant="primary"
    />
  );
}
