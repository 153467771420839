import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';
import { FileList } from '../lists/file/FileList';
import { bytesToSizeString } from '../../../utils/bytesConverter';
import { TextSpan } from '../texts/TextSpan';
import { Accept as AcceptDropZone, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import * as uuid from 'uuid';
import { AlignedContent } from '../layouts/AlignedContent';
import { Text } from '../texts/Text';

type FilesPickerFile = {
  id: string;
  file: File;
};

type TypeDescription = string;
type MIMEType = string;
type Accept = {
  [key: MIMEType]: TypeDescription;
};

export type FilePickerProps = {
  id: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>['id'];
  maxFileSize: number;
  acceptFileType: Accept;
  onlyOneFile?: true;
  onChange: (files: File[]) => void;
  onFilesRejected?: (rejectedFiles: FileRejection[]) => void;
};

export const FilePicker: React.FC<FilePickerProps> = ({
  id,
  maxFileSize,
  acceptFileType,
  onlyOneFile,
  onChange,
  onFilesRejected,
}) => {
  const [files, setFiles] = useState<FilesPickerFile[]>([]);
  const disabled = onlyOneFile && files.length > 0;

  const acceptForDropZone = Object.keys(acceptFileType).reduce<AcceptDropZone>((toAccept, mimeType): AcceptDropZone => {
    toAccept[mimeType] = [];
    return toAccept;
  }, {});

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptForDropZone,
    maxSize: maxFileSize,
    multiple: !onlyOneFile,
    disabled,
    onDropAccepted: (acceptedFiles) => {
      onChange(acceptedFiles);
      setFiles(
        acceptedFiles.map((acceptedFile) => ({
          id: uuid.v4(),
          file: acceptedFile,
        }))
      );
    },
    onDropRejected: onFilesRejected,
  });

  const removeFile = (id: string) => {
    const filteredFiles = files.filter((file) => file.id !== id);
    setFiles(filteredFiles);
    onChange(filteredFiles.map((file) => file.file));
  };

  const rejections: Record<ErrorCode, string> = {
    'file-invalid-type': 'Uploaded file is of invalid type',
    'file-too-large': `Uploaded file cannot be larger than ${bytesToSizeString(maxFileSize)}`,
    'file-too-small': 'Uploaded file cannot be so small',
    'too-many-files': 'Too many files picked',
  };

  const acceptedFilesText = Object.values(acceptFileType).reduce<string>(
    (typeString, description, currentIndex, descriptions) => {
      if (currentIndex === 0) {
        return description;
      }

      const isLastIteration = currentIndex === descriptions.length - 1;
      return !isLastIteration ? `${typeString}, ${description}` : `${typeString} or ${description}`;
    },
    ''
  );
  return (
    <div className="mt-1 sm:col-span-2 sm:mt-0">
      <div {...getRootProps()} className={classNames('rounded-md font-medium')}>
        <div
          className={classNames(
            'group/filepicker flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300  duration-200 px-6 pt-5 pb-6',
            disabled ? 'bg-grey-200' : 'cursor-pointer bg-white hover:border-blue-500',
            { 'border-blue-500': isDragActive }
          )}
        >
          <div className="space-y-1 text-center">
            <FontAwesomeIcon
              className={classNames('h-6', disabled ? 'text-grey-400' : 'text-blue-500')}
              icon={solid('upload')}
            />
            <div
              className={classNames(
                'flex text-sm flex-col',
                disabled ? 'text-grey-400' : 'group-hover/filepicker:text-blue-500',
                isDragActive ? 'text-blue-500' : 'text-grey-900 '
              )}
            >
              <span className="duration-200">
                <p>
                  <TextSpan size="s" weight={'semibold'} align="center">
                    Click to upload
                  </TextSpan>
                  <TextSpan size="s" weight={'normal'} align="center">
                    {' '}
                    or drag and drop
                  </TextSpan>
                </p>
              </span>
              <input {...getInputProps()} id={id} name="file-upload" className="sr-only" />
            </div>
            <TextSpan
              size="xs"
              align="center"
              color={disabled ? 'disabled' : 'grey'}
            >{`${acceptedFilesText} (max. size ${bytesToSizeString(maxFileSize)})`}</TextSpan>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <FileList>
          {files.map((file) => (
            <FileList.UploadItem key={file.id} file={file.file} id={file.id} onRemove={removeFile} />
          ))}
        </FileList>
      )}
      {fileRejections.length > 0 && (
        <FileList>
          {fileRejections.map((rejectedFile, index) => (
            <Text key={index} color="danger" size="s" weight="semibold" align="left">
              <AlignedContent direction="row" gap="xs" positioning="center">
                <Text size="base">
                  <FontAwesomeIcon icon={solid('triangle-exclamation')} />
                </Text>
                {rejections[rejectedFile.errors[0].code as ErrorCode]}
              </AlignedContent>
            </Text>
          ))}
        </FileList>
      )}
    </div>
  );
};
