import { Modal } from '../../atoms/layouts/Modal';
import { Button } from '../../atoms/buttons/Button';
import React, { useState } from 'react';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Text } from '../../atoms/texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export enum ProjectSource {
  FROM_SCRATCH = 'from-scratch',
  SAMPLE = 'sample',
}

export type TypeSelectionFunction = {
  (type: ProjectSource): void;
  isLoading: boolean;
};

type CreateProjectCrossroadsModalProps = {
  defaultSource?: ProjectSource;
  onTypeSelected: TypeSelectionFunction;
  onCancel: () => void;
};

export const CreateProjectCrossroadsModal = ({
  defaultSource,
  onTypeSelected,
  onCancel,
}: CreateProjectCrossroadsModalProps) => {
  const [source, setSource] = useState<ProjectSource | undefined>(defaultSource);

  return (
    <Modal
      size="l"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <Text size="m" weight="semibold">
          Create new course
        </Text>

        <AlignedContent direction="row" gap="s">
          <Button
            variant="selectable"
            size="full-xl-padding"
            label={
              <AlignedContent direction="column" gap="s" positioning="center">
                <FontAwesomeIcon className="w-8 h-8 text-blue-500" icon={regular('table-layout')} />
                <Text weight="semibold" size="s" align="center">
                  Start from scratch
                </Text>
              </AlignedContent>
            }
            action={() => {
              if (source !== ProjectSource.FROM_SCRATCH) {
                setSource(ProjectSource.FROM_SCRATCH);
              }
            }}
            disabled={source === ProjectSource.FROM_SCRATCH}
          />
          <Button
            variant="selectable"
            size="full-xl-padding"
            label={
              <AlignedContent direction="column" gap="s" positioning="center">
                <FontAwesomeIcon className="w-8 h-8 text-blue-500" icon={regular('objects-column')} />
                <Text weight="semibold" size="s" align="center">
                  Choose template
                </Text>
              </AlignedContent>
            }
            action={() => {
              if (source !== ProjectSource.SAMPLE) {
                setSource(ProjectSource.SAMPLE);
              }
            }}
            disabled={source === ProjectSource.SAMPLE}
          />
        </AlignedContent>

        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button
            label={source && source === 'from-scratch' ? 'Create' : 'Continue'}
            action={() => {
              if (source) {
                onTypeSelected(source);
              }
            }}
              loading={onTypeSelected.isLoading}
            disabled={!source}
          />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
