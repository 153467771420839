import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FileListItemProps = {
  icon: IconProp;
  filename: string;
  button: React.ReactNode;
};

export const FilesListItem: React.FC<FileListItemProps> = ({ icon, filename, button }) => {
  return (
    <li className="border border-solid border-grey-350 rounded p-2">
      <FontAwesomeIcon className="text-blue-500 h-4 ml-3" icon={icon} />
      <span className="text-sm font-semibold ml-3.5">{filename}</span>
      {button}
    </li>
  );
};
