import { useCallback, useEffect, useRef, useState } from 'react';
import { CreateTypes, Options } from 'canvas-confetti';

type useFireworksConfettiProps = {
  explosionCount: number;
  delayBetweenExplosionsInMilliseconds: number;
};

export const useFireworksConfetti = ({
  explosionCount,
  delayBetweenExplosionsInMilliseconds,
}: useFireworksConfettiProps) => {
  const refAnimationInstance = useRef<CreateTypes | null>(null);
  const [intervalId, setIntervalId] = useState<number | undefined>();

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: number, opts: Options) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    const origin: Options['origin'] = {
      x: randomInRange(0.3, 0.7),
      y: randomInRange(0.3, 0.7),
    };

    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
      origin,
    });

    makeShot(0.2, {
      spread: 60,
      origin,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
      origin,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
      origin,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
      origin,
    });
  }, [makeShot]);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(window.setInterval(fire, delayBetweenExplosionsInMilliseconds));
    }
  }, [intervalId, fire, delayBetweenExplosionsInMilliseconds]);

  const pauseAnimation = useCallback(() => {
    window.clearInterval(intervalId);
    setIntervalId(undefined);
  }, [intervalId]);

  useEffect(() => {
    if (intervalId) {
      setTimeout(pauseAnimation, explosionCount * delayBetweenExplosionsInMilliseconds);
    }
  }, [pauseAnimation, intervalId, explosionCount, delayBetweenExplosionsInMilliseconds]);

  useEffect(() => {
    return () => {
      window.clearInterval(intervalId);
    };
  }, [intervalId]);

  return {
    confettiRef: getInstance,
    startAnimation: startAnimation,
  };
};

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}
