import React, { useEffect } from 'react';
import { EmptyScreen } from '../components/molecules/layouts/EmptyScreen';
import { AlignedContent } from '../components/atoms/layouts/AlignedContent';
import { Text } from '../components/atoms/texts/Text';
import Inbox from '../assets/images/inbox.png';
import { TextSpan } from '../components/atoms/texts/TextSpan';
import { InTextButton } from '../components/atoms/buttons/InTextButton';
import { useResendVerifyEmail } from '../repositories/me/ResendVerifyEmail';

type VerifyEmailPageProps = {
  email: string;
  onFocus: () => void;
};

export const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({ email, onFocus }) => {
  const resendVerifyEmail = useResendVerifyEmail();

  const resendVerification = () => {
    resendVerifyEmail.mutateAsync(email).catch((e) => {
      throw e;
    });
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);

  return (
    <EmptyScreen>
      <AlignedContent direction="column" gap="m" positioning="center">
        <img src={Inbox} alt="inbox" height="122" width="215" />
        <Text size="m" weight="semibold">
          Check your inbox
        </Text>
        <p className="text-base font-normal text-center">
          A confirmation email has been sent to{' '}
          <TextSpan size="base" weight="semibold">
            {email}
          </TextSpan>
          <br />
          Please check your inbox to complete your registration.
        </p>
        <AlignedContent direction="column" gap="xs" positioning="center">
          <Text size="base" weight="semibold" align="center">
            Can’t find the message?
          </Text>
          <Text size="base" weight="normal" align="center">
            Check the spam or{' '}
            <InTextButton
              variant="blue"
              size="base"
              label={'resend email'}
              action={resendVerification}
              disabled={resendVerifyEmail.isLoading}
            />
            .
          </Text>
        </AlignedContent>
      </AlignedContent>
    </EmptyScreen>
  );
};
