import React from 'react';
import { ImageList } from '../../components/atoms/lists/ImageList';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export const ImageListExample: React.FC = () => {
  const items: { image: string; description: string }[] = [
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
    {
      image:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
      description: 'Citation Latitude',
    },
  ];

  return (
    <div className="px-4 py-12 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <ImageList>
        {items.map((item, idx) => (
          <ImageList.Item
            placeholderIcon={regular('panorama')}
            key={idx}
            imageSrc={item.image}
            screenReaderLabel={`View details for ${item.description}`}
            action={() => alert('there we go')}
          >
            <ImageList.Item.Description text={item.description} />
          </ImageList.Item>
        ))}
      </ImageList>
    </div>
  );
};
