import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import classNames from 'classnames';
import { MODAL_PORTAL_ID } from '../portals/ModalPortal';

type Size = 'l' | 'xxl' | 'fit' | '75%' | '4xl';

const SizeToClassname: Record<Size, string> = {
  l: 'sm:max-w-lg',
  xxl: 'sm:max-w-2xl',
  '4xl': 'sm:max-w-4xl h-full',
  fit: 'sm:max-w-fit h-fit',
  '75%': 'lg:max-w-screen-xl w-full',
};

type ModalPadding = 'm' | 'l' | 'xl';

const ModalPaddingToClassname: Record<ModalPadding, string> = {
  m: 'px-4 pt-5 pb-4 sm:p-6',
  l: 'px-8 pt-8 pb-6',
  xl: 'px-12 py-16',
};

type ModalProps = React.PropsWithChildren & {
  size?: Size;
  padding?: ModalPadding;
  backdrop?: boolean;
  onBackgroundClick?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>['onClick'];
};

export const Modal: React.FC<ModalProps> = ({ size = 'l', padding = 'm', backdrop, onBackgroundClick, children }) => {
  const modalPortal = document.getElementById(MODAL_PORTAL_ID);

  useEffect(() => {
    if (modalPortal && backdrop) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'unset';
      };
    }
    return;
  }, [backdrop, modalPortal]);

  if (!modalPortal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      onClick={onBackgroundClick}
      className={classNames('fixed inset-0 w-screen overflow-y-auto z-modal', {
        'bg-grey-900/70 pointer-events-auto': backdrop,
      })}
    >
      <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-8">
        <div
          className={classnames(
            'relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl pointer-events-auto sm:p-6',
            SizeToClassname[size],
            ModalPaddingToClassname[padding]
          )}
        >
          {children}
        </div>
      </div>
    </div>,
    modalPortal
  );
};
