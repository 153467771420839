import React from 'react';

import { SettingsPage } from '../settings/Settings';
import { DropDownExample } from './DropDownExample';
import { HeaderSectionExampleWithTabsChangingToSelectForSmallScreens } from './HeaderSectionExampleWithTabsChangingToSelectForSmallScreens';
import { ImageListExample } from './ImageListExample';
import { SelectMenuExample } from './SelectMenuExample';

export default function ComponentsExample() {
  return (
    <div className="flex flex-col">
      <SettingsPage />
      <HeaderSectionExampleWithTabsChangingToSelectForSmallScreens />
      <SelectMenuExample />
      <ImageListExample />
      <DropDownExample />
    </div>
  );
}
