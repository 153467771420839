import React from 'react';
import { TextSize, TextSizeToClassname } from '../../texts/Text';
import classnames from 'classnames';

type LabelProps = Exclude<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
  'classname'
> & {
  size?: TextSize;
};

export const Label: React.FC<LabelProps> = ({ size = 'base', ...props }) => {
  return <label className={classnames('block font-semibold text-gray-800', TextSizeToClassname[size])} {...props} />;
};
