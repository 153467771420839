import React from 'react';
import { H3, HeadingProps } from '../../atoms/texts/H3';
import { TextWithActionsLayout } from './TextWithActionsLayout';

type HeadingWithActionsProps = React.PropsWithChildren & {
  title: HeadingProps['children'];
};

export const H3WithActionsContainer: React.FC<HeadingWithActionsProps> = ({ title, children }) => {
  return <TextWithActionsLayout title={<H3>{title}</H3>}>{children}</TextWithActionsLayout>;
};
