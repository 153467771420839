import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, To } from 'react-router-dom';

type SubmenuItemVariants = 'unselected' | 'selected' | 'disabled-pro';
export type SubmenuItemProps = {
  label: string;
  description: string;
  icon: IconProp;
  to: To;
  isDisabledPro?: boolean;
};

const variantDefinitionBox: Record<SubmenuItemVariants, string> = {
  unselected: 'hover:bg-grey-300',
  selected: 'bg-blue-200',
  'disabled-pro': 'cursor-default',
};

const variantDefinitionIcon: Record<SubmenuItemVariants, string> = {
  unselected: 'text-grey-500',
  selected: 'text-blue-400',
  'disabled-pro': 'text-grey-500 opacity-50',
};

const variantDefinitionLabel: Record<SubmenuItemVariants, string> = {
  unselected: 'text-gray-800',
  selected: 'text-blue-500',
  'disabled-pro': 'text-gray-80 opacity-50',
};

const variantDefinitionDescription: Record<SubmenuItemVariants, string> = {
  unselected: 'text-grey-500',
  selected: 'text-blue-400',
  'disabled-pro': 'text-grey-500 opacity-50',
};

export const SubmenuItem: React.FC<SubmenuItemProps> = ({ label, description, icon, to, isDisabledPro }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          variantDefinitionBox[isDisabledPro ? 'disabled-pro' : isActive ? 'selected' : 'unselected'],
          'flex pt-6 pl-7 pr-6 pb-5 border-b-2 border-gray-300'
        )
      }
      onClick={(event) => {
        if (isDisabledPro) {
          event.preventDefault();
        }
      }}
      end
    >
      {({ isActive }) => (
        <>
          <FontAwesomeIcon
            icon={icon}
            className={classNames(
              variantDefinitionIcon[isDisabledPro ? 'disabled-pro' : isActive ? 'selected' : 'unselected'],
              'flex-shrink-0 -mt-0.5 h-6 w-6'
            )}
            aria-hidden="true"
          />
          <div className="ml-3 text-sm flex flex-col justify-items-start">
            <div className="flex space-x-2">
              <p
                className={classNames(
                  variantDefinitionLabel[isDisabledPro ? 'disabled-pro' : isActive ? 'selected' : 'unselected'],
                  'font-semibold text-base text-left'
                )}
              >
                {label}
              </p>
              {isDisabledPro && (
                <div className="w-fit font-semibold bg-grey-300 border-r-2 px-2 py-0.5 text-sm text-blue-500 opacity-100">
                  PRO
                </div>
              )}
            </div>
            <p
              className={classNames(
                [variantDefinitionDescription[isDisabledPro ? 'disabled-pro' : isActive ? 'selected' : 'unselected']],
                'mt-1 text-sm text-left'
              )}
            >
              {description}
            </p>
          </div>
        </>
      )}
    </NavLink>
  );
};
