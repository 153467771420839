import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, ButtonAction } from '../buttons/Button';
import classNames from 'classnames';

type Variant = 'blue' | 'grey';

type BannerProps = {
  icon: IconProp;
  text: React.ReactNode;
  button?: ButtonAction;
  variant?: Variant;
};

export const Banner: React.FC<BannerProps> = ({ icon, text, button, variant = 'blue' }) => {
  const variantToClassnames: Record<Variant, string> = {
    blue: 'bg-blue-200 border-blue-300 text-blue-500',
    grey: 'bg-grey-200 border-grey-300 text-grey-650',
  };

  return (
    <div
      className={classNames(
        'flex row gap-3 items-center border border-solid rounded text-base font-medium p-3 mb-4',
        variantToClassnames[variant]
      )}
    >
      <FontAwesomeIcon className="h-5" icon={icon} />
      {text}
      {button && (
        <div className="ml-auto">
          <Button label={button.label} action={button.action} />
        </div>
      )}
    </div>
  );
};
