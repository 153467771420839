import React from 'react';
import { Divider } from '../../components/atoms/divider/Divider';
import { AlignedContent } from '../../components/atoms/layouts/AlignedContent';
import { MenuContentContainer } from '../../components/atoms/layouts/MenuContentContainer';
import { SectionHeader } from '../../components/organisms/SectionHeader';
import { SubscriptionTemplate } from '../../components/templates/settings/SubscriptionTemplate';

export const Billing: React.FC = () => {
  return (
    <MenuContentContainer>
      <SectionHeader>
        <SectionHeader.Title>Subscription &amp; Billing </SectionHeader.Title>
        <SectionHeader.Subtitle>Change your tier, add seats or manage your billing information.</SectionHeader.Subtitle>
      </SectionHeader>
      <Divider />
      <AlignedContent direction="column" gap="m">
        <SubscriptionTemplate />
      </AlignedContent>
    </MenuContentContainer>
  );
};
