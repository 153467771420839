import React, { useState } from 'react';
import gql from 'graphql-tag';
import { FetchUsersQuery, InvitationState, Role, useFetchUsersQuery } from '../../api/graphql/generated';
import { Centered } from '../../components/atoms/layouts/Centered';
import { Loader } from '../../components/molecules/loader/Loader';
import { AlignedContent } from '../../components/atoms/layouts/AlignedContent';
import { MenuContentContainer } from '../../components/atoms/layouts/MenuContentContainer';
import { SectionHeader } from '../../components/organisms/SectionHeader';
import { Divider } from '../../components/atoms/divider/Divider';
import { Space } from '../../components/atoms/divider/Space';
import { UserTableData, UserTable } from '../../components/templates/Team/UserTable';
import { Button } from '../../components/atoms/buttons/Button';
import { DropDown } from '../../components/molecules/dropDown/DropDown';
import { Text } from '../../components/atoms/texts/Text';
import { TextWithActionsLayout } from '../../components/molecules/layouts/TextWithActionsLayout';
import { useMe } from '../../contexts/me/MeConsumer';
import { useActivePlan } from '../../contexts/activePlan/ActivePlanCosumer';
import { InviteUserModal } from '../../components/organisms/InviteUserModal';
import { useSwitch } from '../../hooks/useSwitch';
import { AddSeatsModal } from '../../components/organisms/AddSeatsModal';
import { PromptModal } from '../../components/organisms/PromptModal';
import { useRemoveTeamMateMutation } from '../../repositories/team/RemoveTeamMateMutation';
import { ChangeRoleModal } from '../../components/organisms/ChangeRoleModal';
import { isOwnerRole } from '../../utils/isOwnerRole';

type ModalSwitch = Omit<UserTableData, 'id'> & {
  isOn: boolean;
  userId: number | null;
};

const NULL_USER: ModalSwitch = {
  isOn: false,
  userId: null,
  email: '',
  name: '',
  role: Role.Author,
};

export const Team: React.FC = () => {
  const plan = useActivePlan();
  const me = useMe(true);
  const users = useFetchUsersQuery({ organizationId: me.me.organization.id }, { refetchOnWindowFocus: false });
  const inviteUserModal = useSwitch(false);
  const addSeatsModal = useSwitch(false);
  const removeCollaboratorMutation = useRemoveTeamMateMutation();
  const [revokeInvitationModal, setRevokeInvitationModal] = useState<ModalSwitch>(NULL_USER);
  const [removeCollaboratorModal, setRemoveCollaboratorModal] = useState<ModalSwitch>(NULL_USER);
  const [changeRoleModal, setChangeRoleModal] = useState<ModalSwitch>(NULL_USER);

  const allUsers = users.data?.usersByOrganizationId;
  const pendingUsers = allUsers?.filter((user) => user.inviteState === InvitationState.Pending) ?? [];
  const collaborators = allUsers?.filter((user) => user.inviteState === InvitationState.Accepted) ?? [];

  const emptySeats = plan.activePlan.seats - collaborators.length - pendingUsers.length;

  const inviteNewUser = () => (emptySeats > 0 ? inviteUserModal.turnOn() : addSeatsModal.turnOn());

  return (
    <MenuContentContainer>
      <SectionHeader>
        <SectionHeader.Title>Team</SectionHeader.Title>
      </SectionHeader>
      <Divider />
      <Space size="xxxl" />
      {users.isLoading && (
        <Centered tryGoldenCenter>
          <Loader label="Loading your teammates..." />
        </Centered>
      )}
      {!users.isLoading && (
        <>
          {pendingUsers.length > 0 && (
            <>
              <AlignedContent direction="column" gap="m">
                <Text weight="semibold" size="base">
                  Pending invites
                </Text>
                <UserTable
                  excludedColumns={['Name']}
                  data={convertUsersToTableData(pendingUsers)}
                  ActionItem={({ user }) => (
                    <Button
                      variant="text"
                      size="l"
                      label={
                        <Text color={isOwnerRole(me.me.role) ? 'danger' : 'disabled'} weight="semibold">
                          Remove
                        </Text>
                      }
                      action={() => setRevokeInvitationModal({ isOn: true, userId: user.id, ...user })}
                      disabled={!isOwnerRole(me.me.role)}
                    />
                  )}
                />
              </AlignedContent>
              <Space size="xxxl" />
            </>
          )}

          <AlignedContent direction="column" gap="s">
            <TextWithActionsLayout
              title={
                <Text weight="semibold" size="base">
                  Collaborators
                </Text>
              }
            >
              <AlignedContent direction="row" gap="s" positioning="center">
                {emptySeats > 0 && (
                  <Text weight="semibold" size="base" color="blue" align="center">
                    {emptySeats} {emptySeats > 1 ? 'seats' : 'seat'} available
                  </Text>
                )}
                <Button label="Invite new" action={inviteNewUser} disabled={!isOwnerRole(me.me.role)} />
              </AlignedContent>
            </TextWithActionsLayout>
            <UserTable
              data={convertUsersToTableData(collaborators)}
              ActionItem={({ user }) => (
                <DropDown
                  menuAlignment="right"
                  button={
                    <DropDown.Button
                      variant="text"
                      size="l"
                      disabled={me.me.id === user.id || !isOwnerRole(me.me.role)}
                    >
                      Edit
                    </DropDown.Button>
                  }
                >
                  <DropDown.Option
                    label="Change role"
                    action={() => setChangeRoleModal({ isOn: true, userId: user.id, ...user })}
                  />
                  <DropDown.Option
                    variant="danger"
                    label="Remove"
                    action={() => setRemoveCollaboratorModal({ isOn: true, userId: user.id, ...user })}
                  />
                </DropDown>
              )}
            />
          </AlignedContent>
        </>
      )}

      {inviteUserModal.isOn && (
        <InviteUserModal
          existingEmails={allUsers?.map((user) => user.email)}
          onCreate={() => {
            inviteUserModal.turnOff();
            users.refetch().catch((error) => {
              throw error;
            });
          }}
          onCancel={() => inviteUserModal.turnOff()}
        />
      )}
      {addSeatsModal.isOn && (
        <AddSeatsModal
          onConfirm={() => {
            addSeatsModal.turnOff();
          }}
          onCancel={addSeatsModal.turnOff}
        />
      )}
      {revokeInvitationModal.isOn && (
        <PromptModal
          variant="danger"
          title="Remove invitation"
          description="By cancelling an invite, the invitee will no longer be able to sign into this organization."
          buttonText="Remove"
          onApprove={() => {
            if (revokeInvitationModal.userId) {
              removeCollaboratorMutation
                .mutateAsync({ userId: revokeInvitationModal.userId })
                .then(() => {
                  setRevokeInvitationModal(NULL_USER);
                  users.refetch().catch((error) => {
                    throw error;
                  });
                })
                .catch((e) => {
                  throw e;
                });
            }
          }}
          executing={removeCollaboratorMutation.isLoading}
          onCancel={() => setRevokeInvitationModal(NULL_USER)}
        />
      )}
      {removeCollaboratorModal.isOn && (
        <PromptModal
          variant="danger"
          title="Remove collaborator"
          description={`You are about to remove ${removeCollaboratorModal.name} from your organization’s workspace. Are you sure?`}
          buttonText="Remove"
          onApprove={() => {
            if (removeCollaboratorModal.userId) {
              removeCollaboratorMutation
                .mutateAsync({ userId: removeCollaboratorModal.userId })
                .then(() => {
                  setRemoveCollaboratorModal(NULL_USER);
                  users.refetch().catch((error) => {
                    throw error;
                  });
                })
                .catch((e) => {
                  throw e;
                });
            }
          }}
          executing={removeCollaboratorMutation.isLoading}
          onCancel={() => setRemoveCollaboratorModal(NULL_USER)}
        />
      )}
      {changeRoleModal.isOn && changeRoleModal.userId && (
        <ChangeRoleModal
          title={`Change role for ${changeRoleModal.name}`}
          defaultRole={changeRoleModal.role}
          userId={changeRoleModal.userId}
          onUpdate={() => {
            setChangeRoleModal(NULL_USER);
            users.refetch().catch((error) => {
              throw error;
            });
          }}
          onCancel={() => setChangeRoleModal(NULL_USER)}
        />
      )}
    </MenuContentContainer>
  );
};

gql`
  query fetchUsers($organizationId: Int!) {
    usersByOrganizationId(organizationId: $organizationId) {
      id
      name {
        firstName
        lastName
      }
      email
      role
      inviteState
    }
  }
`;

function convertUsersToTableData(users: FetchUsersQuery['usersByOrganizationId']): UserTableData[] {
  return users.map((user) => ({
    id: user.id,
    name: `${user.name?.firstName ?? ''} ${user.name?.lastName ?? ''}`,
    email: user.email,
    role: user.role,
  }));
}
