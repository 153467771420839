import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DropDownOption } from './DropDownOption';
import { DropDownButton } from './DropDownButton';

type DropDownProps = React.PropsWithChildren & {
  button?: React.ReactNode;
  menuAlignment: 'left' | 'right';
  menuWidth?: 'w-44' | 'w-52';
};

type DropDownContent = {
  Option: typeof DropDownOption;
  Button: typeof DropDownButton;
};

export const DropDown: React.FC<DropDownProps> & DropDownContent = ({
  button,
  menuAlignment,
  menuWidth = 'w-44',
  children,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-20">
      <div>
        {button ?? (
          <DropDown.Button>
            <span className="sr-only">Open options</span>
            <FontAwesomeIcon className="h-5 w-5" aria-hidden="true" icon={regular('ellipsis')} />
          </DropDown.Button>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={classNames(
            'origin-top-right absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            menuWidth,
            `${menuAlignment}-0`
          )}
        >
          <div className="py-1">{children}</div>
        </div>
      </Transition>
    </Menu>
  );
};

DropDown.Option = DropDownOption;
DropDown.Button = DropDownButton;
