import React from 'react';
import { DropDown } from '../../components/molecules/dropDown/DropDown';

export const DropDownExample: React.FC = () => {
  return (
    <DropDown menuAlignment="right">
      <DropDown.Option
        label="Account Settings"
        action={() => {
          alert('click na menu');
        }}
      />
      <DropDown.Option
        label="Support"
        action={() => {
          alert('click na Supprot');
        }}
      />
      <DropDown.Option
        label="License"
        action={() => {
          alert('click na License');
        }}
      />
      <DropDown.Option
        label="Sign out"
        action={() => {
          alert('click na signout');
        }}
      />
    </DropDown>
  );
};
