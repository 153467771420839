import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubscriptionTier, useFetchMyActivePlanQuery } from '../api/graphql/generated';
import { Centered } from '../components/atoms/layouts/Centered';
import { FullScreenSize } from '../components/atoms/layouts/FullScreenSize';
import { Error } from '../components/molecules/error/Error';
import { Loader } from '../components/molecules/loader/Loader';
import { SubscriptionActiveTemplate } from '../components/templates/settings/SubscriptionActiveTemplate';
import { useActivePlan } from '../contexts/activePlan/ActivePlanCosumer';

export const SubscriptionPendingPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const sub = searchParams.get('sub');
  console.log(sub);

  const activePlanQuery = useFetchMyActivePlanQuery(undefined, { refetchOnWindowFocus: false, refetchInterval: 1000 });
  const { refetchActivePlan } = useActivePlan();

  const redirectTimer = React.useCallback(() => {
    const timer = setTimeout(() => navigate('/settings/billing'), 2000);
    return () => clearInterval(timer);
  }, [navigate]);

  if (
    activePlanQuery.isLoading ||
    activePlanQuery.data?.me?.organization?.pricingPlan.pricingPlanType === SubscriptionTier.Free ||
    (sub !== null && activePlanQuery.data?.me?.organization?.pricingPlan.pricingPlanType !== sub)
  ) {
    return (
      <FullScreenSize>
        <Centered tryGoldenCenter>
          <Loader label="Verifying subscription..." />
        </Centered>
      </FullScreenSize>
    );
  }
  if (activePlanQuery.error || activePlanQuery.data?.me?.organization?.pricingPlan.pricingPlanType === undefined) {
    return (
      <FullScreenSize>
        <Centered tryGoldenCenter>
          <Error
            title="Cannot retrieve active subscription"
            description="It appears you do not have any active subscription. Please try again or contact support."
            error="Error"
            primary={{ label: 'Try again', action: () => window.location.reload() }}
            secondary={{ label: 'Go to dashboard', action: () => navigate('/') }}
          />
        </Centered>
      </FullScreenSize>
    );
  }

  refetchActivePlan();
  redirectTimer();
  return (
    <SubscriptionActiveTemplate subscriptionType={activePlanQuery.data.me.organization.pricingPlan.pricingPlanType} />
  );
};
