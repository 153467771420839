import React, { AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type MenuItemProps = {
  icon: IconProp;
  href: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  label: React.ReactNode;
};
export const MenuLink: React.FC<MenuItemProps> = ({ label, icon, href }) => {
  return (
    <a
      className={classNames(
        'group flex items-center px-2 py-3 text-base font-semibold rounded-lg duration-100',
        'border-transparent text-white hover:bg-grey-900 hover:text-grey-400'
      )}
      target="_blank"
      href={href}
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={icon}
        className={classNames('mr-3 flex-shrink-0 h-4 w-4', 'text-white group-hover:text-gray-400')}
        aria-hidden="true"
      />
      {label}
    </a>
  );
};
