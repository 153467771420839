import React from 'react';
import { SectionHeader } from '../../../components/organisms/SectionHeader';
import { Divider } from '../../../components/atoms/divider/Divider';
import { LabelOnLeftBox } from '../../../components/atoms/inputs/layouts/LabelOnLeftBox';
import { Label } from '../../../components/atoms/inputs/label/Label';
import { ItemsFromEndLayout } from '../../../components/atoms/inputs/layouts/PlaceItemFromEnd';
import { AlignedContent } from '../../../components/atoms/layouts/AlignedContent';
import { MenuContentContainer } from '../../../components/atoms/layouts/MenuContentContainer';
import { MAX_INPUT_LENGTH_100 } from '../../../constants';
import gql from 'graphql-tag';
import {
  SubscriptionTier,
  useFetchAppearanceQuery,
  useFetchProjectNameQuery,
  useUpdateProjectWatermarkMutation,
} from '../../../api/graphql/generated';
import { ProjectPageProps, withProjectId } from '../../../hoc/withProjectId';
import { Loader } from '../../../components/molecules/loader/Loader';
import { AsyncTextInput } from '../../../components/molecules/inputs/AsyncTextInput';
import { Button } from '../../../components/atoms/buttons/Button';
import { SelectFileModal, UploadFileFunction } from '../../../components/organisms/SelectFileModal';
import { useSwitch } from '../../../hooks/useSwitch';
import { useUploadLogoMutation } from '../../../repositories/project/UploadLogoMutation';
import { LabelDescription } from '../../../components/atoms/inputs/label/LabelDescription';
import { LogoPreview } from '../../../components/molecules/LogoPreview';
import { useToast } from '../../../hooks/useToast';
import { useActivePlan } from '../../../contexts/activePlan/ActivePlanCosumer';
import { Banner } from '../../../components/atoms/banners/Banner';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';

const MAX_3MB = 3_145_728;

type BrandingProps = ProjectPageProps;

const Branding: React.FC<BrandingProps> = ({ projectId }) => {
  const appearanceQuery = useFetchAppearanceQuery({ projectId }, { refetchOnWindowFocus: false });
  const watermarkMutation = useUpdateProjectWatermarkMutation();
  const projectNameQuery = useFetchProjectNameQuery({ projectId });
  const uploadLogo = useSwitch(false);
  const upload = useUploadLogoMutation();
  const { addSuccessToast, addErrorToast } = useToast();

  const uploadFileHandler: UploadFileFunction = Object.assign(
    (logo: File) => {
      upload
        .mutateAsync({ projectId, logo })
        .then(async () => {
          uploadLogo.turnOff();
          await appearanceQuery.refetch();
          addSuccessToast(
            'New logo successfully set',
            `New loading screen logo for ${
              projectNameQuery.data?.projectById?.name ?? 'your awesome'
            } course has been changed`
          );
        })
        .catch(() => {
          addErrorToast(
            'Something went wrong',
            'We have detected an error when communicating with server, please try again.'
          );
        });
    },
    {
      isLoading: upload.isLoading,
    }
  );

  const resetLogoHandler = () => {
    upload
      .mutateAsync({ projectId, logo: undefined })
      .then(async () => {
        uploadLogo.turnOff();
        await appearanceQuery.refetch();
        addSuccessToast(
          'New logo successfully set',
          `New loading screen logo for ${
            projectNameQuery.data?.projectById?.name ?? 'your awesome'
          } course has been changed`
        );
      })
      .catch((reason) => {
        addErrorToast(
          'Something went wrong',
          'We have detected an error when communicating with server, please try again.'
        );
      });
  };

  const navigate = useNavigate();
  const { activePlan } = useActivePlan();
  const isFreeOrBasicTier = activePlan.type === SubscriptionTier.Free || activePlan.type === SubscriptionTier.Pro;

  return (
    <>
      <MenuContentContainer>
        <AlignedContent direction="column" gap="xs">
          <SectionHeader>
            <SectionHeader.Title>Branding</SectionHeader.Title>
            <SectionHeader.Subtitle>
              Customize the look and feel of your course. Changes will only affect new course exports, old exports will
              keep previous appearance.
            </SectionHeader.Subtitle>
          </SectionHeader>
          <Divider />
          {isFreeOrBasicTier && (
            <>
              <Banner
                icon={solid('circle-info')}
                text="This feature is only available to Pro and Enterprise plans."
                button={{ label: 'Upgrade', action: () => navigate('/pricing') }}
              />
            </>
          )}
          <LabelOnLeftBox
            label={
              <>
                <Label htmlFor="watermark">Watermark</Label>
                <LabelDescription>Visible at the bottom of menu.</LabelDescription>
              </>
            }
          >
            <ItemsFromEndLayout gap="m">
              {appearanceQuery.isLoading ? (
                <Loader label="Loading watermark" />
              ) : (
                <AsyncTextInput
                  id="watermark"
                  size="l"
                  type="text"
                  defaultValue={appearanceQuery.data?.projectById?.settings.watermark ?? ''}
                  maxLength={MAX_INPUT_LENGTH_100}
                  isLoading={watermarkMutation.isLoading}
                  onSave={(watermark) => {
                    watermarkMutation.mutateAsync({ watermark, projectId }).catch((reason) => {
                      throw reason;
                    });
                  }}
                  disabled={isFreeOrBasicTier}
                />
              )}
            </ItemsFromEndLayout>
          </LabelOnLeftBox>
          <Divider />
          {/*<LabelOnLeftBox label={<Label htmlFor="tagline">Tagline</Label>}>*/}
          {/*  <ItemsFromEndLayout gap="m">*/}
          {/*    {appearanceQuery.isLoading ? (*/}
          {/*      <Loader label="Loading tagline" />*/}
          {/*    ) : (*/}
          {/*      <AsyncTextInput*/}
          {/*        id="tagline"*/}
          {/*        size="l"*/}
          {/*        type="text"*/}
          {/*        maxLength={MAX_INPUT_LENGTH_100}*/}
          {/*        onSave={() => alert('saving!!!!')}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </ItemsFromEndLayout>*/}
          {/*</LabelOnLeftBox>*/}
          {/*<Divider />*/}
          <LabelOnLeftBox
            label={
              <>
                <Label htmlFor="logo">Logo</Label>
                <LabelDescription>Visible when a course loads.</LabelDescription>
              </>
            }
          >
            <ItemsFromEndLayout gap="xl">
              {appearanceQuery.isLoading ? (
                <Loader label="Loading logo" />
              ) : (
                <>
                  <LogoPreview logo={appearanceQuery.data?.projectById?.settings.logoUrl ?? undefined} />

                  <Button
                    label="Change"
                    size="m"
                    action={() => uploadLogo.turnOn()}
                    variant="tertiary"
                    loading={upload.isLoading}
                    disabled={isFreeOrBasicTier}
                  />
                  <Button
                    label="Reset"
                    size="m"
                    action={resetLogoHandler}
                    variant="text"
                    loading={upload.isLoading}
                    disabled={!appearanceQuery.data?.projectById?.settings.logoUrl}
                  />
                </>
              )}
            </ItemsFromEndLayout>
          </LabelOnLeftBox>
          <Divider />
        </AlignedContent>
      </MenuContentContainer>
      {uploadLogo.isOn && (
        <SelectFileModal
          title={`Set logo for ${projectNameQuery.data?.projectById?.name ?? ''}`}
          onlyOneFile
          maxFileSize={MAX_3MB}
          acceptFileType={{ 'image/jpeg': 'JPG', 'image/png': 'PNG' }}
          createButtonText="Set logo"
          onCreate={uploadFileHandler}
          onCancel={() => uploadLogo.turnOff()}
        />
      )}
    </>
  );
};

export default withProjectId(Branding);

gql`
  query fetchAppearance($projectId: Int!) {
    projectById(id: $projectId) {
      settings {
        watermark
        logoUrl
      }
    }
  }
`;

gql`
  mutation updateProjectWatermark($projectId: Int!, $watermark: String!) {
    SetProjectProperties(input: { projectId: $projectId, watermark: $watermark }) {
      project {
        id
      }
    }
  }
`;
