import React from 'react';
import { useNonValidatedUser } from '../plugins/auth/Authentication';
import { VerifyEmailPage } from '../pages/VerifyEmailPage';
import { FullScreenSize } from '../components/atoms/layouts/FullScreenSize';
import { Centered } from '../components/atoms/layouts/Centered';
import { Loader } from '../components/molecules/loader/Loader';

export function withEmailVerified<Props>(Component: React.ComponentType<Props>): React.FC<Props> {
  return (props: Props) => {
    const user = useNonValidatedUser();

    if (!user.verified() && user.isLoading) {
      return (
        <FullScreenSize>
          <Centered tryGoldenCenter>
            <Loader label="Verifying email..." />
          </Centered>
        </FullScreenSize>
      );
    }

    if (!user.verified()) {
      return (
        <VerifyEmailPage
          email={user.email}
          onFocus={() => {
            user.forceNewToken().catch((e) => {
              throw e;
            });
          }}
        />
      );
    }

    return <Component {...props} />;
  };
}

export default withEmailVerified;
