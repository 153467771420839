import React, { useState } from 'react';
import { RadioObject } from '../atoms/inputs/radios/RadioObject';
import { RadioOption } from '../atoms/inputs/radios/RadioOption';
import { RadioGroup } from '../atoms/inputs/radios/RadioGroup';
import { SceneType } from '../../repositories/scene/UploadSceneMutation';

type SceneTypeOption = RadioObject & {
  role: SceneType;
};

const EditorOptions: SceneTypeOption[] = [
  {
    id: 1,
    role: SceneType.MultiResPanorama,
    label: '360° image',
    description: (
      <>
        Spherical panorama, 2:1 aspect ratio.
        <br /> Recommended: as high resolution as possible.
      </>
    ),
  },
  {
    id: 2,
    role: SceneType.FlatImage,
    label: 'Regular image',
    description: (
      <>
        Standard, flat, rectilinear image.
        <br /> Recommended: as high resolution as possible.
      </>
    ),
  },
];

type SceneTypeRadioGroupProps = {
  id?: string;
  preselected?: SceneType;
  onChange: (selected: SceneType) => void;
};

export const SceneTypeRadioGroup: React.FC<SceneTypeRadioGroupProps> = ({ id, preselected, onChange }) => {
  const [selected, setSelected] = useState(
    EditorOptions.find((search) => search.role === preselected) ?? EditorOptions[0]
  );
  return (
    <RadioGroup
      id={id}
      selected={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected.role);
      }}
    >
      {EditorOptions.map((role) => (
        <RadioOption key={role.id} value={role} />
      ))}
    </RadioGroup>
  );
};
