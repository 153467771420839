import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';
import { SubscriptionButton } from '../../organisms/SubscriptionButton';

import { SubscriptionTier } from '../../../api/graphql/generated';

// Do not change these values, they are connected with the product price in Stripe, and set via API or Stripe CLI
// PRO is first paid tier, TEAM is second paid tier (at the moment its BASIC and PRO)
const PRICE_LOOKUP_KEY_PRO_MONTHLY = 'proMonthly';
const PRICE_LOOKUP_KEY_PRO_ANNUALLY = 'proAnnual';
const PRICE_LOOKUP_KEY_TEAM_MONTHLY = 'teamMonthly';
const PRICE_LOOKUP_KEY_TEAM_ANNUALLY = 'teamAnnual';
// const PRICE_LOOKUP_KEY_EXTRA_SEAT_MONTHLY = "price_";
// const PRICE_LOOKUP_KEY_EXTRA_SEAT_ANNUALLY = "price_";

interface AdditionalAdmin {
  monthly: string;
  annually: string;
}
export interface Tier {
  name: string;
  priceMonthly: string;
  priceAnnually: string;
  priceSuffix: boolean;
  description: string;
  monthlyPriceId?: string;
  yearlyPriceId?: string;
  type: SubscriptionTier;
}

const tiers: Tier[] = [
  {
    name: 'Free',
    priceMonthly: 'Free',
    priceAnnually: 'Free',
    priceSuffix: false,
    description: 'Create up to 3 courses and share via web links.',
    type: SubscriptionTier.Free,
  },
  {
    name: 'Basic',
    priceMonthly: '€349',
    priceAnnually: '€279',
    priceSuffix: true,
    description: 'Create unlimited number of courses, export as SCORM or xAPI and import in your LMS.',
    monthlyPriceId: PRICE_LOOKUP_KEY_PRO_MONTHLY,
    yearlyPriceId: PRICE_LOOKUP_KEY_PRO_ANNUALLY,
    type: SubscriptionTier.Pro,
  },
  {
    name: 'Pro',
    priceMonthly: '€695',
    priceAnnually: '€556',
    priceSuffix: true,
    description: "Invite your colleagues to collaborate on courses' and build virtual tours.",
    monthlyPriceId: PRICE_LOOKUP_KEY_TEAM_MONTHLY,
    yearlyPriceId: PRICE_LOOKUP_KEY_TEAM_ANNUALLY,
    type: SubscriptionTier.Team,
  },
  {
    name: 'Enterprise',
    priceMonthly: 'Get quote',
    priceAnnually: 'Get quote',
    priceSuffix: false,
    description: 'Build complex courses, enroll and track users progress and distribute courses offline.',
    type: SubscriptionTier.Enterprise,
  },
];

const sections = [
  {
    name: 'General',
    features: [
      {
        name: 'Number of courses',
        tiers: { Free: '3', Basic: 'Unlimited', Pro: 'Unlimited', Enterprise: 'Unlimited' },
      },
      { name: 'Scenes per course', tiers: { Free: '2', Basic: '2', Pro: '10', Enterprise: '250' } },
      { name: 'Removed watermark', tiers: { Free: false, Basic: true, Pro: true, Enterprise: true } },
      { name: 'Custom branding', tiers: { Free: false, Basic: false, Pro: true, Enterprise: true } },

      {
        name: 'Additional admin',
        tiers: {
          Free: false,
          Basic: false,
          Pro: {
            monthly: '185€/mo',
            annually: '185€/mo',
          } as AdditionalAdmin,
          Enterprise: 'Custom',
        },
      },
    ],
  },
  {
    name: 'Sharing',
    features: [
      {
        name: 'Share publicly',
        explanation: 'Generate a course link you can share with anyone. ',
        tiers: { Free: true, Basic: true, Pro: true, Enterprise: true },
      },
      { name: 'SCORM export', tiers: { Free: false, Basic: true, Pro: true, Enterprise: true } },
      { name: 'xAPI export', tiers: { Free: false, Basic: true, Pro: true, Enterprise: true } },
      { name: 'SCORM cloud dispatch', tiers: { Free: false, Basic: false, Pro: false, Enterprise: true } },
    ],
  },
  {
    name: 'Support',
    features: [
      { name: 'Email support', tiers: { Free: true, Basic: true, Pro: true, Enterprise: true } },
      {
        name: 'Onboarding',
        tiers: { Free: false, Basic: true, Pro: true, Enterprise: true },
      },
      {
        name: 'Project consultations',
        explanation: "Get expert feedback on your ongoing projects to ensure you're on the right track.",
        tiers: { Free: false, Basic: 'Add-on', Pro: true, Enterprise: true },
      },
      { name: 'Dedicated success agent', tiers: { Free: false, Basic: false, Pro: false, Enterprise: true } },
    ],
  },
  // {
  //   name: 'LMS Features',
  //   features: [
  //     { name: 'User enrolments', tiers: { Free: false, Pro: false, Team: false, Enterprise: true } },
  //     { name: 'User progress tracking', tiers: { Free: false, Pro: false, Team: false, Enterprise: true } },
  //     { name: 'Offline iOS app for courses', tiers: { Free: false, Pro: false, Team: false, Enterprise: true } },
  //     { name: 'Offline progress tracking', tiers: { Free: false, Pro: false, Team: false, Enterprise: true } },
  //   ],
  // },
];

interface PricingTemplateProps {
  activeSubscription: SubscriptionTier;
  onButtonClick: (lookupKey: string, type: Tier, isMonthly: boolean) => void;
}

export default function PricingTemplate({ activeSubscription, onButtonClick }: PricingTemplateProps) {
  const types = ['Monthly', 'Annual'];
  const [active, setActive] = useState(types[1]);
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pl-12 sm:flex sm:flex-col sm:align-center ">
        <div className="relative self-center mt-6  rounded-lg p-0.5 flex sm:mt-8 bg-gray-200">
          {types.map((type) => (
            <button
              key={type}
              className={
                active === type
                  ? 'relative w-1/2 rounded-md py-2 text-sm leading-5 whitespace-no-wrap transition ease-in-out duration-150 sm:w-auto sm:px-8 text-blue-500 font-medium bg-white'
                  : 'relative w-1/2 rounded-md shadow-sm py-2 text-sm leading-5 whitespace-no-wrap focus:outline-none transition ease-in-out duration-150 sm:w-auto sm:px-8 text-gray-800 active:text-blue-500 font-medium hover:text-blue-500'
              }
              type="button"
              onClick={() => setActive(type)}
            >
              {type} billing
            </button>
          ))}
        </div>
      </div>
      <div className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="px-4 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {active === 'Monthly' ? tier.priceMonthly : tier.priceAnnually}
                  </span>{' '}
                  {tier.priceSuffix === true ? <span className="text-base font-medium text-gray-500">/mo</span> : null}
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <SubscriptionButton
                  tier={tier}
                  monthlyActive={active === 'Monthly'}
                  action={(lookupKey) => onButtonClick(lookupKey, tier, active === 'Monthly')}
                  isActive={activeSubscription === tier.type}
                />
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4 text-right">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700 text-right">{feature.tiers[tier.name]}</span>
                          ) : // Made specifically for price changes, because they change depending on Monthly, Yearly tiers
                          typeof feature.tiers[tier.name] === 'object' ? (
                            <span className="block text-sm text-grey-700 transition-opacity ease-in duration-700 opacity-100">
                              {active === 'Monthly'
                                ? (feature.tiers[tier.name] as AdditionalAdmin).monthly
                                : (feature.tiers[tier.name] as AdditionalAdmin).annually}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <FontAwesomeIcon
                                  icon={solid('check')}
                                  className="inline-block h-6 w-6 text-green-500 text-right"
                                  aria-hidden="true"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={solid('minus')}
                                  className="inline-block h-6 w-6 text-gray-400 "
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div
                className={classNames(
                  tierIdx < tiers.length - 1 ? 'py-5 border-b' : 'pt-5',
                  'border-t border-gray-200 px-4'
                )}
              >
                <SubscriptionButton
                  tier={tier}
                  monthlyActive={active === 'Monthly'}
                  action={(lookupKey) => onButtonClick(lookupKey, tier, active === 'Monthly')}
                  isActive={activeSubscription === tier.type}
                />
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row">
                  Pricing
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="h-full py-8 px-6 align-top">
                    <div className="relative h-full flex flex-col">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">
                          {active === 'Monthly' ? tier.priceMonthly : tier.priceAnnually}
                        </span>{' '}
                        {tier.priceSuffix && (
                          <React.Fragment>
                            <span className="text-base font-medium text-gray-500">/mo</span>

                            <p>
                              <span className="text-base font-medium text-gray-500">
                                {active === 'Monthly' ? 'billed monthly' : 'billed annually'}
                              </span>
                            </p>
                          </React.Fragment>
                        )}
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">{tier.description}</p>
                      <div className="mt-auto">
                        <SubscriptionButton
                          tier={tier}
                          monthlyActive={active === 'Monthly'}
                          action={(lookupKey) => onButtonClick(lookupKey, tier, active === 'Monthly')}
                          isActive={activeSubscription === tier.type}
                        />
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                      colSpan={5}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : // Made specifically for price changes, because they change depending on Monthly, Yearly tiers
                          typeof feature.tiers[tier.name] === 'object' ? (
                            <span className="block text-sm text-grey-700 transition-opacity ease-in duration-700 opacity-100">
                              {active === 'Monthly'
                                ? (feature.tiers[tier.name] as AdditionalAdmin).monthly
                                : (feature.tiers[tier.name] as AdditionalAdmin).annually}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <FontAwesomeIcon
                                  icon={solid('check')}
                                  className="block h-6 w-6 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={solid('minus')}
                                  className="block h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}
                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="pt-5 px-6">
                    <SubscriptionButton
                      tier={tier}
                      monthlyActive={active === 'Monthly'}
                      action={(lookupKey) => onButtonClick(lookupKey, tier, active === 'Monthly')}
                      isActive={activeSubscription === tier.type}
                    />
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
