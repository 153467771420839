import React from 'react';
import { FilesListItem } from './FileListItem';
import { FilesListDownloadItem } from './FileListIDownloadItem';
import { FilesListUploadItem } from './FileListUploadItem';

type FilesListProps = React.PropsWithChildren;

type FileListContent = {
  Item: typeof FilesListItem;
  DownloadItem: typeof FilesListDownloadItem;
  UploadItem: typeof FilesListUploadItem;
};

export const FileList: React.FC<FilesListProps> & FileListContent = ({ children }) => {
  return <ul className="flex flex-col gap-2 mt-3">{children}</ul>;
};

FileList.Item = FilesListItem;
FileList.DownloadItem = FilesListDownloadItem;
FileList.UploadItem = FilesListUploadItem;
