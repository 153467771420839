import React from 'react';

import { Modal } from '../atoms/layouts/Modal';
import { Text } from '../atoms/texts/Text';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Loader } from '../molecules/loader/Loader';
import { Button } from '../atoms/buttons/Button';
import { FileList } from '../atoms/lists/file/FileList';
import { API_CONFIG } from '../../api/api.config';
import { useProjectExportProgressQuery } from '../../repositories/project/ProjectExportProgressQuery';

type ExportProjectModalProps = {
  token: string;
  exportFilename: string;
  onClose: () => void;
};

const REFETCH_INTERVAL = 500;

export const ExportProjectModal: React.FC<ExportProjectModalProps> = ({ token, exportFilename, onClose }) => {
  const refetchInterval = (data: boolean | undefined): number | false => {
    if (data) {
      return false;
    }

    return REFETCH_INTERVAL;
  };
  const projectProgress = useProjectExportProgressQuery(token, { refetchInterval, refetchOnWindowFocus: false });

  const downloadFinished = !projectProgress.isLoading && projectProgress.data;
  const downloadUrl = new URL(`/api/export-project/download-package/${token}`, API_CONFIG.BACKEND_URL);
  downloadUrl.searchParams.set('filename', exportFilename);

  return (
    <Modal backdrop>
      {downloadFinished && (
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            Course export ready
          </Text>
          <AlignedContent direction="column" gap="l">
            <FileList>
              <FileList.DownloadItem filename={`${exportFilename}.zip`} onDownloadLink={downloadUrl.href} />
            </FileList>
            <AlignedContent direction="row" placing="fromEnd">
              <Button variant="tertiary" label="Close" action={onClose} />
            </AlignedContent>
          </AlignedContent>
        </AlignedContent>
      )}
      {!downloadFinished && (
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            Preparing course export
          </Text>
          <Loader label="Please wait, your course will be ready shortly..." />
        </AlignedContent>
      )}
    </Modal>
  );
};
