import React from 'react';
import classNames from 'classnames';
import {
  TextAlign,
  TextAlignToClassname,
  TextColor,
  TextColorToClassname,
  TextSize,
  TextSizeToClassname,
  TextWeight,
  TextWeightToClassname,
} from './Text';

type TextSpanProps = React.PropsWithChildren & {
  size?: TextSize;
  weight?: TextWeight;
  color?: TextColor;
  align?: TextAlign;
};

export const TextSpan: React.FC<TextSpanProps> = ({
  size = 'base',
  weight = 'normal',
  color = 'default',
  align = 'left',
  children,
}) => {
  return (
    <span
      className={classNames(
        TextSizeToClassname[size],
        TextWeightToClassname[weight],
        TextColorToClassname[color],
        TextAlignToClassname[align]
      )}
    >
      {children}
    </span>
  );
};
