import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { useUpgradeSubscriptionMutation } from '../../repositories/subscription/UpgradeSubscriptionMutation';
import { Button } from '../atoms/buttons/Button';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { Centered } from '../atoms/layouts/Centered';
import { Modal } from '../atoms/layouts/Modal';

import { Text } from '../atoms/texts/Text';
import { Loader } from '../molecules/loader/Loader';
import { LoaderBoxCover } from '../molecules/loader/LoaderBoxCover';
import { Tier } from '../templates/pricing/PricingTemplate';

type UpgradeSubscriptionTierModalProps = {
  lookupKey: string;
  tier: Tier;
  isMonthly: boolean;
  onCancel: () => void;
};

export const UpgradeSubscriptionTierModal: React.FC<UpgradeSubscriptionTierModalProps> = ({
  lookupKey,
  tier,
  isMonthly,
  onCancel,
}) => {
  const upgradeSubscriptionQuery = useUpgradeSubscriptionMutation();
  const navigate = useNavigate();
  const { addErrorToast } = useToast();

  const upgradeSubscription = () => {
    upgradeSubscriptionQuery.mutate(lookupKey, {
      onSuccess: () => {
        navigate(`/subscription-pending?sub=${tier.type}`);
      },
      onError: () => {
        addErrorToast(
          'An error has occured',
          'An unexpected error has occured. Please try again, or contact administration if the problem persists.'
        );
      },
    });
  };

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          if (!upgradeSubscriptionQuery.isLoading) {
            onCancel();
          }
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            You are about to upgrade your account from Pro plan to Team
          </Text>
          <Text size="s" weight="normal">
            You will gain access to features such as multi-scene courses and you will be able to add additional team
            members to your organization.
          </Text>
          <Text size="s" weight="bold">
            Pricing will change to{' '}
            {isMonthly ? <>{tier.priceMonthly} per month</> : <>{tier.priceAnnually} per month, charged once a year</>}.
          </Text>
          <Text size="s" weight="normal">
            You will be upgraded and billed immediately. Any remaining time on the previous subscription will be
            deducted from the new subscription.
          </Text>

          {upgradeSubscriptionQuery.isLoading && (
            <LoaderBoxCover>
              <Centered tryGoldenCenter>
                <Loader label="Upgrading..." />
              </Centered>
            </LoaderBoxCover>
          )}
          <AlignedContent direction="row" gap="s" placing="fromEnd">
            <Button label="Cancel" action={onCancel} variant="tertiary" />
            <Button label="Upgrade" action={upgradeSubscription} loading={upgradeSubscriptionQuery.isLoading} />
          </AlignedContent>
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
