import { CreateProjectCrossroadsModal, ProjectSource, TypeSelectionFunction } from './CreateProjectCrossroadsModal';
import React, { useState } from 'react';
import { useSwitch } from '../../../hooks/useSwitch';
import { PickProjectSample } from './PickProjectSample';
import { OnboardingTask, useAddNewProjectMutation } from '../../../api/graphql/generated';
import { useOnboarding } from '../../../contexts/onboarding/OnboardingConsumer';
import { useToast } from '../../../hooks/useToast';

const DEFAULT_PROJECT_NAME = 'Your awesome course';

type CreateNewProjectWizardProps = {
  organizationId: number;
  onCreate: (projectId: number, projectName: string) => void;
  onCancel: () => void;
};

export const CreateNewProjectWizard = ({ organizationId, onCreate, onCancel }: CreateNewProjectWizardProps) => {
  const [source, setSource] = useState<ProjectSource>(ProjectSource.FROM_SCRATCH);
  const crossroadsSwitch = useSwitch(true);

  const { addErrorToast } = useToast();
  const createProject = useAddNewProjectMutation();
  const onboarding = useOnboarding();

  const typeSelectedHandler: TypeSelectionFunction = Object.assign(
    (picked: ProjectSource) => {
      if (picked === 'from-scratch') {
        createProject
          .mutateAsync(
            { name: DEFAULT_PROJECT_NAME, sceneId: null },
            {
              onSuccess(data, variables, context) {
                if (data.AddProject.project) {
                  onboarding.updateOnboarding({ taskCompleted: OnboardingTask.BlankCourseCreated });
                  onCreate(data.AddProject.project.id, DEFAULT_PROJECT_NAME);
                }
              },
            }
          )
          .catch(() => {
            addErrorToast('Course limit reached', 'Free plan allows you to have have 3 courses at the time.');
          });
        return;
      }
      setSource(picked);
      crossroadsSwitch.turnOff();
    },
    {
      isLoading: createProject.isLoading,
    }
  );

  return (
    <>
      {crossroadsSwitch.isOn && (
        <CreateProjectCrossroadsModal defaultSource={source} onTypeSelected={typeSelectedHandler} onCancel={onCancel} />
      )}
      {!crossroadsSwitch.isOn && source === 'sample' && <PickProjectSample onPick={onCreate} onCancel={onCancel} />}
    </>
  );
};
