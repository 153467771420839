import React from 'react';

import PrivateApp from './PrivateApp';
import { withRequiredAuthenticatedUser } from './plugins/auth/Authentication';
import { Route, Routes } from 'react-router-dom';
import { Verified } from './pages/Verified';

import './App.css';

export const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path={'/verified'} element={<Verified />} />
        <Route path={'*'} element={<PrivateApp />} />
      </Routes>
    </div>
  );
};

export default withRequiredAuthenticatedUser(App);
