import React from 'react';
import { Error, ErrorProps } from '../../molecules/error/Error';
import { Centered } from '../../atoms/layouts/Centered';
import { FullScreenSize } from '../../atoms/layouts/FullScreenSize';

export const ErrorTemplate: React.FC<ErrorProps> = (props) => {
  return (
    <FullScreenSize>
      <Centered tryGoldenCenter>
        <Error {...props} />
      </Centered>
    </FullScreenSize>
  );
};
