import React from 'react';
import { ImageListItemDescription } from './ImageListItemDescription';
import { ButtonAction } from '../buttons/Button';
import { ImageListItemSubDescription } from './ImageListItemSubDescription';
import { Image, ImageProps } from '../image/Image';

type ImageListItemProps = React.PropsWithChildren & {
  imageSrc: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>['src'];
  placeholderIcon: ImageProps['placeholderIcon'];
  screenReaderLabel: ButtonAction['label'];
  action?: ButtonAction['action'];
};

export const ImageListItem: React.FC<ImageListItemProps> & {
  Description: typeof ImageListItemDescription;
  SubDescription: typeof ImageListItemSubDescription;
} = ({ imageSrc, placeholderIcon, children, screenReaderLabel, action }) => {
  return (
    <li className="relative">
      <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-blue-500 overflow-hidden">
        <Image placeholderIcon={placeholderIcon} imageSrc={imageSrc} />
        <button type="button" className="absolute inset-0 focus:outline-none" onClick={action} disabled={!action}>
          <span className="sr-only">{screenReaderLabel}</span>
        </button>
      </div>
      {children}
    </li>
  );
};

ImageListItem.Description = ImageListItemDescription;
ImageListItem.SubDescription = ImageListItemSubDescription;
