import React, { useState } from 'react';
import { RadioOption } from '../../components/atoms/inputs/radios/RadioOption';
import { RadioGroup } from '../../components/atoms/inputs/radios/RadioGroup';
import { RadioObject } from '../../components/atoms/inputs/radios/RadioObject';

type RadioGroupTestProps = {
  radioOptions: RadioObject[];
};
export const RadioGroupTest: React.FC<RadioGroupTestProps> = ({ radioOptions }) => {
  const [selected, setSelected] = useState(radioOptions[0]);
  return (
    <RadioGroup
      selected={selected}
      onChange={(sel) => {
        console.log(sel);
        console.log(selected);
        setSelected(sel);
      }}
    >
      {radioOptions.map((setting) => (
        <RadioOption key={setting.id} value={setting} />
      ))}
    </RadioGroup>
  );
};
