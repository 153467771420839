import React from 'react';
import classNames from 'classnames';

type TabProps = Omit<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'className' | 'aria-current'
> & {
  selected: boolean;
};

export const Tab: React.FC<TabProps> = ({ selected, children, ...props }) => {
  return (
    <a
      className={classNames(
        selected
          ? 'border-blue-500 text-blue-600'
          : 'border-transparent text-blue-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm px-8'
      )}
      aria-current={selected ? 'page' : undefined}
      {...props}
    >
      {children}
    </a>
  );
};
