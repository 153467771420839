import React from 'react';
import { ChecklistItem } from './ChecklistItem';

type ChecklistProps = {
  children: React.ReactNode;
};

interface ChecklistBuilder {
  (props: ChecklistProps): JSX.Element;
  Item: typeof ChecklistItem;
}

export const Checklist: ChecklistBuilder = ({ children }: ChecklistProps) => {
  return <div className="flex flex-col">{children}</div>;
};

Checklist.Item = ChecklistItem;
