import { useState } from 'react';

export const useSwitch = (defaultState = false) => {
  const [isOn, setIsOn] = useState(defaultState);
  const turnOff = () => {
    setIsOn(false);
  };
  const turnOn = () => {
    setIsOn(true);
  };
  const toggle = () => {
    setIsOn((prevState) => !prevState);
  };
  return {
    isOn,
    turnOn,
    turnOff,
    toggle,
  };
};
