import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AUTH0_CONFIG } from './plugins/auth/auth.config';
import { AuthenticatedUserProvider } from './plugins/auth/Authentication';
import { ErrorBoundary } from 'react-error-boundary';
import { Logger } from './plugins/logger/Logger';
import { ErrorFallback } from './pages/error/ErrorFallback';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalPortal } from './components/atoms/portals/ModalPortal';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AuthenticatedUserProvider
      domain={AUTH0_CONFIG.AUTH0_DOMAIN}
      audience={AUTH0_CONFIG.AUTH0_AUDIENCE}
      clientId={AUTH0_CONFIG.AUTH0_CLIENT_ID}
      cacheLocation={AUTH0_CONFIG.CACHE_LOCATION}
      redirectUri={window.location.origin}
      screen_hint={window.location.pathname === '/signup' ? 'signup' : 'login'}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => Logger.trackException(error)}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </AuthenticatedUserProvider>
    <Toaster position="top-right" />
    <ModalPortal />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
