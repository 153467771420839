import axios, { AxiosRequestConfig } from 'axios';
import { API_CONFIG } from './api.config';
import { useUser } from '../plugins/auth/Authentication';

export const axiosInstance = axios.create({
  baseURL: API_CONFIG.BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

enum HttpMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type Config<TData> = Omit<AxiosRequestConfig<TData>, 'baseURL'>;

export const useFetch = () => {
  const { getAccessTokenSilently } = useUser();

  return {
    post: request(HttpMethod.POST, getAccessTokenSilently),
    get: request(HttpMethod.GET, getAccessTokenSilently),
    put: request(HttpMethod.PUT, getAccessTokenSilently),
    delete: request(HttpMethod.DELETE, getAccessTokenSilently),
  };
};

export const useUnauthorizedFetch = () => {
  return {
    post: request(HttpMethod.POST, () => Promise.resolve(undefined)),
    get: request(HttpMethod.GET, () => Promise.resolve(undefined)),
    put: request(HttpMethod.PUT, () => Promise.resolve(undefined)),
    delete: request(HttpMethod.DELETE, () => Promise.resolve(undefined)),
  };
};

const request = (method: HttpMethod, accessTokenCallback: () => Promise<string | undefined | null>) => {
  return async <TResponse, TData>(path: string, payload?: TData, config?: Config<TData>) => {
    const accessToken = await accessTokenCallback();
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

    const requestConfig: AxiosRequestConfig<TData> = {
      ...config,
      baseURL: API_CONFIG.BACKEND_URL,
      headers,
    };

    //TODO: refactor, payload is not needed for GET and DELETE, should not even ask for it, but config may be needed
    switch (method) {
      case HttpMethod.POST:
        return await axiosInstance.post<TResponse>(path, payload, requestConfig);
      case HttpMethod.GET:
        return axiosInstance.get<TResponse>(path, requestConfig);
      case HttpMethod.PUT:
        return axiosInstance.put<TResponse>(path, payload, requestConfig);
      case HttpMethod.DELETE:
        return axiosInstance.delete<TResponse>(path, requestConfig);
    }
  };
};
