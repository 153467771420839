import React from 'react';
import { HomeWithMenu } from '../components/templates/HomeWithMenu';
import { Outlet } from 'react-router-dom';

export const Home: React.FC = () => {
  return (
    <HomeWithMenu>
      <Outlet />
    </HomeWithMenu>
  );
};
