import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { RadioObject } from './RadioObject';

type RadioOptionProps<T extends RadioObject> = {
  value: T;
};

export const RadioOption = <T extends RadioObject>({ value }: RadioOptionProps<T>): JSX.Element => {
  return (
    <RadioGroup.Option
      key={value.label}
      value={value}
      className={({ checked }) =>
        classNames(
          { 'bg-blue-50': checked },
          'relative border p-4 flex cursor-pointer focus:outline-none',
          'first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md'
        )
      }
    >
      {({ checked }) => (
        <>
          <span
            className={classNames(
              checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
              'h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full border flex items-center justify-center'
            )}
            aria-hidden="true"
          >
            <span className="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <span className="ml-3 flex flex-col">
            <RadioGroup.Label
              as="span"
              className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'block text-sm font-medium')}
            >
              {value.label}
            </RadioGroup.Label>
            {value.description && (
              <RadioGroup.Description
                as="span"
                className={classNames(checked ? 'text-blue-500' : 'text-gray-500', 'block text-sm')}
              >
                {value.description}
              </RadioGroup.Description>
            )}
          </span>
        </>
      )}
    </RadioGroup.Option>
  );
};
