import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type SuccessProps = {
  title: string;
  description: string;
  subDescription?: string;
  icon: IconProp;
};
export const Success: React.FC<SuccessProps> = ({ title, description, subDescription, icon }) => (
  <div className="text-center">
    <FontAwesomeIcon icon={icon} className="text-blue-500" size="3x" />
    <h1 className="text-2xl mt-4">{title}</h1>
    <p className="text-grey-600 mt-4">{description}</p>
    {subDescription && <p className="text-grey-600">{subDescription}</p>}
  </div>
);
