import React from 'react';
import { RadioGroup as HlRadioGroup } from '@headlessui/react';
import { RadioObject } from './RadioObject';

type RadioGroupProps<T extends RadioObject> = React.PropsWithChildren & {
  id?: string;
  selected: T;
  onChange: (newlySelected: T) => void;
};

export const RadioGroup = <T extends RadioObject>({
  id,
  selected,
  onChange,
  children,
}: RadioGroupProps<T>): JSX.Element => {
  return (
    <HlRadioGroup id={id} value={selected} onChange={onChange}>
      <div className="bg-white rounded-md -space-y-px">{children}</div>
    </HlRadioGroup>
  );
};
