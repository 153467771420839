import { Card } from '../atoms/card/Card';
import { CardHoverableActions } from '../atoms/card/CardHoverableActions';
import { Button } from '../atoms/buttons/Button';
import React from 'react';
import { Image, ImageProps } from '../atoms/image/Image';
import { CardActionBox } from '../atoms/card/CardActionBox';
import { Text } from '../atoms/texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CardTitle } from '../atoms/card/CardTitle';
import { AspectRatioBox } from '../atoms/image/AspectRatioBox';

type TemplateCardProps = {
  title: React.ReactNode;
  selected?: boolean;
  thumbnailUrl: ImageProps['imageSrc'];
  onSelect: () => void;
  selectText?: string;
  selectedText?: string;
};

export const TemplateCard = ({
  title,
  selected,
  thumbnailUrl,
  onSelect,
  selectText = 'Select',
  selectedText = 'Selected',
}: TemplateCardProps): JSX.Element => {
  return (
    <Card>
      <CardHoverableActions
        selected={selected}
        action={
          <CardActionBox variant={selected ? 'blue' : 'white'}>
            {selected ? (
              <Text color="white">
                <FontAwesomeIcon icon={solid('check')} />
                {selectedText}
              </Text>
            ) : (
              <Button variant="primary" action={onSelect} label={selectText} />
            )}
          </CardActionBox>
        }
      >
        <AspectRatioBox variant="thumbnail">
          <Image imageSrc={thumbnailUrl} />
        </AspectRatioBox>
      </CardHoverableActions>

      <CardTitle selected={selected}>{title}</CardTitle>
    </Card>
  );
};
