import React, { useState } from 'react';
import { RegisterStep1 } from '../../organisms/registration/RegisterStep1';
import { RegisterLoadableFunction, RegisterStep2 } from '../../organisms/registration/RegisterStep2';
import { User } from '@auth0/auth0-react';

export type LoadableFunction = {
  (
    firstname: string,
    lastname: string,
    marketingAccepted: boolean,
    companyName?: string,
    jobPosition?: string,
    phoneNumber?: string
  ): void;
  isLoading: boolean;
};

export type RegistrationWizzarProps = {
  user: User;
  onRegister: LoadableFunction;
  isInvited: boolean;
};

export const RegistrationWizzard = ({ onRegister, user, isInvited }: RegistrationWizzarProps) => {
  const haveUserInfo = !!user.given_name && !!user.family_name;

  const [userName, setUserName] = useState<{ firstname: string; lastname: string }>({
    firstname: user.given_name ?? '',
    lastname: user.family_name ?? '',
  });

  const [registerWizzardStep, setRegisterWizzardStep] = useState<1 | 2>(haveUserInfo ? 2 : 1);

  const registerHandler: RegisterLoadableFunction = Object.assign(
    (marketingAccepted: boolean, companyName?: string, jobPosition?: string, phoneNumber?: string) => {
      onRegister(userName.firstname, userName.lastname, marketingAccepted, companyName, jobPosition, phoneNumber);
    },
    { isLoading: onRegister.isLoading }
  );

  return (
    <>
      {registerWizzardStep === 1 && !haveUserInfo && (
        <RegisterStep1
          onContinue={(firstname, lastname) => {
            setUserName({ firstname, lastname });
            setRegisterWizzardStep(2);
          }}
          user={userName}
        />
      )}
      {(registerWizzardStep === 2 || haveUserInfo) && (
        <RegisterStep2 onRegister={registerHandler} isInvited={isInvited} />
      )}
    </>
  );
};
