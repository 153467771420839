import React from 'react';
import { ErrorTemplate } from '../../components/templates/error/ErrorTemplate';
import { useNavigate } from 'react-router-dom';

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ErrorTemplate
      error="404"
      title="Page not found"
      description="Try checking the URL in the address bar and try again."
      primary={{ label: 'Refresh', action: () => window.location.reload() }}
      secondary={{ label: 'Go to dashboard', action: () => navigate('/project') }}
    />
  );
};
