import { createContext } from 'react';
import { DEFAULT_PRICING_PLAN, PanomioPricingPlan } from './ActivePlan';

export type ActivePlan = {
  activePlan: PanomioPricingPlan;
  isLoaded: boolean;
  refetchActivePlan: () => void;
};

export const ActivePlanContext = createContext<ActivePlan>({
  activePlan: DEFAULT_PRICING_PLAN,
  isLoaded: false,
  refetchActivePlan: () => new Error(`Not implemented exception`),
});
