import { UseMutationOptions } from '@tanstack/react-query';
import { InviteUserMutation, InviteUserMutationVariables, useInviteUserMutation } from '../../api/graphql/generated';
import gql from 'graphql-tag';

type InviteNewUserOptions = UseMutationOptions<InviteUserMutation, unknown, InviteUserMutationVariables, unknown>;

export const useInviteTeamMateMutation = (options?: InviteNewUserOptions) => {
  return useInviteUserMutation(options);
};

gql`
  mutation inviteUser($email: String!, $role: Role!) {
    AddTeamMember(input: { newMemberEmail: $email, newMemberRole: $role }) {
      user {
        id
      }
      errors {
        ... on UserAlreadyExistsError {
          message
        }
      }
    }
  }
`;
