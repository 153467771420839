import React, { useState } from 'react';
import { Divider } from '../../../components/atoms/divider/Divider';
import { SectionHeader } from '../../../components/organisms/SectionHeader';
import { LabelOnLeftBox } from '../../../components/atoms/inputs/layouts/LabelOnLeftBox';
import { Label } from '../../../components/atoms/inputs/label/Label';
import { ItemsFromEndLayout } from '../../../components/atoms/inputs/layouts/PlaceItemFromEnd';
import { Select } from '../../../components/atoms/inputs/select/Select';
import { Option } from '../../../components/atoms/inputs/select/Option';
import { Button } from '../../../components/atoms/buttons/Button';
import { MenuContentContainer } from '../../../components/atoms/layouts/MenuContentContainer';
import { useSwitch } from '../../../hooks/useSwitch';
import { ExportProjectModal } from '../../../components/organisms/ExportProjectModal';
import { SelectObject } from '../../../components/atoms/inputs/select/SelectObject';
import {
  ExportType,
  SubscriptionTier,
  useFetchProjectNameQuery,
  useStartProjectExportMutation,
} from '../../../api/graphql/generated';
import { ProjectPageProps, withProjectId } from '../../../hoc/withProjectId';
import { useActivePlan } from '../../../contexts/activePlan/ActivePlanCosumer';
import { Banner } from '../../../components/atoms/banners/Banner';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import { Link } from '../../../components/atoms/links/Link';

type ExportOption = SelectObject & {
  fileSuffix: string;
  inputArg: ExportType;
};

const EXPORT_FORMATS: ExportOption[] = [
  { id: 0, inputArg: ExportType.Scorm12, name: 'SCORM 1.2', fileSuffix: 'SCORM12' },
  { id: 1, inputArg: ExportType.Scorm2004, name: 'SCORM 2004', fileSuffix: 'SCORM2004' },
  { id: 2, inputArg: ExportType.TinCan, name: 'xAPI (TinCan)', fileSuffix: 'xAPI' },
];

const Export: React.FC<ProjectPageProps> = ({ projectId }) => {
  const [selected, setSelected] = useState(EXPORT_FORMATS[0]);
  const exportModal = useSwitch();
  const [token, setToken] = useState('');
  const getExportToken = useStartProjectExportMutation();
  const projectNameQuery = useFetchProjectNameQuery({ projectId });

  const navigate = useNavigate();
  const { activePlan } = useActivePlan();
  const isFreeTier = activePlan.type === SubscriptionTier.Free;
  return (
    <MenuContentContainer>
      <SectionHeader>
        <SectionHeader.Title>Export package</SectionHeader.Title>
        <SectionHeader.Subtitle>
          Download the latest version of the course in SCORM 1.2, SCORM 2004 or xAPI format.
        </SectionHeader.Subtitle>
      </SectionHeader>
      <Divider />
      {isFreeTier && (
        <>
          <Banner
            icon={solid('circle-info')}
            text="This feature is only available to Basic, Pro and Enterprise plans."
            button={{ label: 'Upgrade', action: () => navigate('/pricing') }}
          />
          <Banner
            icon={solid('circle-info')}
            text={
              <span>
                If you wish to test exported course in your LMS without upgrading, you can{' '}
                <Link href="https://panomio.com/resources/docs/package-export" target="_blank">
                  download sample from our documentation
                </Link>
                .
              </span>
            }
            variant="grey"
          />
        </>
      )}
      <LabelOnLeftBox label={<Label>Export format</Label>}>
        <ItemsFromEndLayout gap="m">
          <Select
            selected={selected}
            onChange={(id) => {
              const format = EXPORT_FORMATS.find((format) => format.id === id);
              if (format) {
                setSelected(format);
              }
            }}
          >
            {EXPORT_FORMATS.map((format) => (
              <Option key={format.id} value={format} />
            ))}
          </Select>
          <Button
            label="Export"
            action={() => {
              const selectedOption = EXPORT_FORMATS.find((x) => x.id === selected.id);
              if (!!!selectedOption) {
                throw new Error('Unknown export type');
              }
              getExportToken.mutate(
                { exportType: selectedOption.inputArg, projectId },
                {
                  onSuccess: (mutation) => {
                    exportModal.turnOn();
                    setToken(mutation.StartProjectExport.string ?? '');
                  },
                }
              );
            }}
            loading={getExportToken.isLoading}
            disabled={isFreeTier || getExportToken.isLoading || projectNameQuery.isLoading}
            size="m"
            variant="primary"
          />
        </ItemsFromEndLayout>
      </LabelOnLeftBox>
      <Divider />
      {exportModal.isOn && (
        <ExportProjectModal
          token={token}
          onClose={exportModal.turnOff}
          exportFilename={`${projectNameQuery.data?.projectById?.name ?? 'projectExport'}_${selected.fileSuffix}`}
        />
      )}
    </MenuContentContainer>
  );
};

export default withProjectId(Export);
