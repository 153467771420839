import { Me } from '../me/MeEntity';
import { useRegisterNewUser } from './RegisterNewUserMutation';
import { useRenameOrganization } from './RenameOrganizationMutation';

type OrganizationRepo = {
  registerNewUser: {
    execute: (
      firstname: string,
      lastname: string,
      email: string,
      marketingConsent: boolean,
      companyName?: string,
      jobPosition?: string,
      phoneNumber?: string
    ) => Promise<Me>;
    isLoading: boolean;
  };
  renameOrganization: {
    execute: (organizationName: string) => Promise<Me>;
    isLoading: boolean;
  };
};

export const useOrganizationRepo = (): OrganizationRepo => {
  const registerNewUser = useRegisterNewUser();
  const renameOrganization = useRenameOrganization();

  return {
    registerNewUser: {
      execute: (
        firstname: string,
        lastname: string,
        email: string,
        marketingConsent: boolean,
        companyName?: string,
        jobPosition?: string,
        phoneNumber?: string
      ) =>
        registerNewUser.mutateAsync({
          firstname,
          lastname,
          email,
          marketingConsent,
          companyName,
          jobPosition,
          phoneNumber,
        }),
      isLoading: registerNewUser.isLoading,
    },
    renameOrganization: {
      execute: (organizationName: string) => renameOrganization.mutateAsync({ organizationName }),
      isLoading: renameOrganization.isLoading,
    },
  };
};
