import { TextInput, TextInputProps } from '../../atoms/inputs/text/TextInput';

import { Button } from '../../atoms/buttons/Button';
import React, { useState } from 'react';

type AsyncTextInputProps = Exclude<TextInputProps, 'onInput' | 'defaultValue'> & {
  onSave: (inputText: string) => void;
  defaultValue?: string;
  isLoading?: boolean;
};

export const AsyncTextInput = ({
  onSave,
  disabled,
  isLoading,
  defaultValue = '',
  ...inputProps
}: AsyncTextInputProps) => {
  const [stoneValue, setStoneValue] = useState(defaultValue);
  const [value, setValue] = useState(stoneValue);

  const changed = value !== stoneValue;

  return (
    <>
      <TextInput
        {...inputProps}
        defaultValue={value}
        onInput={(event) => setValue(event.currentTarget.value)}
        disabled={disabled || isLoading}
      />
      <Button
        label="Save"
        size="m"
        action={() => {
          if (changed) {
            setStoneValue(value);
            onSave(value);
          }
        }}
        variant="primary"
        loading={isLoading}
        disabled={!changed || disabled}
      />
    </>
  );
};
