import React from 'react';

type TextWithActionsLayoutProps = React.PropsWithChildren & {
  title: React.ReactNode;
};

export const TextWithActionsLayout: React.FC<TextWithActionsLayoutProps> = ({ title, children }) => {
  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      {title}
      <div className="mt-3 flex sm:mt-0 sm:ml-4 space-x-8">{children}</div>
    </div>
  );
};
