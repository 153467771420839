import { Auth0ProviderOptions } from '@auth0/auth0-react';
import { env } from '../../globals/config/env';

export type AuthConfig = {
  AUTH0_DOMAIN: string;
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENT_ID: string;
  CACHE_LOCATION: Auth0ProviderOptions['cacheLocation'];
};

export const AUTH0_CONFIG: AuthConfig = {
  AUTH0_DOMAIN: env('AUTH0_DOMAIN', 'DOMAIN_NOT_PROVIDED'),
  AUTH0_AUDIENCE: env('AUTH0_AUDIENCE', 'AUDIENCE_NOT_PROVIDED'),
  AUTH0_CLIENT_ID: env('AUTH0_CLIENT_ID', 'CLIENT_ID_NOT_PROVIDED'),
  CACHE_LOCATION: env('CACHE_LOCATION', 'localstorage'),
};
