import React from 'react';
import { Navigate, Route, Routes as RouterRoutes, useNavigate } from 'react-router-dom';
import { Home } from './pages/Home';
import { Projects } from './pages/project/Projects';
import Branding from './pages/project/settings/Branding';
import { Media } from './pages/media/Media';
import { SettingsPage } from './pages/settings/Settings';
import { General } from './pages/settings/General';
import { Billing } from './pages/settings/Billing';
import ComponentsExample from './pages/examples/ComponentsExample';
import { PricingExample } from './pages/examples/PricingExample';
import { SubscriptionPendingPage } from './pages/SubscriptionPendingPage';
import { PageNotFound } from './pages/error/PageNotFound';
import withOrganizationRegistered from './hoc/withOrganizationRegistered';
import { Scenes } from './pages/scenes/Scenes';
import { withActivePlan } from './contexts/activePlan/withActivePlan';
import { Team } from './pages/team/Team';
import { withMeLoaded } from './contexts/me/withMeLoaded';
import withAcceptedInvitation from './hoc/withAcceptedInvitation';
import { useMe } from './contexts/me/MeConsumer';
import { isOwnerRole } from './utils/isOwnerRole';
import Export from './pages/project/settings/Export';
import ProjectSettings from './pages/project/settings/ProjectSettings';
import Sharing from './pages/project/settings/Sharing';
import { PROJECT_ID_URL_SLUG as PROJECT_ID_ROUTE_PARAM } from './routing/constants';
import { useOnMount } from './hooks/useOnMount';
import { GOOGLETAG_CONFIG } from './plugins/googleTag/googleTag.config';
import GoogleTag from '@ael/google-tag';
import { MainContainer } from './components/atoms/layouts/MainContainer';

const PrivateRoutes: React.FC = () => {
  const {
    me: { role },
  } = useMe(true);

  useOnMount(() => {
    GoogleTag.init(GOOGLETAG_CONFIG.GOOGLE_TAG_ID);
  });

  return (
    <MainContainer>
      <RouterRoutes>
        <Route path={'/'} element={<Home />}>
          <Route index element={<Navigate to={'project'} replace />} />
          <Route path={'project'} element={<Projects />} />
          <Route path={`project/:${PROJECT_ID_ROUTE_PARAM}/settings`} element={<ProjectSettings />}>
            <Route index element={<Navigate to={'export'} replace />} />
            <Route path={'branding/'} element={<Branding />} />
            <Route path={'export/'} element={<Export />} />
            <Route path={'sharing/'} element={<Sharing />} />
          </Route>
          <Route path={'scene'} element={<Scenes />} />
          <Route path={'media'} element={<Media />} />
          {isOwnerRole(role) && (
            <Route path={'settings'} element={<SettingsPage />}>
              <Route index element={<Navigate to={'general'} replace />} />
              <Route path={'general'} element={<General />} />
              <Route path={'billing'} element={<Billing />} />
            </Route>
          )}
          <Route path={'team'} element={<Team />} />
          <Route path={'components'} element={<ComponentsExample />} />
          <Route path={'pricing'} element={<PricingExample />} />
        </Route>
        <Route path={'/subscription-pending'} element={<SubscriptionPendingPage />} />
        <Route path="/signup" element={<Navigate to="/project" />} />
        <Route path={'*'} element={<PageNotFound />} />
      </RouterRoutes>
    </MainContainer>
  );
};

export const usePrivateNavigation = () => {
  const navigate = useNavigate();
  return {
    toProjectsWithWelcome: () => navigate({ pathname: '/project', search: '?welcome' }),
  };
};

export default withMeLoaded(withOrganizationRegistered(withAcceptedInvitation(withActivePlan(PrivateRoutes))));
