import React from 'react';
import { Modal } from '../atoms/layouts/Modal';
import { Button } from '../atoms/buttons/Button';
import { Text } from '../atoms/texts/Text';
import { AlignedContent } from '../atoms/layouts/AlignedContent';
import { useDeleteProjectMutation } from '../../api/graphql/generated';

type DeleteProjectModalProps = {
  projectId: number;
  projectName: string;
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  projectId,
  projectName,
  onCancel,
  onDelete,
}) => {
  const deleteProjectMutation = useDeleteProjectMutation();

  const onDeleteAction = () => {
    deleteProjectMutation
      .mutateAsync({ projectId })
      .then(() => onDelete())
      .catch((reason) => {
        throw reason;
      });
  };

  return (
    <Modal
      padding="m"
      onBackgroundClick={(event) => {
        if (event.target === event.currentTarget) {
          onCancel();
        }
      }}
      backdrop
    >
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            Delete course
          </Text>
          <Text size="s" weight="normal">
            {`You are about to permanently delete “${projectName}”.
                This action cannot be undone. Are you sure?`}
          </Text>
        </AlignedContent>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="Cancel" action={onCancel} variant="tertiary" />
          <Button label="Delete" variant="danger" action={onDeleteAction} loading={deleteProjectMutation.isLoading} />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
