import React, { useState } from 'react';
import { SubscriptionTier } from '../../api/graphql/generated';
import PricingTemplate, { Tier } from '../../components/templates/pricing/PricingTemplate';
import { useUser } from '../../plugins/auth/Authentication';
import { useSubscriptionRepo } from '../../repositories/subscription/SubscriptionRepo';
import { useActivePlan } from '../../contexts/activePlan/ActivePlanCosumer';
import { UpgradeSubscriptionTierModal } from '../../components/organisms/UpgradeSubscriptionTierModal';

export function PricingExample() {
  const successUrl = new URL('/subscription-pending', window.location.origin);
  const cancelUrl = new URL('/pricing', window.location.origin);

  const { user } = useUser();
  const { activePlan } = useActivePlan();

  const { generateCheckoutSession } = useSubscriptionRepo();

  const [upgradeData, setUpgradeData] = useState<{
    lookupKey: string;
    tier: Tier;
    isMonthly: boolean;
  } | null>(null);

  // TODO
  // const downgradeSubscriptionQuery = useDowngradeSubscriptionQuery();
  //const downgradeSubscription = (lookupKey: string) =>
  //   downgradeSubscriptionQuery.mutate(lookupKey, {
  //     onSuccess: () => {
  //       alert('Downgrade is scheduled!');
  //     },
  //   });

  const initCheckout = async (lookupKey: string) => {
    if (user.email) {
      const checkoutUrl = await generateCheckoutSession(lookupKey, cancelUrl.href, successUrl.href);
      window.location.href = checkoutUrl;
    }
  };

  const buyUpgradeOrDowngrade = async (lookupKey: string, tier: Tier, isMonthly: boolean) => {
    if (activePlan.type === SubscriptionTier.Free) {
      return initCheckout(lookupKey);
    }
    if (tier.type > activePlan.type) {
      return setUpgradeData({ lookupKey, tier, isMonthly });
    }
    // TODO return downgradeSubscription(lookupKey);
    // in the meantime...

    window.open(`https://share.hsforms.com/1_7oMnh26TWWzQLTTlQ8EKg2wtil?email=${user.email}`, '_blank');
    return;
  };

  return (
    <>
      <div className="flex flex-col flex-1 gap-6 p-8">
        <PricingTemplate
          activeSubscription={activePlan.type}
          onButtonClick={(lookupKey, tier, isMonthly) => {
            buyUpgradeOrDowngrade(lookupKey, tier, isMonthly).catch(() => {
              return;
            });
          }}
        />
      </div>
      {upgradeData && (
        <UpgradeSubscriptionTierModal
          tier={upgradeData.tier}
          lookupKey={upgradeData.lookupKey}
          onCancel={() => setUpgradeData(null)}
          isMonthly={upgradeData.isMonthly}
        />
      )}
    </>
  );
}
