import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useUnauthorizedFetch } from '../../api/fetch';

type ResendVerifyEmailRequest = {
  email: string;
};

type ResendVerifyEmailOptions = Omit<UseMutationOptions<void, unknown, string>, 'mutationFn'>;

export const useResendVerifyEmail = (options?: ResendVerifyEmailOptions) => {
  const { post } = useUnauthorizedFetch();
  return useMutation({
    ...options,
    mutationFn: async (email: string) => {
      await post<void, ResendVerifyEmailRequest>('/api/resend-verification-email', { email });
    },
  });
};
