import classNames from 'classnames';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import './PhoneNumberInput.css';

type PhoneNumberInputProps = {
  phone: string;
  onChange: (phone: string) => void;
  isValid?: boolean;
  errorMessage?: string;
};

export const PhoneNumberInput = ({ phone, isValid, errorMessage, onChange }: PhoneNumberInputProps) => (
  <PhoneInput
    country={'us'}
    value={phone}
    onChange={(value) => onChange(`+${value}`)}
    isValid={isValid}
    defaultErrorMessage={errorMessage}
    containerClass="relative w-full"
    inputClass={classNames(
      'pl-12 block w-full shadow-sm focus:ring-1 focus:ring-blue-400 duration-150 focus:border-blue-500',
      'border-gray-300 text-gray-800 font-semibold rounded-md sm:text-sm'
    )}
    buttonClass="absolute top-0 bottom-0 border-1 border-gray-500 rounded-md"
    specialLabel={''}
    dropdownClass="mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
  />
);
