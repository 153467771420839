import { APIConfig } from '../../api/api.config';
import { AuthConfig } from '../../plugins/auth/auth.config';
import { GoogleTagConfig } from '../../plugins/googleTag/googleTag.config';
import { LogConfig } from '../../plugins/logger/log.config';

export type EnvVars = AuthConfig & LogConfig & APIConfig & GoogleTagConfig;

export function env<K extends keyof EnvVars>(envVar: K, fallback: EnvVars[K]): EnvVars[K] {
  return ((process.env[`REACT_APP_${envVar}`] as EnvVars[K]) || window?.SERVER_DATA?.[envVar]) ?? fallback;
}
