import React from 'react';
import { Submenu } from '../../../components/molecules/submenu/Submenu';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Outlet } from 'react-router-dom';
import { SubmenuItemProps } from '../../../components/molecules/submenu/SubmenuItem';
import gql from 'graphql-tag';
import { useFetchProjectNameQuery } from '../../../api/graphql/generated';
import { Loader } from '../../../components/molecules/loader/Loader';
import { Centered } from '../../../components/atoms/layouts/Centered';
import { ProjectPageProps, withProjectId } from '../../../hoc/withProjectId';
import { LayoutWithSubmenu } from '../../../components/atoms/layouts/LayoutWithSubmenu';

const ProjectSettings: React.FC<ProjectPageProps> = ({ projectId }) => {
  const settingsMenu: SubmenuItemProps[] = [
    {
      label: 'Share link',
      description: 'Create and manage access to the course through a link.',
      icon: regular('link'),
      to: 'sharing',
    },
    {
      label: 'Branding',
      description: 'Customize the look and feel of your course.',
      icon: regular('paintbrush-fine'),
      to: 'branding',
    },
    {
      label: 'Export course',
      description: 'Download the latest version of the course in desired format.',
      icon: regular('file-export'),
      to: 'export',
    },
  ];

  const projectNameQuery = useFetchProjectNameQuery({ projectId });

  return (
    <>
      {projectNameQuery.isLoading && (
        <Centered tryGoldenCenter>
          <Loader label="Loading course settings..." />
        </Centered>
      )}
      {!projectNameQuery.isLoading && (
        <LayoutWithSubmenu>
          <Submenu label={`Manage ${projectNameQuery.data?.projectById?.name ?? 'your awesome course'}`}>
            {settingsMenu.map((item, idx) => (
              <Submenu.Item key={`ProjectSubmenu-${idx}`} {...item} />
            ))}
          </Submenu>
          <Outlet />
        </LayoutWithSubmenu>
      )}
    </>
  );
};

export default withProjectId(ProjectSettings);

gql`
  query fetchProjectName($projectId: Int!) {
    projectById(id: $projectId) {
      name
    }
  }
`;
