import { useMutation } from '@tanstack/react-query';
import { useFetch } from '../../api/fetch';

type Upgrade = {
  lookupKey: string;
};

export const useUpgradeSubscriptionMutation = () => {
  const { post } = useFetch();

  return useMutation({
    mutationFn: async (lookupKey: string) => {
      return await post<Response, Upgrade>('/api/UpgradeSubscriptionPlan', { lookupKey: lookupKey });
    },
  });
};
