import { SubscriptionTier } from '../../api/graphql/generated';

export type PanomioPricingPlan = {
  type: SubscriptionTier;
  seats: number;
  projectLimit: number | null;
  scenesPerProjectLimit: number | null;
  canExportSCORMCloud: boolean;
  canExportSCORM: boolean;
  canExportXAPI: boolean;
};

export const DEFAULT_PRICING_PLAN: PanomioPricingPlan = {
  type: SubscriptionTier.Free,
  seats: 1,
  projectLimit: 3,
  scenesPerProjectLimit: 1,
  canExportSCORMCloud: false,
  canExportSCORM: false,
  canExportXAPI: false,
};
