import React from 'react';
import { useGetProjectsByToursQuery } from '../../../api/graphql/generated';
import { Modal } from '../../atoms/layouts/Modal';
import { AlignedContent } from '../../atoms/layouts/AlignedContent';
import { Text } from '../../atoms/texts/Text';
import { Button } from '../../atoms/buttons/Button';
import { Loader } from '../../molecules/loader/Loader';
import { TextSpan } from '../../atoms/texts/TextSpan';

type DeleteSceneFailedModalProps = {
  sceneName: string;
  blockingTourIds: number[];
  onUnderstand: () => void;
};

export const DeleteSceneFailedModal = ({ sceneName, blockingTourIds, onUnderstand }: DeleteSceneFailedModalProps) => {
  const getProjects = useGetProjectsByToursQuery({ tourIds: blockingTourIds });

  const projectNames = getProjects.data?.projectsByTourIds
    .reduce<string[]>((collectedArray, project) => {
      collectedArray.push(project.name);

      return collectedArray;
    }, [])
    .join(', ');

  return (
    <Modal padding="m" backdrop>
      <AlignedContent direction="column" gap="m">
        <AlignedContent direction="column" gap="s">
          <Text size="m" weight="semibold">
            {"Can't delete a scene"}
          </Text>
          {getProjects.isLoading && <Loader />}
          {!getProjects.isLoading && (
            <AlignedContent direction="column" gap="s">
              <Text size="s" weight="normal">
                This scene is already used in one or more courses. Remove the scene from courses before deleting it from
                your organization.
                <br />
                <br />
                Courses using{' '}
                <TextSpan size="s" weight="bold">
                  {sceneName}
                </TextSpan>{' '}
                :{' '}
                <TextSpan size="s" weight="bold">
                  {projectNames}
                </TextSpan>
              </Text>
            </AlignedContent>
          )}
        </AlignedContent>
        <AlignedContent direction="row" gap="s" placing="fromEnd">
          <Button label="I undersand" variant="primary" action={onUnderstand} />
        </AlignedContent>
      </AlignedContent>
    </Modal>
  );
};
