import React from 'react';
import { ErrorTemplate } from '../../components/templates/error/ErrorTemplate';

export const ErrorFallback: React.FC = () => {
  return (
    <ErrorTemplate
      error="Error"
      title="Something went wrong"
      description="We have detected an error when communicating with server."
      primary={{ label: 'Refresh', action: () => window.location.reload() }}
      secondary={{ label: 'Go to dashboard', action: () => alert('redirecting to dashboard will be implemented') }}
    />
  );
};
