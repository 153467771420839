import React from 'react';
import { ImageList } from '../atoms/lists/ImageList';
import { DropDown } from '../molecules/dropDown/DropDown';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FloatingItemLayout } from '../atoms/layouts/FloatingItemLayout';
import { useSwitch } from '../../hooks/useSwitch';
import { RenameSceneModal } from './RenameSceneModal';
import Moment from 'moment/moment';
import { SelectFileModal, UploadFileFunction } from './SelectFileModal';
import { useUploadSceneThumbnailMutation } from '../../repositories/scene/UploadSceneThumnailMutation';
import { PulseEffect } from '../atoms/effects/PulseEffect';
import { TextSpan } from '../atoms/texts/TextSpan';
import { DeleteSceneWizard } from './scene/DeleteSceneWizard';
import gql from 'graphql-tag';
import { useFetchSceneProcessingFinishedByIdQuery } from '../../api/graphql/generated';
import { useToast } from '../../hooks/useToast';

const MAX_5MB = 5_242_880;
const REFETCH_INTERVAL_MS = 30000;

type SceneTileProps = {
  sceneId: number;
  sceneName: string;
  lastUpdate: Date;
  isProcessing: boolean;
  thumbnailUrl?: string;
  onThumbnailSet: () => void;
  onDeleteScene: () => void;
  onRenameScene: () => void;
  onProcessingFinished: () => void;
};

export const SceneTile: React.FC<SceneTileProps> = ({
  sceneId,
  sceneName,
  lastUpdate,
  isProcessing,
  thumbnailUrl,
  onDeleteScene,
  onRenameScene,
  onThumbnailSet,
  onProcessingFinished,
}) => {
  const deleteModal = useSwitch(false);
  const renameModal = useSwitch(false);
  const upload = useUploadSceneThumbnailMutation();
  const uploadThumbnail = useSwitch(false);
  const { addSuccessToast } = useToast();

  const uploadFileHandler: UploadFileFunction = Object.assign(
    (thumbnail: File) => {
      upload
        .mutateAsync({ sceneId, thumbnail })
        .then(() => {
          onThumbnailSet();
          uploadThumbnail.turnOff();
        })
        .catch((reason) => {
          throw reason;
        });
    },
    {
      isLoading: upload.isLoading,
    }
  );

  useFetchSceneProcessingFinishedByIdQuery(
    { sceneId: sceneId },
    {
      refetchOnWindowFocus: false,
      refetchInterval: REFETCH_INTERVAL_MS,
      enabled: isProcessing,
      onSuccess: (data) => {
        if (data.sceneById?.hasProcessingFinishedSuccessfully === true) {
          addSuccessToast('Scene is ready', `${sceneName} is processed and ready to use.`);
          onProcessingFinished();
        }
      },
    }
  );

  const dropDown = (
    <DropDown menuAlignment="right">
      <DropDown.Option
        label="Rename"
        action={() => {
          renameModal.turnOn();
        }}
      />
      <DropDown.Option label="Set thumbnail" action={() => uploadThumbnail.turnOn()} />
      <DropDown.Option
        variant="danger"
        label="Delete"
        action={() => {
          deleteModal.turnOn();
        }}
      />
    </DropDown>
  );

  const description = isProcessing ? (
    <PulseEffect>
      <ImageList.Item.SubDescription
        text={
          <TextSpan color="blue" weight="semibold">
            Processing...
          </TextSpan>
        }
      />
    </PulseEffect>
  ) : (
    <ImageList.Item.SubDescription text={Moment(lastUpdate).format('DD-MMM-YYYY')} />
  );

  return (
    <>
      <FloatingItemLayout floatingItem={dropDown}>
        <ImageList.Item
          imageSrc={thumbnailUrl}
          placeholderIcon={isProcessing ? solid('timer') : solid('panorama')}
          screenReaderLabel={`View details for ${sceneName}`}
        >
          <ImageList.Item.Description text={sceneName} />
          {description}
        </ImageList.Item>
      </FloatingItemLayout>
      {deleteModal.isOn && (
        <DeleteSceneWizard
          sceneId={sceneId}
          sceneName={sceneName}
          onDelete={() => {
            deleteModal.turnOff();
            onDeleteScene();
          }}
          onCancel={() => deleteModal.turnOff()}
        />
      )}
      {renameModal.isOn && (
        <RenameSceneModal
          sceneId={sceneId}
          sceneName={sceneName}
          onRename={() => {
            renameModal.turnOff();
            onRenameScene();
          }}
          onCancel={() => renameModal.turnOff()}
        />
      )}
      {uploadThumbnail.isOn && (
        <SelectFileModal
          title={`Set thumbnail for ${sceneName}`}
          onlyOneFile
          maxFileSize={MAX_5MB}
          acceptFileType={{ 'image/jpeg': 'JPG', 'image/png': 'PNG' }}
          createButtonText="Set thumbnail"
          onCreate={uploadFileHandler}
          onCancel={() => uploadThumbnail.turnOff()}
        />
      )}
    </>
  );
};

export const SceneTiles = ImageList;

gql`
  query fetchSceneProcessingFinishedById($sceneId: Int!) {
    sceneById(id: $sceneId) {
      hasProcessingFinishedSuccessfully
    }
  }
`;
