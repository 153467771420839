import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';

interface LoaderProps {
  label?: string;
  spinnerColor?: 'blue' | 'white' | 'black';
}

export const Loader: React.FC<LoaderProps> = ({ label = 'Loading...', spinnerColor = 'blue' }) => (
  <div role="status" className="flex items-center">
    <FontAwesomeIcon
      className={classNames('h-4 mr-2 inline animate-spin text-grey-500', {
        'fill-blue-600 text-blue-500': spinnerColor === 'blue',
        'text-white': spinnerColor === 'white',
      })}
      icon={duotone('spinner-third')}
    />
    <span className="sr-only">Loading...</span>
    <span className="font-semibold">{label}</span>
  </div>
);
