import TagManager from 'react-gtm-module';
var GoogleTag = /** @class */ (function () {
    function GoogleTag() {
    }
    GoogleTag.init = function (GTM_ID) {
        if (this._initialized) {
            this._isDev && console.warn('GTM cannot be initialized more than 1 times.');
            return;
        }
        if (GTM_ID && !GTM_ID.trim()) {
            try {
                var tagManagerArgs = {
                    gtmId: GTM_ID,
                    dataLayerName: 'EventsDataLayer',
                    preview: undefined,
                    auth: undefined,
                };
                TagManager.initialize(tagManagerArgs);
                this._initialized = true;
                return;
            }
            catch (e) {
                console.warn('Error initializing GTM. Looks like an invalid ID was provided.');
                this._initialized = false;
                return;
            }
        }
        this._initialized = false;
        return;
    };
    GoogleTag.sendEvent = function (payload) {
        if (this._initialized) {
            return TagManager.dataLayer({
                dataLayer: payload,
                dataLayerName: 'EventsDataLayer',
            });
        }
        if (this._isDev) {
            console.info('GTM was not initialized, but sendEvent was triggered.');
        }
    };
    GoogleTag._isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    return GoogleTag;
}());
export default GoogleTag;
