import classNames from 'classnames';
import React from 'react';
import { Loader } from '../../molecules/loader/Loader';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger' | 'text' | 'selectable';
export type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'full' | 'full-xl-padding'; //refactor sizes into text-size and padding

export type ButtonAction = {
  label: React.ReactNode;
  action?: () => void;
};

type ButtonProps = ButtonAction & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
};

const variantToClassnames: Record<ButtonVariant, string> = {
  primary:
    'text-white bg-blue-500 hover:bg-blue-600 border-transparent focus:ring-blue-300 disabled:bg-grey-300 disabled:text-grey-500 disabled:hover:bg-grey-300 border shadow-sm ',
  secondary:
    'text-blue-700 bg-blue-300 hover:bg-blue-400 border-transparent focus:ring-blue-300 disabled:text-white disabled:hover:bg-blue-300 border shadow-sm ',
  tertiary:
    'text-gray-800 bg-white hover:bg-gray-50 border-gray-300 focus:ring-grey-300 disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:text-grey-500  disabled:border-grey-300 border shadow-sm ',
  danger:
    'text-white bg-red-500 hover:bg-red-600 border-gray-300 disabled:bg-grey-300 disabled:text-grey-500 disabled:hover:bg-grey-300 border shadow-sm ',
  text: 'text-gray-800 hover:text-gray-700 disabled:text-gray-600 disabled:hover:text-gray-600',
  selectable:
    'text-grey-900 bg-white hover:bg-gray-50 border-gray-300 focus:ring-grey-300 disabled:bg-blue-200 disabled:text-blue-500 disabled:border-grey-300 border shadow-sm ',
};

export const sizeToClassnames: Record<ButtonSize, string> = {
  xs: 'px-2.5 py-1.5 text-xs',
  s: 'px-3 py-2 text-sm',
  m: 'px-4 py-2 text-sm',
  l: 'px-4 py-2 text-base',
  xl: 'px-6 py-3 text-base',
  full: 'w-full px-2.5 py-1.5 text-s',
  'full-xl-padding': 'w-full px-5 py-6 text-s',
};

export const Button: React.FC<ButtonProps> = ({
  label,
  action,
  variant = 'primary',
  size = 'm',
  disabled,
  loading,
}) => {
  return (
    <button
      className={classNames(
        'inline-flex items-center font-semibold transition-all duration-200 rounded disabled:cursor-default h-fit',
        variantToClassnames[variant],
        sizeToClassnames[size]
      )}
      disabled={disabled || loading}
      onClick={action}
    >
      <div className="flex items-center justify-center w-full whitespace-nowrap">
        {loading && <Loader label="" spinnerColor="black" />}
        {label}
      </div>
    </button>
  );
};
