import { ButtonAction } from '../../atoms/buttons/Button';
import React from 'react';
import classNames from 'classnames';

type Variant = 'casual' | 'danger';

const VariantToClassname: Record<Variant, string> = {
  casual: 'text-gray-800 hover:text-gray-900',
  danger: 'text-red-500 hover:text-red-500',
};

type DropDownOptionProps = ButtonAction & {
  variant?: Variant;
  disabled?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>['disabled'];
};

export const DropDownOption: React.FC<DropDownOptionProps> = ({ label, action, variant = 'casual', disabled }) => {
  return (
    <button
      className={classNames(
        'hover:bg-gray-100  block w-full text-left px-4 py-2 text-sm font-semibold disabled:text-grey-500',
        VariantToClassname[variant]
      )}
      onClick={action}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
