import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FetchMyActivePlanQuery, useFetchMyActivePlanQuery } from '../../api/graphql/generated';
import gql from 'graphql-tag';
import { DEFAULT_PRICING_PLAN, PanomioPricingPlan } from './ActivePlan';
import { ActivePlan, ActivePlanContext } from './ActivePlanContext';

export const ActivePlanContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activePlan, setActivePlan] = useState<PanomioPricingPlan>(DEFAULT_PRICING_PLAN);
  const activePlanQuery = useFetchMyActivePlanQuery(undefined, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (activePlanQuery.data?.me?.organization?.pricingPlan) {
      setActivePlan(hydratePanomioPricingPlan(activePlanQuery.data));
    }
  }, [activePlanQuery.data]);

  const provided: ActivePlan = {
    activePlan: activePlan,
    isLoaded: activePlanQuery.isSuccess,
    refetchActivePlan: () => {
      activePlanQuery.refetch().catch((e) => console.log(e));
    },
  };

  return <ActivePlanContext.Provider value={provided}>{children}</ActivePlanContext.Provider>;
};

gql`
  query fetchMyActivePlan {
    me {
      organization {
        pricingPlan {
          pricingPlanType
          numberOfCourses
          scenesPerCourse
          isSCORMExportAllowed
          isSCORMCloudDispatchAllowed
          isXAPIExportAllowed
        }
        purchasedSeats
      }
    }
  }
`;

const OWNER_SEAT_COUNT = 1;

function hydratePanomioPricingPlan(query: FetchMyActivePlanQuery): PanomioPricingPlan {
  if (!query.me?.organization?.pricingPlan) {
    throw new Error('no plan provided dude');
  }

  const plan = query.me?.organization?.pricingPlan;

  return {
    type: plan.pricingPlanType,
    seats: query.me.organization.purchasedSeats + OWNER_SEAT_COUNT ?? OWNER_SEAT_COUNT,
    projectLimit: plan.numberOfCourses ?? null,
    scenesPerProjectLimit: plan.scenesPerCourse ?? null,
    canExportSCORMCloud: plan.isSCORMCloudDispatchAllowed,
    canExportSCORM: plan.isSCORMExportAllowed,
    canExportXAPI: plan.isXAPIExportAllowed,
  };
}
