import React from 'react';
import classNames from 'classnames';

export type TextSize = 'xxs' | 'xs' | 's' | 'base' | 'm' | 'l' | 'xl' | 'xxl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl';

export const TextSizeToClassname: Record<TextSize, string> = {
  xxs: 'text-xxs',
  xs: 'text-xs',
  base: 'text-base',
  l: 'text-l',
  m: 'text-m',
  s: 'text-s',
  xl: 'text-xl',
  xxl: 'text-xxl',
  '5xl': 'text-5xl',
  '6xl': 'text-6xl',
  '7xl': 'text-7xl',
  '8xl': 'text-8xl',
  '9xl': 'text-9xl',
};

export type TextWeight = 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold';

export const TextWeightToClassname: Record<TextWeight, string> = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
};

export type TextColor =
  | 'default'
  | 'success'
  | 'error'
  | 'blue'
  | 'white'
  | 'danger'
  | 'grey'
  | 'disabled'
  | 'blue-400';

export const TextColorToClassname: Record<TextColor, string> = {
  default: 'color-inherit',
  blue: 'text-blue-500',
  'blue-400': 'text-blue-400',
  white: 'text-white',
  success: 'text-green-500',
  error: 'text-red-500',
  danger: 'text-red-500',
  grey: 'text-grey-600',
  disabled: 'text-gray-500',
};

export type TextAlign = 'center' | 'left' | 'right';

export const TextAlignToClassname: Record<TextAlign, string> = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
};

type TextProps = React.PropsWithChildren & {
  size?: TextSize;
  weight?: TextWeight;
  color?: TextColor;
  align?: TextAlign;
};

export const Text: React.FC<TextProps> = ({
  size = 'base',
  weight = 'normal',
  color = 'default',
  align = 'left',
  children,
}) => {
  return (
    <div
      className={classNames(
        TextSizeToClassname[size],
        TextWeightToClassname[weight],
        TextColorToClassname[color],
        TextAlignToClassname[align]
      )}
    >
      {children}
    </div>
  );
};
