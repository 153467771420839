import React, { useState } from 'react';
import { ImagePlaceholder, ImagePlaceholderProps } from './ImagePlaceholder';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';

type ImageGrow = 'cover' | 'fit';

type DimensionsLimit = {
  width: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>['width'];
  height: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>['height'];
};

export type ImageProps = {
  placeholderIcon?: ImagePlaceholderProps['icon'];
  imageSrc: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>['src'];
  grow?: ImageGrow;
  limit?: DimensionsLimit;
};

export const Image = ({
  placeholderIcon = solid('layer-group'),
  imageSrc,
  grow = 'cover',
  limit,
}: ImageProps): JSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      {!imageLoaded && <ImagePlaceholder icon={placeholderIcon} />}
      <img
        src={imageSrc}
        style={!imageLoaded ? { display: 'none' } : {}}
        alt=""
        {...limit}
        className={classNames(
          { 'object-cover': grow === 'cover' },
          'pointer-events-none group-hover:opacity-75 max-w-full max-h-full'
        )}
        onLoad={(event) => setImageLoaded(true)}
      />
    </>
  );
};
