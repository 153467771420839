import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavLink, To } from 'react-router-dom';

type MenuItemProps = {
  icon: IconProp;
  href: To;
  label: React.ReactNode;
};
export const MenuItem: React.FC<MenuItemProps> = ({ label, icon, href }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames('group flex items-center px-2 py-3 text-base font-semibold rounded-lg duration-100', {
          'bg-grey-900 border-blue-500 text-blue-500': isActive,
          'border-transparent text-white hover:bg-grey-900 hover:text-grey-400': !isActive,
        })
      }
      to={href}
    >
      {({ isActive }) => {
        return (
          <>
            <FontAwesomeIcon
              icon={icon}
              className={classNames('mr-3 flex-shrink-0 h-4 w-4', {
                'text-blue-500': isActive,
                'text-white group-hover:text-gray-400': !isActive,
              })}
              aria-hidden="true"
            />
            {label}
          </>
        );
      }}
    </NavLink>
  );
};
